import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Copyright from "../../Components/Copyright";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import blog_1 from "../../Assets/Images/BP imgs/blog_1_new.jpg";
import blog_2 from "../../Assets/Images/BP imgs/blog_2_new.jpg";
import blog_3 from "../../Assets/Images/BP imgs/blog_3_new.jpg";
import blog_4_new from "../../Assets/Images/blog_4_new.png";


const BlogDetailPage = () => {
  useEffect(() => {
    AOS.init();
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "ABLE",
            // textAlign: "center",
            // pb: "1rem",
            fontWeight: "600",
          }}
        >
          {/* <em style={{ textDecoration: "underline" }}> */}
            The Comprehensive Guide to Conscious
            {/* <br /> */}
            Breathing for Respiratory Health
          {/* </em> */}
        </Typography>
        <Typography
            sx={{
              fontSize: "16px",
              // textAlign: "center",
              fontFamily: "ABLE",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Mon Apr 29 2024
          </Typography>
        <Typography
          component={"img"}
          src={blog_1}
          // width={"100%"}
          sx={{ float: "right",p:{md:"0rem 1rem 0rem 1rem",xs:"0 0 1rem"}, width: { md: "50%", xs: "100%" } }}
          height={"100%"}
          pb={"1rem"}
        />
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          In the hustle and bustle of modern life, the importance of respiratory
          health often goes unnoticed. However, conscious breathing, a practice
          rooted in ancient wisdom, offers a pathway to improved lung function,
          stress reduction, and overall well-being. In this comprehensive guide,
          we delve deep into the significance of conscious breathing and explore
          practical techniques to optimize respiratory health.
        </Typography>

        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          <em style={{ textDecoration: "underline" }}>
            Understanding Conscious Breathing
          </em>
        </Typography>

        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          Conscious breathing, also referred to as mindful breathing or
          controlled breathing, involves deliberate awareness and control of the
          breath. It encompasses various techniques aimed at enhancing the
          quality, depth, and rhythm of respiration. By focusing on the present
          moment and regulating the breath, individuals can tap into the
          profound benefits of conscious breathing for both body and mind.
        </Typography>

        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          <em style={{ textDecoration: "underline" }}>
            The Science Behind Conscious Breathing
          </em>
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
          }}
        >
          Numerous studies have elucidated the physiological and psychological
          mechanisms underlying conscious breathing and its impact on
          respiratory health:
        </Typography>

        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <li>
            Respiratory Physiology- Conscious breathing techniques, such as
            diaphragmatic breathing and paced breathing, promote optimal lung
            function by maximizing oxygen intake and minimizing respiratory
            effort.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <li>
            Autonomic Nervous System- Mindful breathing activates the
            parasympathetic nervous system, triggering the relaxation response
            and reducing stress-related hormones like cortisol.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <li>
            Brain Function- MRI studies have shown that conscious breathing
            modulates brain activity, enhancing connectivity between regions
            associated with attention, emotion regulation, and self-awareness.
          </li>
        </Typography>
        <Typography
          component={"img"}
          src={blog_2}
          sx={{ float: "right",p:{md:"1rem 1rem 0rem 1rem",xs:"0 0 1rem"}, width: { md: "50%", xs: "100%" } }}
          height={"100%"}
          pb={"1rem"}
        />

        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          <em style={{ textDecoration: "underline" }}>
            Benefits of Conscious Breathing
          </em>
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          Conscious breathing offers a plethora of benefits for respiratory
          health, mental well-being, and overall vitality:
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
            // pl: "1.5rem",
          }}
        >
          <li>
            Improved Lung Function- By practicing deep, controlled breaths,
            individuals can enhance lung capacity, strengthen respiratory
            muscles, and optimize oxygen exchange in the lungs.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
            // pl: "1.5rem",
          }}
        >
          <li>
            Stress Reduction- Mindful breathing techniques induce a state of
            calmness and relaxation, alleviating stress, anxiety, and tension
            accumulated throughout the day.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
            // pl: "1.5rem",
          }}
        >
          <li>
            Enhanced Mental Clarity- Focusing on the breath cultivates
            mindfulness and presence, sharpening cognitive function, improving
            focus, and promoting mental clarity.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
            // pl: "1.5rem",
          }}
        >
          <li>
            Emotional Regulation- Conscious breathing serves as a powerful tool
            for emotional regulation, helping individuals manage negative
            emotions, cultivate resilience, and foster a sense of inner peace.
          </li>
        </Typography>
        <Typography
          component={"img"}
          src={blog_3}
          sx={{ float: "left",p:{md:"1rem 2rem 0rem 1rem",xs:"0 0 1rem"}, width: { md: "50%", xs: "100%" } }}
          height={"100%"}
          pb={"1rem"}
        />
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          <em style={{ textDecoration: "underline" }}>
            Incorporating Conscious Breathing into Daily Life
          </em>
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
          }}
        >
          Integrating conscious breathing practices into everyday routines can
          amplify their benefits and promote long-term respiratory health:
        </Typography>

        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <li>
            Morning Ritual- Begin each day with a brief session of conscious
            breathing to set a positive tone, center yourself, and align with
            your intentions for the day.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <li>
            Stress Management- Employ mindful breathing techniques during
            moments of stress, overwhelm, or uncertainty to regain composure,
            restore balance, and navigate challenges with clarity and
            resilience.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <li>
            Exercise Enhancement- Enhance your physical performance and
            endurance by synchronizing conscious breathing with movement during
            exercise, yoga, or meditation sessions.
          </li>
        </Typography>
        <Typography
          component={"ul"}
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <li>
            Before Bed Routine- Wind down in the evening with relaxation-focused
            breathing exercises to unwind, release tension, and prepare the body
            and mind for restorative sleep.
          </li>
        </Typography>
        <Typography
          component={"img"}
          src={blog_4_new}
          sx={{ float: "right",p:{md:"2rem 1rem 0rem 1rem",xs:"0 0 1rem"}, width: { md: "auto", xs: "100%" } }}
          height={"100%"}
          pb={"1rem"}
        />
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          <em style={{ textDecoration: "underline" }}>
            Utilizing Tools for Enhanced Respiratory Health
          </em>
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          While conscious breathing can be practiced independently, innovative
          technologies can augment its effectiveness and provide valuable
          results.{" "}
        </Typography>
        {/* image */}
     <Box textAlign={"center"}>

     </Box>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          CapnoTrainer® GO- Developed by Better Physiology, the CapnoTrainer® GO
          is a cutting-edge hardware and software system designed to optimize
          respiratory health through personalized breathing education. With its
          live and recorded physiology presentations, users can receive
          real-time feedback and tailored training tools to enhance their
          breathing patterns and overall well-being.{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          Conscious breathing stands as a cornerstone of respiratory health and
          holistic well-being. By embracing mindful breathing practices and
          incorporating them into daily life, individuals can unlock the full
          potential of their respiratory system, reduce stress, and cultivate a
          deeper sense of connection to themselves and the world around them.
          With the support of advanced tools like the CapnoTrainer® GO, the
          journey towards optimal respiratory health becomes accessible,
          empowering, and profoundly transformative.{" "}
        </Typography>
        <Box pt={"5rem"}>
          <Copyright center={"center"} />
        </Box>
      </Box>
    </Container>
  );
};

export default BlogDetailPage;
