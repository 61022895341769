import React, { useEffect, useState } from "react";
import cart_bg from "../../Assets/Images/cart_bg.png";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { API_URL, IMAGE_URL } from "../../redux/Constant";
import { getCart } from "../../store/cartSlice";
import { useNavigate } from "react-router-dom";
// import capno_trainer_img from "../../Assets/Images/capno-trainer_img.png";
import { makeStyles } from "@mui/styles";
import axios from "axios";

const useStyles = makeStyles({
  box_main: {
    // backgroundImage: `url(${cart_bg})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "100% 100%",
    // backgroundSize: "cover",
    background:"#fff",
    border:"1px solid #dad5d5",

    // padding: "2rem 1rem",
    borderRadius: "6px",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  btn: {
    background: "rgb(109 79 160) !important",
    color: "#fff !important",
    fontFamily: "ABLE !important",
    padding: "0.7rem !important",
    fontSize: "18px !important",
    lineHeight: "initial !important",
    width: "100%",
    "&:hover": {
      background: "rgb(109 79 160)",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto !important",
      color: "rgb(255 255 255 / 38%) !important",
      "&:hover": {
        opacity: "1",
      },
    },
  },
  front: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
  },
  back: {
    display: "block",
    cursor: "pointer",
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    transform: "rotateY(180deg)",
  },
  flip_card_inner: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.8s",
    transformStyle: "preserve-3d",
  },
  flip_card_outer: {
    backgroundColor: "transparent",
    height: "230px",
    perspective: "1000px",
    "&:hover $flip_card_inner": {
      transform: "rotateY(180deg)",
    },
  },
});
const CartBox1 = ({ data, setShowSnackbar, setAddSuccessMsg,setAddErrorMsg }) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(()=>{

  },[data])
  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  const addToCartFunc = async (productId, quantity) => {
    setLoading(true);
    try {
      const res = await axios.post(`${API_URL}/add-to-cart`, {
        user_id: localStorage.getItem("user")
          ? localStorage.getItem("user")
          : "",
        product_ids: [productId],
        quantities: [quantity],
      });
      if (res.status === 200) {
        console.log(res);
        setLoading(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddSuccessMsg("");
        }, 2000);
        setAddSuccessMsg(res.data.message);
        const userId = res.data.user_id ;
        localStorage.setItem("user", res.data.user_id);
        dispatch(getCart(userId));
      }
  
    } catch (err) {
      console.log(err);
          if (err.response.status === 404) {
        setLoading(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddErrorMsg("")
        }, 2000);
        setAddErrorMsg("Out of stock");
      }
    }
  };

  return (
    <Box
      sx={{ backgroundColor: "#F7F4F4", borderRadius: "6px", color: "#000000",border:"1px solid #dad5d5"}}
    >
      <Box className={classes.flip_card_outerr}>
        <Box
          className={classes.box_main}
          onClick={() => navigate(`/go-product/${data?.id}`)}
        >
          <Typography
            component={"img"}
            src={`${IMAGE_URL}/${data?.product_images && data?.product_images }`}
            sx={{
              // width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box
          // className={classes.box_main}
          // sx={{
          //   height: { sm: "100px" },
          // }}
          // onClick={() =>
          //   navigate(`/productDetail/${data?.id}`, { state: data })
          // }
        >
          <Typography
            sx={{
              fontSize: { sm: "20px", xs: "20px" },
              fontFamily: "ABLE",
              lineHeight: "30px",
              textAlign: { md: "start", xs: "center" },
              p: "1rem",
              height:'100px'
            }}
          >
            {data?.name} <br/>
            <Typography component={'em'} sx={{
              fontSize:{sm:"18px", xs:"16px"},
              fontFamily: "ABLE",
            }}>{data?.subtitle}</Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          px: "0.7rem",
          pt: "0.7rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: { sm: "20px", xs: "18px" }, fontFamily: "ABLE" }}
        >
          Price
        </Typography>
        <Typography
          sx={{ fontSize: { sm: "24px", xs: "20px" }, fontFamily: "ABLE" }}
        >
          $ {data.price}
        </Typography>
      </Box>
      <Box
        sx={{
          p: "0.3rem 0.7rem 0.7rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: { sm: "20px", xs: "18px" }, fontFamily: "ABLE" }}
        >
          Quantity
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              border: "1px solid rgb(109 79 160)",
              fontSize: "20px",
              fontFamily: "ABLE",
              borderRadius: "6px 0 0 6px",
              width: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {count}
          </Box>
          <Box
            sx={{
              background: "rgb(109 79 160)",
              borderRadius: "0px 6px 6px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ExpandLess
              onClick={increment}
              sx={{
                cursor: "pointer",
                width: "1.3rem",
                height: "1.3rem",
                color: "#fff",
              }}
            />
            <ExpandMore
              onClick={decrement}
              sx={{
                cursor: "pointer",
                width: "1.3rem",
                height: "1.3rem",
                color: "#fff",

                "&[disabled]": {
                  pointerEvents: "not-allowed",
                  opacity: 0.6, // Adjust the opacity as needed
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: "0.4rem 0.7rem 1.2rem" }}>
        <Button
          className={classes.btn}
          disabled={count === 0}
          onClick={() => addToCartFunc(data?.id, count)}
        >
          {loading ? (
            <CircularProgress
              sx={{
                width: "23px !important",
                height: "23px !important",
                color: "#e4dbe7",
              }}
            />
          ) : (
            "Add to Cart"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default CartBox1;
