import React from "react";
import ImageGallery from "react-image-gallery";
import { Box, Grid, Typography } from "@mui/material";
import GoProfessionalInfo from "./CapnoTrainerDetailInfo/GoProfessionalInfo";
import GoBasicInfo from "./CapnoTrainerDetailInfo/GoBasicInfo";
import GoPersonal from "./CapnoTrainerDetailInfo/GoPersonal";
import GoPersonalC from "./CapnoTrainerDetailInfo/GoPersonalC";
import GoPersonalM from "./CapnoTrainerDetailInfo/GoPersonalM";

const ContentAndImageGrid = ({ images, description, productData }) => {
  return (
    <>
      <Box className="go_details">
        <Box
          sx={{
            float: "right",
            width: { md: "50%", xs: "100%" },
            paddingLeft: { md: "1.5rem" },
            paddingBottom: { md: "0", xs: "1.7rem" },
          }}
        >
          <ImageGallery
            items={images}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
            disableSwipe={true}
            disableThumbnailSwipe={true}
            slideDuration={0}
          />
          <Typography
            sx={{
              pt: "0.5rem",
              fontSize: { sm: "18px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "28px" },
              textAlign: { sm: "start", xs: "justify" },
              color: "rgb(109 79 160)",
              fontWeight: "600",
            }}
          >
            NOTE:{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: { sm: "18px", xs: "18px" },
                fontFamily: "ABLE",
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
                fontWeight: "600",
              }}
            >
              Actual size: 9.7 X 7.6 X 3.2 cm{" "}
              {/* <Typography
                component={"span"}
                sx={{ fontWeight: "300", fontFamily: "ABLE" }}
              >
                (Actual size: 9.7 X 7.6 X 3.2 cm)
              </Typography> */}
            </Typography>
          </Typography>
          {productData?.id === 10 && (
            <Box
              pt={"1.5rem"}
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                  "& p": {
                    fontSize: "20px",
                    fontFamily: "ABLE !important",
                  },
                },
              }}
            >
              <Typography pb={"0.5rem"} fontWeight={600}>
                Do you need a GO Professional System? If{" "}
                <span style={{ textDecoration: "underline" }}>ONE</span> or more
                of the following is true, you will need the GO Professional
                System.
              </Typography>
              <Typography>
                “I want to take advantage of its advanced features, e.g., HRV
                algorithms.”
              </Typography>
              <Typography>
                “More than one person will be using the GO for work with
                clients.”
              </Typography>
              <Typography>
                “We will be using more than one GO in our practice.”
              </Typography>
              <Typography>
                “Our clients will monitor themselves and collect data in the
                field.”
              </Typography>
              <Typography>“We plan to rent instruments to clients.”</Typography>
              <Typography>
                “We plan to do group work (i.e., work with multiple clients
                simultaneously).”
              </Typography>
              <Typography>
                “We want to customize data reports and write report notes.”
              </Typography>
              <Typography>
                “Clients and/or trainers will need their own logins to see their
                data.”
              </Typography>
              <Typography>
                “We need additional assessment tools, such as interview forms.”
              </Typography>
              <Typography>
                “We are using the GO for research purposes.”
              </Typography>
            </Box>
          )}
        </Box>
        {productData?.id === 10 && <GoProfessionalInfo />}
        {productData?.id === 67 && <GoBasicInfo />}
        {productData?.id === 12 && <GoPersonal />}
        {productData?.id === 13 && <GoPersonalC />}
        {productData?.id === 14 && <GoPersonalM />}
        {/* <Typography
          component={"span"}
          dangerouslySetInnerHTML={{
                          __html:description,
                        }}
          sx={{
            "& p":{
          wordBreak:"break-word",
          fontSize: "20px",
          textAlign: "justify",
          fontFamily: "ABLE !important",
        }    
          }}
        />    */}
      </Box>
    </>
  );
};

export default ContentAndImageGrid;
