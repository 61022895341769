import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import logo from "../Assets/Images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import ProductComparision from "./ShoppingCart/ProductComparision";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
 
  return (
    <DialogTitle sx={{ m: 0, p: "10px", color: "rgb(109 79 160)" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#000",
            top: "-10px",
            background: "#fff",
            border:"0.5px solid rgb(109 79 160)",
            "&:hover": {
              background: "#fff",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Subscribe({ subscribeModal, setSubscribeModal }) {




  const closeHandle = () => {
    setSubscribeModal(false);

  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => closeHandle() }
        aria-labelledby="customized-dialog-title"
        open={subscribeModal}
        sx={{
          "& .MuiPaper-root": {
            overflowY: "inherit",
          },
          "& .MuiDialog-paper": {
            width: "1222px",
            maxWidth:"1222px",
            // background: "#E4D5F4",
            // border: "5px solid #fff",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => closeHandle()}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "space-between", xs: "center" },
              flexDirection: { sm: "row", xs: "column" },
              alignItems: "center",
              "& .MuiTypography-root": {
                fontSize: "30px",
              },
            }}
          >
          </Box>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              background: "#fff",
              padding: "0 1.5rem 1.5rem",
              borderRadius: "12PX",
              "& label": {
                mb: "0.3rem",
                display: "block",
                color: "#000",
                fontSize: "18px",
                fontFamily: "ABLE !important",
              },
            }}
          >
            <ProductComparision value={1} />
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
