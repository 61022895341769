import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import Copyright from "../../Components/Copyright";
import Title from "../../Components/Title";

const Subscribe = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <Container>
      <Navbar />
      <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up">
        <Title title={`Subscribe`} paddingb={'1.5rem'} />
        <Box
          sx={{
            width: { md: "50%" ,sm:"75%"},
            margin: "0 auto",
          }}
        >
          <div
            class="ctct-inline-form"
            data-form-id="c92f3cf7-5f23-44f7-908c-bf70d551a4f8"
          ></div>
        </Box>
      </Box>
    </Container>
  );
};

export default Subscribe;
