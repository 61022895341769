export const TrainingData=[
    {
        id:1,
        title:"A problem…",
        subTitle:"Statistics suggest that tens millions of people world-wide suffer with the profound and misunderstood symptoms and deficits of learned dysfunctional breathing habits. Unfortunately, these habits are rarely identified by practitioners, their effects mistakenly attributed to other causes, and their resolutions prescriptive in nature where focus is on symptoms rather than on causes."
    },
    {
        id:2,
        title:"Professional Certificate: Science of Breathing Behavior Analysis",
        subTitle:"Earning this Certificate requires completing three webinars during a trimester, including: Respiratory Psychophysiology, Breathing Habit Assessment, and Breathing Habit Modification. These courses constitute three units of credit and 45 professional CE hours."
    },
    {
        id:3,
        title:"Professional Certification: Certified Breathing Behavior Analyst",
        subTitle:"Becoming Certified as a Breathing behavior Analyst requires completing the Proseminar, Case Analysis, Review, and Practicum, during the trimester following the Certificate training. During the Proseminar candidates present cases of their own and provide feedback for colleagues. The Proseminar constitutes another three units of credit and 45 professional CE hours."
    },
]