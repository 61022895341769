import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import cart_empty from "../../Assets/Images/cart_empty.png";

import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import NewTypography from "../../Components/NewTypography";
// import { EmptyCart, RemoveToCart, addToCart } from "../../redux/Action";

import { useSelector } from "react-redux";
import CartItem from "./CartItem";
import PriceDetail from "../../Components/PriceDetail";
import SuccesAlert from "../../Components/SuccesAlert";
import MaxQtyMessage from "../../Components/MaxQtyMessage";
import ReminderModal from "./ReminderModal";

const Cart = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [maxQnty, setMaxQnty] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addSuccessMsg, setAddSuccessMsg] = useState("");
  const [reminder, setReminder] = useState(false);

  const { data: result } = useSelector((state) => state.cart);

  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    if (result?.data) {
      const totalPriceAdd = result.data.reduce(
        (total, item) => total + (item.total_price || 0),
        0
      );
      setTotalPrice(totalPriceAdd);
    }
  }, [result]);
  // const { loading } = useSelector((state) => state.remove);
  useEffect(() => {
    if (result?.data?.length > 0) {
      if (result?.data?.some((item) => item.product_id == 50)) {
        setReminder(false);
      } else if (!reminder) {
        //   setTimeout(() => {
        setReminder(true);
        //   }, 2500);
      }
    }
  }, [result?.data]);
  return (
    <Container>
      <SuccesAlert showSnackbar={showSnackbar} addSuccessMsg={addSuccessMsg} />
      <MaxQtyMessage maxQnty={maxQnty} />
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`Shopping cart`} paddingb={"1rem"} />

        {result?.data?.length === (0 || undefined) ? (
          <Box
            sx={{
              background: "#F7F7F9",
              borderRadius: "8px",
              textAlign: "center",
              padding: "5rem 2rem",
              my: "2rem",
            }}
          >
            {" "}
            <Typography
              component={"img"}
              src={cart_empty}
              sx={{ width: { sm: "35%", xs: "50%" } }}
            />
            <Typography
              sx={{
                fontSize: {
                  sm: "30px",
                  xs: "24px",
                  lineHeight: "30px",
                  fontFamily: "ABLE",
                },
              }}
            >
              Your shopping cart is currently empty.
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2} mt={"0.5rem"}>
            <Grid item md={8} sm={12} xs={12}>
              <Box sx={{ border: "1px solid rgb(109 79 160)", p: "1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgb(109 79 160)",
                    pb: "0.7rem",
                  }}
                >
                  <div>
                    <NewTypography
                      text={`My Cart (${result?.data?.length} items)`}
                      fsm={"20px"}
                      fxs={"18px"}
                      lsm={"30px"}
                      lxs={"23px"}
                      textalign={"start"}
                      fontWeight={600}
                    />
                  </div>
                  <div>
                    <NewTypography
                      text={`Total: $ ${totalPrice}`}
                      fsm={"20px"}
                      fxs={"18px"}
                      lsm={"30px"}
                      lxs={"23px"}
                      textalign={"start"}
                      fontWeight={600}
                    />
                  </div>
                </Box>
                {result?.data?.map((item, index) => (
                  <CartItem
                    item={item}
                    key={index}
                    setMaxQnty={setMaxQnty}
                    setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <PriceDetail result={result} totalPrice={totalPrice} />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box pt={"5rem"}>
        <Copyright />
      </Box>
      <ReminderModal setReminder={setReminder} reminder={reminder} />
    </Container>
  );
};

export default Cart;
