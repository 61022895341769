import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../../Components/Title";
import Copyright from "../../../Components/Copyright";
import capno_trainer_img from "../../../Assets/Images/book.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../../Components/Navbar";
import NewTypography from "../../../Components/NewTypography";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import cart_bg from "../../../Assets/Images/cart_bg.png";
import BookOrder from "../BookOrder/BookOrder";
import PlaceOrder from "./PlaceOrder";
import ImageModal from "../../../Components/ImageModal";
import { ContractContext } from "../../../Context/ContractContext";

const BookCart = () => {
  const { show } = useContext(ContractContext);
  const [count, setCount] = useState(1);
  const [newAdd, setNewAdd] = useState(false);
  const [poppup, setPoppup] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryShipping, setSelectedCountryShipping] = useState(null);
  
  let selected_country;
  if (newAdd === false) {
    selected_country = selectedCountry;
  } else {
    selected_country = selectedCountryShipping;
  }
  const shippingFee = selected_country?.name === "United States" ? 4 : 15;

  const unitPrice = 45;

  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container>
      <Navbar />

      <Box
        sx={{ color: "rgb(109 79 160)", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`Cart`} paddingb={'0rem'} />
        <Grid container spacing={2} mt={"1rem"}>
          <Grid item md={8} sm={12} xs={12}>
            <Box sx={{ border: "1px solid rgb(109 79 160)", p: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgb(109 79 160)",
                  pb: "0.7rem",
                }}
              >
                <NewTypography
                  text={"My Cart"}
                  fsm={"18px"}
                  fxs={"18px"}
                  lsm={"30px"}
                  lxs={"23px"}
                  textalign={"start"}
                  fontWeight={"600"}
                  textTransform="uppercase"
                />
              </Box>
              <Grid container spacing={3} mt={"0.3rem"}>
                <Grid item md={4} sm={12} xs={12}>
                  <Box
                    sx={{
                      backgroundImage: `url(${cart_bg})`,
                      border:"1px solid",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "100% 100%",
                      backgroundSize: "cover",
                      p: "0.4rem",
                      borderRadius: "6px",
                      textAlign: "center",
                      cursor: "pointer",
                      "&:hover": {
                        background: "rgb(0 0 0 / 18%)",
                        "& img":{
                          // filter:"blur(1px)"
                        }
                      },
                    }}
                    onClick={() => setPoppup(true)}
                  >
                    <Typography
                      component={"img"}
                      src={capno_trainer_img}
                      sx={{
                        width: { md:"115px",sm: "100%", xs: "100%" },
                        height: { md: "177px", sm: "100%", xs: "100%" },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{ textAlign: { md: "start", xs: "center" } }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        xs: "20px",
                        lineHeight: "30px",
                        fontFamily: "ABLE",
                        fontWeight: "600",
                      },
                    }}
                  >
                    CapnoLearning™
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        xs: "20px",
                        lineHeight: "30px",
                        fontFamily: "ABLE",
                        fontWeight: "600",
                      },
                    }}
                  >
                    An Introductory Guide
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "17px",
                        xs: "17px",
                        lineHeight: "25px",
                        fontFamily: "ABLE",
                      },
                    }}
                  >
                    Peter M. Litchfield, Ph.D. and Sandra Reamer, MFA, MS, CBBA,
                    CBBP, CSOM, CBT
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12} textAlign={"center"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: "18px", xs: "16px" },
                        lineHeight: "30px",
                        fontFamily: "ABLE",
                        fontWeight: "600",
                      }}
                    >
                      Unit Price
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { sm: "18px", xs: "16px" },
                        lineHeight: "30px",
                        fontFamily: "ABLE",
                        fontWeight: "600",
                      }}
                    >
                      $ {count * unitPrice}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: "0.5rem",
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: "18px", xs: "16px" },
                        fontFamily: "ABLE",
                        fontWeight: "600",
                      }}
                    >
                      Quantity
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          border: "1px solid rgb(109 79 160)",
                          fontSize: "20px",
                          fontFamily: "ABLE",
                          borderRadius: "6px 0 0 6px",
                          width: "60px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {count}
                      </Box>
                      <Box
                        sx={{
                          background: "rgb(109 79 160)",
                          borderRadius: "0px 6px 6px 0",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <ExpandLess
                          onClick={increment}
                          sx={{
                            cursor: "pointer",
                            width: "1.3rem",
                            height: "1.3rem",
                            color: "#fff",
                          }}
                        />
                        <ExpandMore
                          onClick={decrement}
                          sx={{
                            cursor: "pointer",
                            width: "1.3rem",
                            height: "1.3rem",
                            color: "#fff",
                            "&[disabled]": {
                              pointerEvents: "not-allowed",
                              opacity: 0.6, // Adjust the opacity as needed
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            {show && (
              <PlaceOrder
                shippingFee={shippingFee}
                count={count}
                unitPrice={unitPrice}
              />
            )}
          </Grid>
        </Grid>
        <BookOrder
          count={count}
          unitPrice={unitPrice}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedCountryShipping={selectedCountryShipping}
          setSelectedCountryShipping={setSelectedCountryShipping}
          shippingFee={shippingFee}
          newAdd={newAdd}
          setNewAdd={setNewAdd}
        />
      </Box>
      <Box pt={"5rem"}>
        <Copyright />
      </Box>
      <ImageModal poppup={poppup} setPoppup={setPoppup} />
    </Container>
  );
};

export default BookCart;
