import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
// import { Link } from "react-router-dom";
import book from "../../Assets/Images/book.png";

const Book = () => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "rgb(109 79 160)", mt: "2rem"  }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title
          title={
            <>
              <span style={{ fontSize: "2.5rem" }}>CapnoLearning™ </span>
              <br /> <span style={{ fontSize: "2rem" }}>An Introductory Guide</span> 
            </>
          }
     
          position={"absolute"}
          paddingb={'2rem'}
        />
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12} >
            <Typography
              sx={{
                pt:{lg:'3rem'},
                pb: "1rem",
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
              }}
            >
              Breathing habits, optimal respiration, and acid-base physiology
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "rgb(109 79 160)",
                fontWeight:"600"
              }}
            >
              Peter M. Litchfield, Ph.D.
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "20px", xs: "20px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
              }}
            >
              Professional School of Behavioral Health Sciences
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "50px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
                fontWeight:"600"
              }}
            >
              and
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "20px", xs: "20px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "rgb(109 79 160)",
                fontWeight:"600"
              }}
            >
              Sandra Reamer, MFA, MS, CBBA, CBBP, CSOM, CBT
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
              }}
            >
              Professional School of Behavioral Health Sciences
            </Typography>
            <Box pt={"1rem"}>
              {/* <Typography
                sx={{
                  fontSize: { sm: "22px", xs: "20px" },
                  fontFamily: "ABLE",
                  lineHeight: { sm: "30px", xs: "30px" },
                  textAlign: { sm: "start", xs: "justify" },
                  color: "#040404",
                }}
              >
                Click here to{" "}
                <a
                  href="#learn-more"
                  onClick={() => setShow(!show)}
                  style={{
                    textDecoration: "underline",
                    color: "rgb(109 79 160)",
                    cursor: "pointer",
                  }}
                >
                  learn more
                </a>{" "}
                about the book.
              </Typography> */}
            </Box>
            <Box pb={"2rem"}>
              <Typography
                sx={{
                  fontSize: { sm: "22px", xs: "20px" },
                  fontFamily: "ABLE",
                  lineHeight: { sm: "30px", xs: "30px" },
                  textAlign: { sm: "start", xs: "justify" },
                  color: "#040404",
                }}
              >
                Buy the book and support the {" "}
                <em
                >
                 Breathing Science Journal
                </em>
                <br/>
                by making {" "}
                <a
                          style={{
                            textDecoration: "underline",
                            color: "rgb(109 79 160)",
                            fontWeight:"600"
                          }}
                          href="https://www.thebsj.org/a/2147732394/GYhjaEjN"
                          target="_blank"
                        >
                          a tax-deductible purchase
                        </a> {" "}
                from the publisher.
                <br /><br /><strong>e-book:</strong> $35
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
                fontWeight: "600"
              }}
            >
              Publisher
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "30px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "rgb(109 79 160)",
                fontWeight:"600"
              }}
            >
              Breathing Science, Inc.
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "30px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
              }}
            >
              A 501(c)(3) nonprofit corporation
              <br />
              Cheyenne, Wyoming USA
            </Typography>
            {/* <Typography
              sx={{
                py: "1rem",
                fontSize: { sm: "24px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "rgb(109 79 160)",
              }}
            >
              SUPPORT THE BREATHING SCIENCE JOURNAL.
              <br />
              BUY THIS BOOK.
            </Typography> */}
          </Grid>
          <Grid item md={6} sm={12} xs={12} textAlign={"center"}>
            <Typography
              component={"img"}
              src={book}
              sx={{
                width: { lg: "60%", xs: "80%" },
                mt:{md:"2rem"}
              }}
            />
          </Grid>
        </Grid>
        {show && (
          <Box id="learn-more">
            <Typography
              sx={{
                pt: "1rem",
                fontSize: { sm: "26px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                fontWeight:600
              }}
            >
              Breathing habits, optimal respiration, and acid-base physiology
            </Typography>
            <Box pt={"1.5rem"}>
              <NewTypography
                text={
                  "Breathing is a much bigger subject than you might have ever imagined. It is about much more than simple mechanics, which are good or not so good, e.g., breathing with the diaphragm, or not. It is about much more than oxygen in and carbon dioxide out. Merely focusing on breathing techniques to make you feel better, e.g., slow breathing for relaxation, overlooks the enormity of role of breathing as behavior in health and performance."
                }
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>
            <Box pt={"1.5rem"}>
              <NewTypography
                text={
                  "Breathing is behavior and is driven psychologically, not just physically. This means that we all learn breathing habits, bad ones and good ones. Good breathing optimizes respiration and balances acid-base physiology. It requires precision reflex-management of CO2 in the blood (and elsewhere) that regulates body pH from moment to moment. Bad breathing habits compromise this physiology and can trigger insidiously debilitating outcomes, e.g., a panic attack. Good breathing habits can optimize this physiology and enhance health and performance both immediately and in the long term, e.g., a successful public presentation."
                }
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>
            <Box pt={"1.5rem"}>
              <NewTypography
                text={
                  "Breathing and respiration are not the same thing. Breathing is behavior. Respiration is reflexive. Breathing habits operate based on their triggers, motivation and psychological outcomes. When these habits are not aligned with respiratory chemistry, there is trouble, lots of it. Are your habits working for you, or against you? How are they affecting you? What can you do about them? Unfortunately, there are many misunderstandings about breathing and respiration that are the basis of misguided breathing self-help techniques everywhere."
                }
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>

            <Box pt={"1.5rem"}>
              <NewTypography
                text={
                  "CapnoLearning brings together physiology, psychology, and technology to create powerful tools for identifying good and bad breathing habits, for learning to disengage habits that compromise physical and mental competence, and for learning new breathing habits that promote health and performance. Understanding breathing as behavior makes these objectives possible through implementing the principles and applications of breathing behavior analysis. This guide provides both an introduction to CapnoLearning and 14 different practicums using a CapnoTrainer."
                }
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>
          </Box>
        )}
        {/* <Box sx={{ p: "1rem 0 1rem" }}>
          <Typography
            sx={{
              fontSize: { sm: "28px", xs: "20px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "40px", xs: "30px" },
            }}
            component={Link}
            to="/book-payment"
            color={"#0038FF"}
          >
            Purchase here
          </Typography>{" "}
          <Typography
            component={"span"}
            sx={{
              fontSize: { sm: "28px", xs: "20px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "40px", xs: "30px" },
            }}
          >
            $45.00
          </Typography>
        </Box> */}

        <Box pt={"3rem"}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default Book;
