import { Typography } from "@mui/material";
import React from "react";

const NewTypographyTech = ({ text,text2, fsm, fxs, lsm, lxs, link,textalign,color,fontWeight,subtitle}) => {
  return (
    <>
    <Typography
      sx={{
        fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        textAlign: { sm: "start", xs: textalign },
        fontWeight:fontWeight,
        color:color && color
      }}
      className="title"
    >

      {link && (
        <>

        <Typography
          component={"a"}
          href={link}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            color: "rgb(109 79 160)",
            fontWeight:600
            // textAlign: { sm: "start", xs: "justify" },
          }}
        >
          {text}
        </Typography>
        
        {" "}
        </>
      )} {text2}{" "}
    </Typography>        
    <span color={"#000"} fontSize={"18px"}><i>{subtitle}</i></span>
    </>
  );
};

export default NewTypographyTech;
