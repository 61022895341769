import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../Components/Navbar";
import Copyright from "../../Components/Copyright";
import capno_trainer_img from "../../Assets/Images/success.png";
import { Link } from 'react-router-dom'

const Thankyou = () => {


  return (
    <Container>
      <Navbar />
      <Box
        sx={{
          width: { md: "60%", xs: "auto" },
          margin: "5rem auto 2rem",
          borderRadius: "10px",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          padding: "1.5rem",
          textAlign: "center",
          height: {sm:"500px",xs:"300px"},
          boxShadow: "rgb(146, 141, 191) 0px 2px 12px",
        }}
      >
        <Box>
          <Box
            sx={{
              color: "rgb(109 79 160)",
              fontSize: "2rem",
              mb: "2rem",
              display: "flex",
              alignItems: "center",
              flexDirection: { md: "column", xs: "column" },
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                // background: "green",
                height: { sm: "100px", xs: "60px" },
                width: { sm: "100px", xs: "60px" },
                // borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pb: "1rem",
                fontWeight:600
              }}
            >
              <Typography
                component={"img"}
                src={capno_trainer_img}
                sx={{
                  width: { sm: "100%", xs: "100%" },
                }}
              />
            </Box>
            Order Placed Successfully.
          </Box>
                <Button
                 sx={{
            
                  background: "rgb(109 79 160) !important",
                  color: "#fff !important",
                  fontFamily: "ABLE",
                  p: "0.7rem",
                  fontSize: "18px !important",
                  lineHeight: "initial !important",
                  border: "2px solid #C5A7CF !important",
                  width: "100%",
                  "&:hover": {
                    background: "rgb(109 79 160)",
                  },
                }}
                component={Link} to="/shopping-cart"
                >Continue Shopping</Button>
       
         
        </Box>
      </Box>

      <Box sx={{
        pt:{sm:"10rem",xs:"5rem"}
      }}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Thankyou;
