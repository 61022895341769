import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../Components/Title";
import Copyright from "../Components/Copyright";
import mission from "../Assets/Images/mission.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../Components/Navbar"

const Mission = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <Container>
     <Navbar />
      <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
        <Title title={`Mission`} paddingb={'1.5rem'} />
        <Typography
          sx={{
            fontSize: { sm: "1.375rem", xs: "1.25rem" },
            fontFamily: "ABLE",
            lineHeight: { sm: "2.15rem", xs: "2rem" },
            textAlign: { sm: "start", xs: "justify" },
            pb: "1rem",
          }}
        >
          Our mission is to bring together physiology, psychology, and
          technology to create powerful learning tools for identifying good and
          bad breathing habits, for learning to disengage bad ones that
          compromise physical and mental competence, and for learning new ones
          that promote health and performance.
        </Typography>
        <Box
          sx={{
            height: { sm: "50vh" },
          }}
        />
        <Box pt={"5rem"} >
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default Mission;
