import { FormControlLabel, Radio, Typography } from "@mui/material";
import axios from "axios";
import React from "react";

const ChangeShipping = ({
  e,
  setCheckOut,
  selectedCountry,
  orderId,
  setShipping,
  setItemTotalAmount,
  setTotalAmount,
  setDeliveryDate,
  shippingNew,
  setShippingNew
}) => {
  const shippingOption = async () => {
    setTotalAmount(0)
    setCheckOut(false)
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API}/shopping-cart/change/shipping-option`,
        {
          order_id: orderId,
          full_name: e.full_name,
        }
      );
      if (res.data.status) {
        setShipping(res.data.total_shippingRate);
        setItemTotalAmount(res.data.totalProductPrice);
        setDeliveryDate(res.data.DeliveryDate);
        setShippingNew(e.full_name)
        setTotalAmount(res.data.total_price_with_shipping);
        setTimeout(() => {
        setCheckOut(true)
        },1000)
        
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <FormControlLabel
      sx={{
        "& .MuiFormControlLabel-label" : {
                color: "#000!important",
                fontFamily:"ABLE",
                fontSize:"18px"
              }
      }}
        value={e.full_name}
        control={
          <Radio
            disableRipple
            name="shippingoption"
            defaultChecked={
              shippingNew == e.full_name
                ? true
                : false
            }
            onClick={shippingOption}
            sx={{
              "& .MuiSvgIcon-root": {
                color: "#000",
              },
              
            }}
          />
        }
        label={`${e.full_name} - $${e.total_charge} (Expected Delivery ${e.delivery_date})`} 
      />
      {/* <Typography color={"#000"}>Total Charges: </Typography> */}
    </>
  );
};

export default ChangeShipping;
