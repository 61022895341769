import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../Components/Title";
import Copyright from "../Components/Copyright";
import interview_img from "../Assets/Images/interview_img.jpg";
import NewTypography from "../Components/NewTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../Components/Navbar";
import InterviewTypogray from "./InterviewTypogray";
import InterviewVideo from "../Components/InterviewVideo";

const Interview = () => {
  const [videoLink, setVideoLink] = useState("");
  const [interviewModal, setInterviewModal] = useState(false);
  const handleVideo = (link) => {
    setVideoLink(link);
    setInterviewModal(true);
  };
  useEffect(() => {
    AOS.init();
  }, []);

  const url1 = `https://better-physiology-videos.s3.amazonaws.com/Interview-with-Sandy-Abrams-Nov-8-2021.mp4`;
  const url2 = `https://better-physiology-videos.s3.amazonaws.com/Denise-Campbell-2-cases.mp4`;
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
        width={"100%"}
        display={"block"}
      >
        <Title title={`Interviews`} paddingb={"0rem"} />

        <Box sx={{ pt: "1rem" }}>
          <Box sx={{ pt: "1rem", width: { md: "50%", xs: "100%" } }}>
            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                lineHeight: { sm: "2.2rem", xs: "2rem" },
                fontFamily: "ABLE",
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
              }}
            >
              Click here to see an interview with Peter Litchfield and Sandy
              Abrams regarding breathing misconceptions, myths, and
              pseudoscience:{" "}
              <Typography
                component={"a"}
                sx={{
                  textDecoration: "underline",
                  color: "rgb(109 79 160)",
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  lineHeight: { sm: "2.2rem", xs: "2rem" },
                  fontFamily: "ABLE",
                  cursor: "pointer",
                  fontWeight:600
                }}
                onClick={() => handleVideo(url1)}
              >
                INTERVIEW.
              </Typography>
            </Typography>
            <Box sx={{ pt: "1rem" }}>
              <Typography
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  lineHeight: { sm: "2.2rem", xs: "2rem" },
                  fontFamily: "ABLE",
                  textAlign: { sm: "start", xs: "justify" },
                  color: "#040404",
                }}
              >
                Click here to see an interview with Peter Litchfield and Denise
                Campbell regarding a breathing behavior analysis of two clients:{" "}
                <br />
                <Typography
                  component={"a"}
                  sx={{
                    textDecoration: "underline",
                    color: "rgb(109 79 160)",
                    fontSize: { sm: "1.375rem", xs: "1.2rem" },
                    lineHeight: { sm: "2.2rem", xs: "2rem" },
                    fontFamily: "ABLE",
                    cursor: "pointer",
                    fontWeight:600
                  }}
                  onClick={() => handleVideo(url2)}
                >
                  INTERVIEW.
                </Typography>
              </Typography>
            </Box>
          </Box>

          <Box
            pt={"0rem"}
            sx={{
              display: { md: "block", xs: "none" },
              width: { md: "50%", xs: "50%" },
              m: "0 auto",
            }}
          >
            {" "}
            <Typography
              component={"img"}
              style={{
                position: "absolute",
                width: "400px",
                right: "0px",
                top: "20px",
              }}
              src={interview_img}
              width={"70%"}
            />
          </Box>
          <Box
            pt={"2rem"}
            sx={{
              display: { md: "none", xs: "block" },
              width: { sm: "80%", xs: "100%" },
              margin: "0 auto",
            }}
          >
            <Typography component={"img"} src={interview_img} width={"100%"} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: { md: "30vh", xs: "0" } }} />
      <Box pt={"3rem"}>
        <Copyright />
      </Box>

      <InterviewVideo
        interviewModal={interviewModal}
        setInterviewModal={setInterviewModal}
        videoLink={videoLink}
      />
    </Container>
  );
};

export default Interview;
