import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";

const GoBrochure = () => {
  useEffect(() => {
    AOS.init();
  }, []);         
  return (
    <Container>
      <Navbar /> 
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`GO Brochure`}  />
        <Typography
                    sx={{
                      pb: "1rem",
                      fontSize: { sm: "16px", xs: "16px" },
                      fontFamily: "ABLE",
                      lineHeight: { sm: "20px", xs: "20px" },
                      textAlign: { sm: "start", xs: "start" },
                      fontWeight: "600",
                      fontStyle:"italic"
                    }}
                  >
                  Can't view the PDF? Please   <a
                  href="https://better-physiology-videos.s3.amazonaws.com/CapnoTrainer+GO+Brochure.pdf"
                  target="_blank"
                      style={{
                        textDecoration:"underline",
                        color: "rgb(109 79 160)",
                        fontSize: "16px",
                        fontWeight: "700",
                      fontStyle:"italic",
                        cursor: "pointer",
                      }}
                   
                    >
                       Click here
                    </a>{" "} to download.
                  </Typography>
        <embed
          src="https://better-physiology-videos.s3.amazonaws.com/CapnoTrainer+GO+Brochure.pdf#toolbar=0"
          // src="https://better-physiology-videos.s3.amazonaws.com/GO-Brochure-Brochure-03-2023.pdf#toolbar=0"
          type="application/pdf"

          width="100%"
          height="700"
        ></embed>
        {/* <PdfView /> */}
      </Box>
      <Box pt={"1rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default GoBrochure;
