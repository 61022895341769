import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./BookOrderStyle";

const BillingInfo = ({ title, textObj }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        borderRadius: "8px",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        pb: "1.5rem",
        pt: "0.5rem",
        px: "1rem",
        "& p":{
          color:"#000"
        }
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid rgb(109 79 160)",
          py: "0.5rem",
          fontSize: "20px",
          fontWeight:600
        }}
      >
        {title}
      </Box>
      <Grid container spacing={2} mt={"0.5rem"} sx={{"& .MuiGrid-item" : {paddingTop : "5px"} }}>
        <Grid item md={6} sm={12} xs={12} >
          <Typography className={classes.text}>
            First Name : {textObj.fname}
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            Last Name : {textObj.lname}
          </Typography>
        </Grid>
        {textObj.company && (
          <Grid item md={6} sm={12} xs={12}>
            <Typography className={classes.text}>
              Company name : {textObj.company}
            </Typography>
          </Grid>
        )}
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            Country : {textObj.country}
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            Address Line 1 : {textObj.address_1}
          </Typography>
        </Grid>
        {textObj.address_2 && (
          <Grid item md={6} sm={12} xs={12}>
            <Typography className={classes.text}>
              Address Line 2 : {textObj.address_2}
            </Typography>
          </Grid>
        )}
        {textObj.address_3 && (
          <Grid item md={6} sm={12} xs={12}>
            <Typography className={classes.text}>
              Address Line 3 : {textObj.address_3}
            </Typography>
          </Grid>
        )}
        {textObj.address_4 && (
          <Grid item md={6} sm={12} xs={12}>
            <Typography className={classes.text}>
              Address Line 4 : {textObj.address_4}
            </Typography>
          </Grid>
        )}
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            City : {textObj.city}
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            State : {textObj.state}
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            Postal Code : {textObj.postal}
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            Phone : {textObj.phone}
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            Email : {textObj.email}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingInfo;
