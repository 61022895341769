import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import capnoKit_new from "../Assets/Images/new_1_.png";
import new_kit from "../Assets/Images/new_kit-min_bg.png";
import new_2 from "../Assets/Images/new_2_.png";
import new_3 from "../Assets/Images/new_3_.png";

const images = [
  { src: new_kit, alt: "new_kit" },
  { src: capnoKit_new, alt: "CapnoKit New",paddingTop:"3rem" },
  { src: new_2, alt: "New 2" },
  { src: new_3, alt: "New 3" },
];

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px 0", color: "rgb(109 79 160)" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#fff",
            top: "-10px",
            background: "rgb(109 79 160)",
            borderRadius: "100%",
            "&:hover": {
              background: "rgb(109 79 160)",
            },
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ViewImages({ view, setView }) {
  const closeHandle = () => {
    setView(false);
  };

  return (
    <div>
      <Dialog
        onClose={closeHandle}
        aria-labelledby="customized-dialog-title"
        open={view}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1rem",
            border: "none",
          },
          "& .MuiDialogActions-root": {
            padding: "1rem",
          },
          "& .MuiPaper-root": {
            overflowY: "inherit",
          },
          "& .MuiDialog-paper": {
            width: "650px",
            maxWidth: "95%",
            border: "2px solid rgb(109 79 160)",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeHandle}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              borderBottom: "1px solid rgb(109 79 160)",
              pb: "0.3rem",
              mb: "0.3rem",
              display: "flex",
              alignItems: "center",
              gap: "0.1rem",
              fontFamily: "ABLE",
              fontWeight:600
            }}
          >
            Images ({images?.length})
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              textAlign: "center",
              py: "1.5rem",
              fontSize: "1.2rem",
              fontFamily: "ABLE",
              "& .swiper-button-prev:after, .swiper-button-next:after":{
                fontSize:"30px",
                color:"rgb(109 79 160)"
              },
              "& .swiper-button-prev":{
               left:"0 !important"
              },
              "& .swiper-button-next":{
               right:"0 !important"
              },
              "& .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal":{
               bottom:"-5px !important"
              },
              "& .swiper-pagination-bullet-active":{
                background:"rgb(109 79 160)"
              }
            }}
          >
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
              navigation={true}
              pagination={true}
              modules={[Autoplay, Navigation, Pagination]}
              className="mySwiper"
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
             <Box textAlign={"center"}>
             <Typography
                    component={"img"}
                    src={image.src}
                    alt={image.alt}
                    sx={{
                      pt:{xs:image?.paddingTop},
                      width: { sm: "71%", xs: "80%" },
                      height:"100%"
                    }}
                  />
             </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
