import {configureStore} from '@reduxjs/toolkit'
import cartSlice from './cartSlice';
import ProductSlice from './ProductSlice';
import ProductByIdSlice from './ProductByIdSlice';

const store = configureStore(
    {
        reducer:{
            cart:cartSlice,
            products:ProductSlice,
            productById:ProductByIdSlice
        },
    })

export default store;