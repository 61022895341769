import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import blog_bg from "../../Assets/Images/blog_bg.png";
import blog_1 from "../../Assets/Images/BP imgs/blog_1_new.jpg";
import blog_5 from "../../Assets/Images/BP imgs/nutrition.jpg";
import blog_9 from "../../Assets/Images/BP imgs/blog_2_new.jpg";


import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import BlogHoverBox from "./BlogHoverBox";

const Blogs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const blogData=[
    {
        id:1,
        image:blog_1,
        link:"/blog/breathing-guide",
        title:"The Comprehensive Guide to Conscious Breathing for Respiratory Health",
        content:"In the hustle and bustle of modern life, the importance of respiratory health often goes unnoticed.."
    },
    {
        id:2,
        image:blog_5,
        link:"/blog/nutrition",
        title:"Nutrition",
        content:"Maintaining a healthy respiratory system is essential for overall well-being and optimal bodily function.."
    },
    {
        id:3,
        image:blog_9,
        link:"/blog/environmental-impact",
        title:"Understanding the Environmental Impact of Air Pollution on Respiratory Health",
        content:"Air pollution is a pressing global concern with far-reaching implications for public health and the environment.."
    },
  ]
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Box
          sx={{
            backgroundImage: `url(${blog_bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: {sm:"98% 60%",xs:"100%"},
            backgroundSize: "cover",
            height: {sm:"320px",xs:"250px"},
            borderRadius: "5px",
            p: "1rem",
            mb: "1.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {/* <Typography
              sx={{
                fontSize: { sm: "30px", xs: "25px" },
                fontFamily: "ABLE",
                color: "rgb(109 79 160)",
                // lineHeight: { sm: "2.15rem", xs: "2rem" },
                textAlign: { sm: "center", xs: "center" },
                pt: "4rem",
                pb: "2.5rem",
              }}
            >
              Join us on a journey to discover the transformative power of
              optimal breathing!
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: { sm: "row", xs: "column" },
                gap: "0.5rem",
              }}
            >
              <TextField
                autoComplete="off"
                // fullWidth
                variant="standard"
                type="text"
                placeholder="Enter your email here..."
                id="fname"
                name="fname"
                sx={{
                  borderRadius: "5px",
                  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
                  border: "1px solid rgb(109 79 160)",
                  width: { sm: "400px", xs: "100%" },
                  background: "#ffffffa1",
                  color: "#fff",
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "&:focus-Visible": {
                    outline: "none !important",
                    border: "1px solid rgb(109 79 160)",
                  },
                  "&:hover": {
                    border: "1px solid rgb(109 79 160)",
                  },
                  "& .MuiInputBase-root": {
                    "&::before": {
                      display: "none",
                    },
                    "&::after": {
                      display: "none",
                    },
                  },

                  "&:focus": {
                    boxShadow: "none !important",
                    border: "1px solid rgb(109 79 160)",
                    borderRadius: "10px !important ",
                  },
                  "& input": {
                    padding: "10px",
                    width: "100%",
                    color: "#000",
                    "&:focus": {
                      outline: "none",
                    },
                    "&::placeholder": {
                      color: "#000",
                      //   color: "#494949",
                    },
                  },
                }}
                // value={formik.values.fname}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
              <Button
                sx={{
                  background: "rgb(109 79 160) !important",
                  color: "#fff !important",
                  fontFamily: "ABLE",
                  padding: "9px 8px !important",
                  lineHeight: "initial !important",
                  fontSize: "17px",
                  width: { sm: "120px", xs: "50%" },
                  "&:hover": {
                    background: "rgb(109 79 160)",
                  },
                  display: "block",
                  m: "0 auto",
                  border: "1px solid transparent",
                  borderRadius: "6px !important",
                  textTransform: "capitalize",
                }}
              >
                Join Now
              </Button>
            </Box> */}
          </Box>
        </Box>
        <Title title={`Blogs`} paddingb={"1.5rem"} />
        <Grid container spacing={2}>
       {
        blogData?.map((data,i)=>(
            <Grid
            item
            md={4}
            sm={12}
            xs={12}
            position={"relative"}
            className="blog_1"
          >
            <Typography
              component={"img"}
              src={data.image}
              width={"100%"}
              height={"100%"}
            />
            <BlogHoverBox content={data.content} link={data.link} title={data.title}/>
          </Grid>
        ))
       }
        </Grid>
        {/* <Grid container spacing={2} mt={"0rem"}>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            position={"relative"}
            className="blog_1"
          >
            <Typography
              component={"img"}
              src={grid_3}
              width={"100%"}
              height={"100%"}
            />
           <BlogHoverBox content="Quality sleep is essential for overall health and well being but many of us struggle to geta restful night’s sleep. one often overlooked factor that can impact sleep quality is out breathing. By learning how to use our..."/> 
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            position={"relative"}
            className="blog_1"
          >
            <Typography
              component={"img"}
              src={grid_4}
              width={"100%"}
              height={"100%"}
            />
           <BlogHoverBox/>
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            position={"relative"}
            className="blog_1"
          >
            <Typography
              component={"img"}
              src={grid_1}
              width={"100%"}
              height={"100%"}
            />
           <BlogHoverBox/>
          </Grid>
        </Grid> */}
        {/* <Box
          sx={{
            height: { sm: "50vh" },
          }}
        /> */}
        <Box pt={"6rem"}>
          <Copyright center="center" />
        </Box>
      </Box>
    </Container>
  );
};

export default Blogs;
