import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Distributors from "./Pages/Distributors/Distributors";
import History from "./Pages/History/History";
import Mission from "./Pages/Mission";
import Book from "./Pages/Book/Book";
import Interview from "./Pages/Interview";
import Lectures from "./Pages/Lectures/Lectures";
import Publications from "./Pages/Publications/Publications";
import Consultation from "./Pages/Consultation";
import Support from "./Pages/Support/Support";
import GoVideo from "./Pages/GoVideos/GoVideo";
import Training from "./Pages/Training/Training";
import GoManual from "./Pages/GoManual/GoManual";
import GoQuickStart from "./Pages/GoQuickStart/GoQuickStart";
import Earlier from "./Pages/5.0Earlier/Earlier";
import GoBrochure from "./Pages/GoBrochure/GoBrochure";
import GoDemo from "./Pages/GoDemo/GoDemo";
import GoOverview from "./Pages/GoOverview/GoOverview";
import Cart from "./Pages/Cart/Cart";
import ShoppingCart from "./Pages/ShoppingCart/ShoppingCart";
import Webinar from "./Pages/Webinar/Webinar";
import GoDownload from "./Pages/GoDownload/GoDownload";
import ProductDetail from "./Pages/ShoppingCart/ProductDetail";
import BookOrder from "./Pages/BookOrder/BookOrder";
import Thankyou from "./Pages/book-payment/Thankyou";
import WebsiteBuildMsg from "./Pages/book-payment/WebsiteBuildMsg";
import BookCart from "./Pages/book-payment/BookCart/BookCart";
import Faq from "./Pages/Faq/Faq";
import Subscribe from "./Pages/Subscribe/Subscribe";
import ProductDetail1 from "./Pages/ShoppingCart/ProductDetail1.js";
import ContentAndImageGrid from "./Pages/ShoppingCart/ContentAndImageGrid.js";
import Annoucement from "./Pages/Annoucement";
import Course from "./Pages/course/Course.js";
import BookPdf from "./Components/BookPdf";
import Blogs from "./Pages/Blogs/Blogs";
import BlogDetailPage from "./Pages/Blogs/BlogDetailPage";
import BlogDetailPage_2 from "./Pages/Blogs/BlogDetailPage_2";
import BlogDetailPage_3 from "./Pages/Blogs/BlogDetailPage_3";
import RedirectToSchool from "./Pages/RedirectToSchool.js";
import ProfessionalEducation from "./Pages/ProfessionalEducation.js";

function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/breathing-guide" element={<BlogDetailPage />} />
        <Route path="/blog/nutrition" element={<BlogDetailPage_2 />} />
        <Route path="/blog/environmental-impact" element={<BlogDetailPage_3 />} />
        <Route path="/distributors" element={<Distributors />} />
        <Route path="/history" element={<History />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/book" element={<Book />} />
        <Route path="/course" element={<Course />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/lectures" element={<Lectures />} />
        <Route path="/references" element={<Publications />} />
        <Route path="/consultation" element={<Consultation />} />
        <Route path="/professional-education" element={<ProfessionalEducation />} />
        <Route path="/go-support" element={<Support />} />
        <Route path="/go-video" element={<GoVideo />} />
        <Route path="/training" element={<Training />} />
        <Route path="/go-manual" element={<GoManual />} />
        <Route path="/go-quickstart" element={<GoQuickStart />} />
        <Route path="/download-5.0" element={<Earlier />} />
        <Route path="/go-brochure" element={<GoBrochure />} />
        <Route path="/request-demonstration" element={<GoDemo />} />
        <Route path="/go-overview" element={<GoOverview />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shopping-cart" element={<ShoppingCart />} />
        <Route path="/webinars" element={<Webinar />} />
        <Route path="/go-download" element={<GoDownload />} />
        <Route path="/go-Detail/:id" element={<ProductDetail />} />
        <Route path="/go-product/:id" element={<ProductDetail1 />} />
        <Route path="/place-order" element={<BookOrder />} />
        <Route path="/success" element={<Thankyou />} />
        <Route path="/message" element={<WebsiteBuildMsg />} />
        <Route path="/book-payment" element={<BookCart />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/announcement" element={<Annoucement />} />
        <Route path="/book-pdf" element={<BookPdf />} />
        <Route path="/webinars/pregnancy" element={<RedirectToSchool />} />
        
        {/* <Route path="/check" element={<ContentAndImageGrid />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
