import * as Yup from "yup";

export const getInitialValues = (
  selectedState,
  selectedCountry,
  newAdd,
  selectedStateShipping,
  selectedCountryShipping
) => {
  return {
    fname: "",
    lname: "",
    company: "",
    address_1: "",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "",
    postal: "",
    phone: "",
    email: "",
    state: selectedState,
    country: selectedCountry,
    checkbox: newAdd,
    fname_s: "",
    lname_s: "",
    company_s: "",
    address_1_s: "",
    address_2_s: "",
    address_3_s: "",
    address_4_s: "",
    city_s: "",
    postal_s: "",
    phone_s: "",
    email_s: "",
    state_s: selectedStateShipping,
    country_s: selectedCountryShipping,
  };
};

export const validationSchema = Yup.object({
  fname: Yup.string()
    .trim("First Name should not contain spaces")
    .required("Required"),
  lname: Yup.string()
    .trim("Last Name should not contain spaces")
    .required("Required"),
  company: Yup.string().trim("Company Name should not contain spaces"),
  address_1: Yup.string()
    .trim("Address should not contain spaces")
    .required("Required"),
  address_2: Yup.string().trim("Address should not contain spaces"),
  address_3: Yup.string().trim("Address should not contain spaces"),
  address_4: Yup.string().trim("Address should not contain spaces"),
  city: Yup.string()
    .trim("City/Town should not contain spaces")
    .required("Required"),
  postal: Yup.string()
    .matches(/^[0-9A-Za-z]+$/, "Invalid postal code format")
    .required("Required"),
  phone: Yup.string()
    .trim()
    .test("phone", "Phone number must contain only numeric digits", (value) => {
      if (!value) {
        // Allow empty values, assuming they are valid
        return true;
      }
      return /^[0-9()-]+$/.test(value);
    })
    .matches(/^[0-9()-]+$/, "Invalid phone number format")
    .required("Phone number is required"),
  email: Yup.string()
    .trim("Email should not contain spaces")
    .email("Invalid email address")
    .required("Email is required"),
  state: Yup.string().nullable().required("Select atleast one state"),
  country: Yup.string().nullable().required("Select atleast one country"),
  checkbox: Yup.boolean(),
  fname_s: Yup.string()
    .trim("First Name should not contain spaces")
    .when("checkbox", {
      is: true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  lname_s: Yup.string()
    .trim("Last Name should not contain spaces")
    .when("checkbox", {
      is: true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  company_s: Yup.string().trim("Company Name should not contain spaces"),
  address_1_s: Yup.string()
    .trim("Address should not contain spaces")
    .when("checkbox", {
      is: true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  address_2_s: Yup.string().trim("Address should not contain spaces"),
  address_3_s: Yup.string().trim("Address should not contain spaces"),
  address_4_s: Yup.string().trim("Address should not contain spaces"),
  city_s: Yup.string()
    .trim("City/Town should not contain spaces")
    .when("checkbox", {
      is: true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  postal_s: Yup.string().when("checkbox", {
    is: true,
    then: Yup.string()
      .matches(/^[0-9A-Za-z]+$/, "Invalid postal code format")
      .required("Required"),
    otherwise: Yup.string(),
  }),
  phone_s: Yup.string().when("checkbox", {
    is: true,
    then: Yup.string()
      .test(
        "phone",
        "Phone number must contain only numeric digits",
        (value) => {
          if (!value) {
            // Allow empty values, assuming they are valid
            return true;
          }
          return /^[0-9()-]+$/.test(value);
        }
      )
      .matches(/^[0-9()-]+$/, "Invalid phone number format")
      .required("Phone number is required"),
    otherwise: Yup.string(),
  }),
  email_s: Yup.string()
    .trim("Email should not contain spaces")
    .when("checkbox", {
      is: true,
      then: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      otherwise: Yup.string(),
    }),
  state_s: Yup.string().when("checkbox", {
    is: true,
    then: Yup.string().nullable().required("Select at least one state"),
    otherwise: Yup.string().nullable(),
  }),

  country_s: Yup.string().when("checkbox", {
    is: true,
    then: Yup.string().nullable().required("Select at least one country"),
    otherwise: Yup.string().nullable(),
  }),
});
