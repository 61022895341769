import { Box, Typography } from "@mui/material";
import React from "react";

const GoPersonal = () => {
  return (
    <Box
      sx={{
        "& p": {
          fontSize: "20px",
          textAlign: "justify",
          fontFamily: "ABLE !important",
        },
      }}
    >
      <Typography pb={"1.5rem"}>
        The GO Personal is for anyone interested in learning about their own
        breathing habits, disengaging self-defeating habits, and learning new
        habits that optimize respiration for improving health and performance.
        The GO Personal is designed to serve you in exploring your breathing
        behavior, habits, and patterns. Is your breathing congruent with
        respiration or is it compromising respiration at certain times and
        places, e.g., social situations? If so, what are the symptoms and/or
        deficits associated with it, e.g., brain fog? What are its specific
        triggers, e.g., taking a test? All breathing habits are unconscious
        solutions: are you aware of how they may be serving you? How can you
        learn to disengage, edit, and learn new habits for optimizing
        respiration? To this end, the GO Personal comes with a digital version
        of the book, <b>CapnoLearning: An Introductory Guide</b> (Breathing
        habits, optimal respiration, and acid-base physiology) that teaches you
        about how to explore answers to these kinds of questions.
      </Typography>
      <Typography pb={"1.5rem"}>
        The GO Personal is battery-operated, Bluetooth, wearable, light weight
        <br/>
        (185 grams), small (fits in your pocket), and nearly silent (slight
        ticking sound). It operates on tablets and I-pads, Android and i-phones,
        and if Option C is purchased with PC and Apple computers. The GO
        Personal also connects to most third-party HRV Bluetooth devices (e.g.,
        Polar Belt) and provides HRV displays along with breathing physiology.
      </Typography>
      <Typography pb={"1.5rem"}>
        The GO Personal instrument operates with an App on cell phones and
        tablets. Track your breathing while exercising, working, and
        socializing. See when and where breathing misaligns with respiration and
        what deficits are associated with it. Learn about breathing from the
        perspective of habits, rather than techniques, and what role they may be
        playing in performance issues, e.g., performance anxiety. Users have a
        personal Cloud account for viewing session data outcomes and downloading
        them as PDF files. Text and audio notes along with screen snapshots are
        automatically appended to sessions.
      </Typography>
      <Typography pb={"1.5rem"}>
        GO Personal users may upgrade with Option C ($300.00), Option M
        ($450.00), GO Basic (both options C and M for $750.00), or GO
        Professional (1,450.00) at any time.
        <b>
          {" "}
          Purchase of both Options redefines the GO Personal as a GO Basic.
        </b>{" "}
      </Typography>
    </Box>
  );
};

export default GoPersonal;
