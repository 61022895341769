import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import NewTypography from "../../Components/NewTypography";
import window_icon from "../../Assets/Images/window_icon.png";
import ios_icon from "../../Assets/Images/ios_icon.png";
import axios from "axios";
import { API_URL } from "../../redux/Constant";

const GoDownload = () => {
  const goDemoData = [
    {
      id: 1,
      downloads: "Click on Download for Windows OR Download for Mac.",
    },
    {
      id: 2,
      downloads:
        "Click on the CapnoTrainer Installer file, e.g., with Chrome, in the lower left corner.",
      text4:
        "(CapnoTrainer-installer.exe for PC, or CapnoTrainer-installer.dmg for Apple)",
    },
    {
      id: 3,
      downloads:
        "Read and follow the instructions that appear in an installation window.",
      text1:
        "If you are using a PC, the CapnoTrainer icon will now appear on your desktop.",
      text2:
        "If you are using a MAC, the CapnoTrainer icon will appear in the Applications folder.",
    },
    {
      id: 4,
      downloads: "Click on the CapnoTrainer icon.",
    },
    {
      id: 5,
      downloads: "A sign-in window will appear. ",
    },
    {
      id: 6,
      downloads: "If you are a new Client, Renter or Trainer, enter your EMAIL address and your temporary PASSWORD.",
      text6: "IF YOU ARE A CLIENT, RENTER, OR TRAINER, STOP HERE."
    },
    {
      id: 7,
      downloads: "If you are NEW GO CUSTOMER, click on CREATE account to activate your software.",
    },
    {
      id: 8,
      downloads: "Enter your EMAIL.",
    },
    {
      id: 9,
      downloads: "Create a PASSWORD.",
    },
    {
      id: 10,
      downloads: "Enter your SERIAL NUMBER.",
    },
    {
      id: 11,
      downloads: "Click on CREATE YOUR ACCOUNT.",
    },
  ];
  useEffect(() => {
    AOS.init();
  }, []);
  const [data, setData] = useState([]);
  const getData = async () => {
    try {
      const res = await axios.get(
        `https://apis.capnolearning.com/api/get/Specific/software/data`
      );
      if (res.status === 200) {
        console.log(res.data.software_key);
        setData(res.data.software_key);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Navbar />
      <Box sx={{ color: "#000", mt: "1.5rem" }} data-aos="fade-up">
        <Title title={`GO Downloads`} paddingb={"1.5rem"} />
        <Typography
          sx={{
            fontSize: { sm: "1.375rem", xs: "1.2rem" },
            fontFamily: "ABLE",
            lineHeight: { sm: "2.1rem", xs: "2rem" },
            textAlign: { sm: "start", xs: "justify" },
            pb: "1rem",
            pt: { sm: "0.3rem" },
          }}
        >
          Please follow the instructions below to download your GO software,
          whether for the first time or for subsequent downloads. The
          instructions are the same for all versions of the GO: Personal (with
          Option C), Basic, and Professional.
        </Typography>
        <Grid
          container
          spacing={3}
          mb={"1rem"}
          // mt={"0.5rem"}
          justifyContent={"center"}
          sx={{
            "& a": {
              color: "#000",
              textDecoration: "none",
            },
          }}
        >
          <Grid item md={3} sm={4} xs={12}>
            <Box
              sx={{
                // background: "rgb(109 79 160)",
                color: "#fff",
                borderRadius: "12px",
              }}
            >
              <Box
                sx={{
                  // background: "#E4D5F4",
                  // borderRadius: "12px",
                  textAlign: "center",
                  // p: "2.5rem 1.5rem",
                }}
              >
                <a href={data?.windows} ><Typography component={"img"} src={window_icon} width={"20%"} /></a>
                {/* <a href="https://github.com/arsh09/capnotrainer-release/releases/download/v2.2.0/CapnoTrainer-Setup-2.2.0.exe" ><Typography component={"img"} src={window_icon} width={"20%"} /></a> */}
              </Box>
              <Typography
                sx={{
                  // height: "90px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontFamily: "ABLE",
                  fontStyle:"italic"
                }}
                component={"a"}
                href={data?.windows}
              >
                Download for Windows
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Box
              sx={{
                // background: "rgb(109 79 160)",
                // color: "#fff",
                // borderRadius: "12px",
              }}
            >
              <Box
                sx={{
                  // background: "#E4D5F4",
                  // borderRadius: "12px",
                  textAlign: "center",
                  // p: "2.5rem 1.5rem",
                }}
              >
                <a href={data?.mac}><Typography component={"img"} src={ios_icon} width={"20%"} /></a>
                {/* <a href="https://github.com/arsh09/capnotrainer-release/releases/download/v2.2.0/CapnoTrainer-2.2.0.dmg"><Typography component={"img"} src={ios_icon} width={"20%"} /></a> */}
              </Box>
              <Typography
                sx={{
                  // height: "90px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontFamily: "ABLE",
                  fontStyle:"italic"
                }}
                component={"a"}
                href={data?.mac}
              >
                Download for MAC
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography
          fontSize={"20px"}
          fontFamily="ABLE"
          className="title"
          lineHeight={"32px"}
          fontWeight={"700"}
          textalign={"justify"}
          color={"#040404"}
        >
          If you are a <span style={{ color: "rgb(109 79 160)" }}>NEW CUSTOMER</span>,
          please follow the ten steps shown below.
        </Typography>

        <Typography
          fontSize={"20px"}
          fontFamily="ABLE"
          className="title"
          lineHeight={"32px"}
          fontWeight={"700"}
          textalign={"justify"}
          color={"#040404"}
        >
          If you are{" "}
          <span style={{ color: "rgb(109 79 160)" }}>ALREADY A CUSTOMER</span> and wish
          to download the software to another computer, follow only steps 1 - 6
          as described below.
        </Typography>
        <Typography
          fontSize={"20px"}
          fontFamily="ABLE"
          className="title"
          lineHeight={"32px"}
          fontWeight={"700"}
          textalign={"justify"}
          color={"#040404"}
        >
          If you are a <span style={{ color: "rgb(109 79 160)" }}>CLIENT</span>, a{" "}
          <span style={{ color: "rgb(109 79 160)" }}>RENTER</span>, or a{" "}
          <span style={{ color: "rgb(109 79 160)" }}>TRAINER</span> follow only steps 1
          - 6 as described below.
        </Typography>
        <br />
        {goDemoData.map((e, index) => (
          <>
            <Box
              sx={{ display: "flex", gap: "0.5rem", pb: "0.5rem" }}
              key={index}
            >
              <Box
                sx={{
                  fontSize: { sm: "20px", xs: "17px" },
                  pt: { sm: "0.05rem", xs: "0.1rem" },
                }}
              >
                ({index + 1})
              </Box>
              <NewTypography
                text={e.downloads}
                fsm={"20px"}
                fxs={"20px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>
            {e.text6 && (
              <Box
                sx={{
                  display: "grid",
                  gap: "0.5rem",
                  paddingBottom: "1rem",
                  paddingLeft: "2rem",
                  paddingTop: "1rem"
                }}
              >
                <NewTypography
                  text={e.text6}
                  fsm={"20px"}
                  fxs={"17px"}
                  lsm={"10px"}
                  lxs={"0px"}
                  fontWeight={"700"}
                  textalign={"justify"}
                  color={"#040404"}
                />
              </Box>
            )}
            {e.text4 && (
              <Box
                sx={{
                  display: "grid",
                  gap: "0.5rem",
                  paddingBottom: "0.5rem",
                  paddingLeft: "2rem",
                }}
              >
                <NewTypography
                  text={e.text4}
                  fsm={"20px"}
                  fxs={"17px"}
                  lsm={"10px"}
                  lxs={"0px"}
                  textalign={"justify"}
                  color={"#040404"}
                />
              </Box>
            )}
            {e.text1 && (
              <Box
                sx={{
                  display: "grid",
                  gap: "0.5rem",
                  paddingBottom: "0.5rem",
                  paddingLeft: "2rem",
                }}
              >
                <NewTypography
                  text={e.text1}
                  fsm={"20px"}
                  fxs={"17px"}
                  lsm={"15px"}
                  lxs={"15px"}
                  textalign={"justify"}
                  fstyle={"italic"}
                  color={"#040404"}
                />
                <NewTypography
                  text={e.text2}
                  fsm={"20px"}
                  fstyle={"italic"}
                  fxs={"17px"}
                  lsm={"15px"}
                  lxs={"15px"}
                  textalign={"justify"}
                  color={"#040404"}
                />
              </Box>
            )}
          </>
        ))}

        <Box pt={"3rem"}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default GoDownload;
