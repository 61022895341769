import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import Select from "react-select";
import { useFormik } from "formik";
import Paypal from "./Paypal";
import { Country, State } from "country-state-city";
import axios from "axios";
import PlaceOrder from "../BookCart/PlaceOrder";
import { ContractContext } from "../../../Context/ContractContext";
import { getInitialValues, validationSchema } from "./FormConfig";
import { useStyles } from "./BookOrderStyle";

const BookOrder = ({
  count,
  unitPrice,
  selectedCountry,
  setSelectedCountry,
  selectedCountryShipping,
  setSelectedCountryShipping,
  shippingFee,
  setNewAdd,
  newAdd,
}) => {
  const [totalAmount, setTotalAmount] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [shipping, setShipping] = useState(null);
  const { show } = useContext(ContractContext);
  const classes = useStyles();
  const [checkout, setCheckOut] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateShipping, setSelectedStateShipping] = useState(null);

  const initialValues = getInitialValues(
    selectedState,
    selectedCountry,
    newAdd,
    selectedStateShipping,
    selectedCountryShipping
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
    },
  });

  let shipping_address_info = {
    fname: formik.values.fname_s,
    lname: formik.values.lname_s,
    company: formik.values.company_s,
    country: selectedCountryShipping?.name,
    state: selectedStateShipping?.name,
    address_1: formik.values.address_1_s,
    address_2: formik.values.address_2_s,
    city: formik.values.city_s,
    postal: formik.values.postal_s,
    email: formik.values.email_s,
  };

  const billing_address_info = {
    fname: formik.values.fname,
    lname: formik.values.lname,
    company: formik.values.company,
    country: selectedCountry?.name,
    state: selectedState?.name,
    address_1: formik.values.address_1,
    address_2: formik.values.address_2,
    city: formik.values.city,
    postal: formik.values.postal,
    email: formik.values.email,
  };
  const proceedToPayment = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/create-order`,
        {
          quantity: count,
          shipping_address_info:
            newAdd === false ? billing_address_info : shipping_address_info,
          billing_address_info,
          unit_price: unitPrice,
        }
      );
      if (res.status === 200) {
        setTotalAmount(res.data?.total_amount);
        setOrderId(res.data?.order_id);
        setShipping(res.data?.Shipping_fee);
        setCheckOut(true);
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);
  const handleAddress = () => {
    setNewAdd(!newAdd);
  };
  return (
    <Box
      id="placeOrder"
      sx={{ color: "rgb(109 79 160)", mt: "2rem" }}
      data-aos="fade-up"
      data-aos-duration="500"
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} mt={"0.5rem"}>
          <Grid item md={8} sm={12} xs={12}>
            <Box
              sx={{
                borderBottom: "1px solid rgb(109 79 160)",
                pb: "0.5rem",
                fontSize: "18px",
              }}
            >
              Billing Details
            </Box>
            <Box className={classes.input_box}>
              <Grid container spacing={2} mt={"0.5rem"}>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    placeholder="First Name"
                    id="fname"
                    name="fname"
                    className={classes.bin1}
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.fname && formik.errors.fname && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.fname}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    placeholder="Last Name"
                    id="lname"
                    name="lname"
                    className={classes.bin1}
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lname && formik.errors.lname && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.lname}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    className={classes.bin1}
                    placeholder="Company name (optional)"
                    id="company"
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.company && formik.errors.company && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.company}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className="select">
                    <Select
                      id="country"
                      name="country"
                      options={Country.getAllCountries()}
                      getOptionLabel={(options) => {
                        return (
                          <Box>
                            {options.flag} {options.name}
                          </Box>
                        );
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={selectedCountry}
                      onChange={(item) => {
                        setSelectedCountry(item);
                        formik.setFieldValue("country", item.name);
                      }}
                      onBlur={formik.handleBlur("country")}
                      placeholder="Select country..."
                    />
                    {formik.touched.country && formik.errors.country && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.country}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    className={classes.bin1}
                    placeholder="Address Line 1"
                    id="address_1"
                    name="address_1"
                    value={formik.values.address_1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.address_1 && formik.errors.address_1 && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.address_1}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    className={classes.bin1}
                    placeholder="Address Line 2 (optional)"
                    id="address_2"
                    name="address_2"
                    value={formik.values.address_2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.address_2 && formik.errors.address_2 && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.address_2}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    className={classes.bin1}
                    placeholder="City/District/Town"
                    id="city"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.city}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className="select">
                    <Select
                      id="state"
                      name="state"
                      options={State?.getStatesOfCountry(
                        selectedCountry?.isoCode
                      )}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={selectedState}
                      onChange={(item) => {
                        setSelectedState(item);
                        formik.setFieldValue("state", item.name);
                      }}
                      onBlur={formik.handleBlur("state")}
                      placeholder="Select state..."
                    />
                    {formik.touched.state && formik.errors.state && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.state}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    className={classes.bin1}
                    placeholder="Postal Code"
                    id="postal"
                    name="postal"
                    value={formik.values.postal}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.postal && formik.errors.postal && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.postal}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="text"
                    className={classes.bin1}
                    placeholder="Phone"
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.phone}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="email"
                    className={classes.bin1}
                    placeholder="Email Address"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                mt: "24px",
                borderBottom: "1px solid rgb(109 79 160)",
                pb: "0.5rem",
                fontSize: "18px",
              }}
            >
              <Checkbox
                id="checkbox"
                name="checkbox"
                value={newAdd}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleAddress(e);
                }}
                disableRipple
                sx={{
                  color: "rgb(109 79 160)",
                  p: "0",
                  verticalAlign: "text-bottom",
                  "&.Mui-checked": {
                    color: "rgb(109 79 160)",
                  },
                  "& svg": {
                    fontSize: "1.3rem",
                  },
                }}
              />{" "}
              Ship to a different address?
            </Box>
            {newAdd && (
              <Box className={classes.input_box}>
                <Grid container spacing={2} mt={"0.5rem"}>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      placeholder="First Name"
                      id="fname_s"
                      name="fname_s"
                      className={classes.bin1}
                      value={formik.values.fname_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.fname_s && formik.errors.fname_s && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.fname_s}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      placeholder="Last Name"
                      id="lname_s"
                      name="lname_s"
                      className={classes.bin1}
                      value={formik.values.lname_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.lname_s && formik.errors.lname_s && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.lname_s}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      className={classes.bin1}
                      placeholder="Company name (optional)"
                      id="company_s"
                      name="company_s"
                      value={formik.values.company_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.company_s && formik.errors.company_s && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.company_s}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box className="select">
                      <Select
                        id="country_s"
                        name="country_s"
                        options={Country.getAllCountries()}
                        getOptionLabel={(options) => {
                          return (
                            <Box>
                              {options.flag} {options.name}
                            </Box>
                          );
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={selectedCountryShipping}
                        onChange={(item) => {
                          setSelectedCountryShipping(item);
                          formik.setFieldValue("country_s", item.name);
                        }}
                        onBlur={formik.handleBlur("country_s")}
                        placeholder="Select country..."
                      />
                      {formik.touched.country_s && formik.errors.country_s && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.country_s}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      className={classes.bin1}
                      placeholder="Address Line 1"
                      id="address_1_s"
                      name="address_1_s"
                      value={formik.values.address_1_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.address_1_s &&
                      formik.errors.address_1_s && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.address_1_s}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      className={classes.bin1}
                      placeholder="Address Line 2 (optional)"
                      id="address_2_s"
                      name="address_2_s"
                      value={formik.values.address_2_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.address_2_s &&
                      formik.errors.address_2_s && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.address_2_s}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      className={classes.bin1}
                      placeholder="City/District/Town"
                      id="city_s"
                      name="city_s"
                      value={formik.values.city_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.city_s && formik.errors.city_s && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.city_s}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box className="select">
                      <Select
                        id="state_s"
                        name="state_s"
                        options={State?.getStatesOfCountry(
                          selectedCountryShipping?.isoCode
                        )}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={selectedStateShipping}
                        onChange={(item) => {
                          setSelectedStateShipping(item);
                          formik.setFieldValue("state_s", item.name);
                        }}
                        onBlur={formik.handleBlur("state_s")}
                        placeholder="Select state..."
                      />
                      {formik.touched.state_s && formik.errors.state_s && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.state_s}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      className={classes.bin1}
                      placeholder="Postal Code"
                      id="postal_s"
                      name="postal_s"
                      value={formik.values.postal_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.postal_s && formik.errors.postal_s && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.postal_s}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="text"
                      className={classes.bin1}
                      placeholder="Phone"
                      id="phone_s"
                      name="phone_s"
                      value={formik.values.phone_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone_s && formik.errors.phone_s && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.phone_s}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} mb={"1rem"}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      type="email"
                      className={classes.bin1}
                      placeholder="Email Address"
                      id="email_s"
                      name="email_s"
                      value={formik.values.email_s}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email_s && formik.errors.email_s && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.email_s}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            {!show && (
              <PlaceOrder
                shippingFee={shippingFee}
                count={count}
                unitPrice={unitPrice}
              />
            )}
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Box
              sx={{
                borderBottom: "1px solid rgb(109 79 160)",
                pb: "0.5rem",
                fontSize: "18px",
              }}
            >
              Payment Options
            </Box>

            {checkout ? (
              <Paypal
                totalAmount={totalAmount}
                orderId={orderId}
                billing_address_info={billing_address_info}
                shipping_address_info={
                  (newAdd === false
                    ? billing_address_info
                    : shipping_address_info,
                  billing_address_info)
                }
                _price={totalAmount}
                unitPrice={unitPrice}
                count={count}
                newAdd={newAdd}
                shipping={shipping}
              />
            ) : (
              <Box mt={"1rem"}>
                <Button
                  type="button"
                  className={classes.proceed_btn}
                  disabled={!(formik.isValid && formik.dirty)}
                  onClick={proceedToPayment}
                >
                  Proceed to Payment
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default BookOrder;
