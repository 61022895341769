import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../Components/Navbar";
import Copyright from "../../Components/Copyright";

const WebsiteBuildMsg = () => {
  return (
    <Container>
      <Navbar />
      <Box
        sx={{
          //  width:{md:"60%",xs:"auto"},
          margin: "4rem auto 2rem",
          borderRadius: "10px",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          padding: "1.5rem",
          textAlign: "center",
          // height:"400px",
          boxShadow: "rgb(146, 141, 191) 0px 2px 12px",
        }}
      >
        <Box>
          <Box>
            <Typography
              component={"img"}
              src={
                "https://img.freepik.com/free-vector/digital-marketing-team-constructing-landing-home-page_74855-10590.jpg?t=st=1698756921~exp=1698757521~hmac=87b52319a192b35ccc3116607397aeb1dac9cef8aa07ce89cabf8d4cb19b9a3b"
              }
              width={"100%"}
            />
          </Box>
          <Typography sx={{ color: "rgb(109 79 160)", fontSize: "1.5rem", py: "2rem" }}>
            Website Building still in Progress.....
          </Typography>
          <Typography
            sx={{ color: "rgb(109 79 160)", fontSize: "1.25rem" }}
            component={"a"}
            href="https://betterphysiology.com/"
            target="_blank"
          >
            Go To Website
          </Typography>
        </Box>
      </Box>
      <Box pt={"4rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default WebsiteBuildMsg;
