import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const BlogHoverBox = ({content,link,title}) => {
  return (
    <Box
    className="blog_hover"
    sx={{
      background: "rgb(255 255 255 / 76%)",
      backdropFilter: "blur(2px)",
      borderRadius: "5px",
      p: "1rem 0.5rem 0",
      position: "absolute",
      bottom: "0",
      // display:"none"
    }}
  >
    <Typography
      sx={{
        fontSize: "20px",
        color: "rgb(109 79 160)",
        //   py: "0.5rem",
        lineHeight:"1.5rem",
        textAlign: "center",
      }}
    >
     {title}
    </Typography>
    <Typography
      sx={{
        fontSize: "16px",
        color: "#212121",
        py: "0.5rem",
        width: { sm: "90%", xs: "100%" },
        m: "0 auto",
        textAlign: "center",
      }}
    >
      {content?content:"Quality sleep is essential for overall health and well being but..."}
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          color: "rgb(109 79 160)",
        }}
      >
        29/04/2024
      </Typography>
      <Button
      component={Link}
      to={link}
        sx={{
          background: "transparent",
          color: "rgb(109 79 160)",
          fontFamily: "ABLE",
          padding: "6px 10px !important",
          lineHeight: "initial !important",
          fontSize: "16px",
          // width: { sm: "120px", xs: "50%" },
          "&:hover": {
            background: "transparent",
          },
          //   display: "block",
          //   m: "0 auto",
          border: "1px solid rgb(109 79 160)",
          borderRadius: "5px",
          textTransform: "capitalize",
        }}
      >
        Continue reading...
      </Button>
    </Box>
  </Box>
  )
}

export default BlogHoverBox