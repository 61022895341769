import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import manual from "../../Assets/Images/manual.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"

const GoManual = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  const goManualData=[
    {
      id:1,
      title:"GO App Manual",
      link:"https://betterphysiology-media.s3.amazonaws.com/GO+App+Manual+2024.pdf"
    },
    {
      id:2,
      title:"GO Basic Operating Manual",
      link:"https://better-physiology-videos.s3.amazonaws.com/Operating+Manual+GO+Basic-3.0.pdf"
      // link:"https://better-physiology-videos.s3.amazonaws.com/Operating+Manual+GO+Basic+3.0.pdf"
    },
    {
      id:3,
      title:"GO Professional Operating Manual",
      link:"https://better-physiology-videos.s3.amazonaws.com/Operating+Manual+GO+Professional-4.0.pdf"
      // link:"https://better-physiology-videos.s3.amazonaws.com/Operating+Manual+GO+Professional+-+4.0.pdf"
    },

  ]
  return (
    <Container>
    <Navbar/>
      <Box sx={{ color: "#000", mt: "2rem"}}  data-aos="fade-up" data-aos-duration="500">
    <Grid container spacing={2}>
      <Grid item md={6} sm={12} xs={12}>
      <Title title={`GO Manuals`}  />
        <Typography pb={"1.5rem"} sx={{fontStyle:"italic",textAlign:{sm:"start",xs:"center"}, fontSize: "14px" , color: "#000"}}>Click on a manual below to download a PDF copy.</Typography>
      {
        goManualData.map((e,index)=>(
          <Box key={index} pb="1rem">
       <Typography
          component={"a"}
          href={e.link}
          sx={{
            fontSize: { sm: "22px", xs: "17px" },
            fontFamily: "ABLE",
            lineHeight: { sm: "32px", xs: "30px" },
            color:"#000",
            textDecoration: "none",
            fontStyle: "italic"
          }}
          target="_blank"
        >
         {e.title}
        </Typography>
       </Box>
        ))
      }
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
     <Box>
          <Typography component={"img"} src={manual} sx={{width:"100%"}}/>
        </Box>
     </Grid>
    </Grid>
   
      </Box>

      <Box
        sx={{
          height: { lg: "35vh",md:"25vh",sm:"10vh", xs: "0" },
        }}
      />
      <Box pt={"3rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default GoManual;
