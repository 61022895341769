import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import { TrainingData } from "./TrainingData";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"

const Training = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <Container>
    <Navbar/>
      <Box sx={{ color: "#000" }} mt={"2rem"}  data-aos="fade-up" data-aos-duration="500">
        <Title title={`Training`} paddingb={'1.5rem'} />
        <Box pb={"1.2rem"} pt={"0.5rem"}>
          <Typography
            sx={{
              fontSize: { sm: "22px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "30px" }, 
              textAlign: { sm: "start", xs: "justify" },
            }}
          >
            The{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: { sm: "25px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "33px", xs: "30px" },
              }}
            >
              Professional School Of Behavioral Health Sciences
            </Typography>{" "}
            offers a{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: { sm: "25px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "33px", xs: "30px" },
              }}
            >
              Professional Certificate
            </Typography>{" "}
            in the Science of Breathing Behavior Analysis,{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: { sm: "25px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "33px", xs: "30px" },
              }}
            >
              Certification
            </Typography>{" "}
            as Certified Breathing Behavior Analyst, and a{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: { sm: "25px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "33px", xs: "30px" },
              }}
            >
              Professional Diploma
            </Typography>{" "}
            in Behavioral Breathing Science to qualified applicants.
          </Typography>
        </Box>
        <Box pb={"1.2rem"} pt={"0.1rem"}>
          <Typography
            sx={{
              fontSize: { sm: "22px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "30px" }, 
              textAlign: { sm: "start", xs: "justify" },
            }}
          >
            If you are interested in formal training, visit their website at{" "}
            <Typography
              component={"a"}
              href={"https://www.bp.edu/"}
              sx={{
                fontSize: { sm: "22px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "30px" }, 
                color: "rgb(109 79 160)",
                fontWeight:"600"
              }}
              target="_blank"
            >
              www.bp.edu
            </Typography>{" "}
            or write to them for a Zoom appointment at{" "}
            <Typography
              component={"a"}
              href="mailto:education@bp.edu"
              sx={{
                fontSize: { sm: "22px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "30px" }, 
                color: "rgb(109 79 160)",
                fontWeight:600
              }}
            >
              education@bp.edu.
            </Typography>{" "}
            Go to{" "}
            <Typography
              component={"a"}
              href={"https://e-campus-registration.bp.edu/"}
              sx={{
                fontSize: { sm: "22px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "30px" }, 
                color: "rgb(109 79 160)",
                fontWeight:600
              }}
              target="_blank"
            >
              www.e-campus.bp.edu
            </Typography>{" "}
            for a brochure, scheduling, and registration.
          </Typography>
        </Box>
        {TrainingData.map((data, index) => (
          <Box key={index} pb={"1.5rem"}>
            <NewTypography
              text={data.title}
              fsm={"26px"}
              fxs={"22px"}
              lsm={"40px"}
              lxs={"30px"}
              fontWeight={600}
            />
           <Box pt={"0.3rem"}>
           <NewTypography
              text={data.subTitle}
              fsm={"22px"}
              fxs={"18px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
           </Box>
          </Box>
        ))}
      </Box>
      <Box pt={"3rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Training;
