import React, { useEffect, useState } from "react";
import cart_bg from "../../Assets/Images/cart_bg.png";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { API_URL, IMAGE_URL } from "../../redux/Constant";
import { getCart } from "../../store/cartSlice";
import { useNavigate } from "react-router-dom";
// import capno_trainer_img from "../../Assets/Images/capno-trainer_img.png";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import ReminderModal from "../Cart/ReminderModal";
import ReminderPopup from "./ReminderPopup";

const useStyles = makeStyles({
  box_main: {
    // backgroundImage: `url(${cart_bg})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "100% 100%",
    // backgroundSize: "cover",
    background: "#fff",
    border: "1px solid #dad5d5",

    padding: "2rem 1rem",
    borderRadius: "6px",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  btn: {
    background: "rgb(109 79 160) !important",
    color: "#fff !important",
    fontFamily: "ABLE !important",
    padding: "0.7rem !important",
    fontSize: "16px !important",
    lineHeight: "initial !important",
    // width: "150px",
    "&:hover": {
      background: "rgb(109 79 160)",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto !important",
      color: "rgb(255 255 255 / 38%) !important",
      "&:hover": {
        opacity: "1",
      },
    },
  },
  front: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
  },
  back: {
    display: "block",
    cursor: "pointer",
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    transform: "rotateY(180deg)",
  },
  flip_card_inner: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.8s",
    transformStyle: "preserve-3d",
  },
  flip_card_outer: {
    backgroundColor: "transparent",
    height: "230px",
    perspective: "1000px",
    "&:hover $flip_card_inner": {
      transform: "rotateY(180deg)",
    },
  },
});
const GoInstruments = ({
  data,
  setShowSnackbar,
  setAddSuccessMsg,
  setAddErrorMsg,
}) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [productId, setProductId] = useState(null);
  const [productQuantity, setProductQuantity] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const addToCartFunc = async (productId, quantity) => {
    setProductId(productId)
    setProductQuantity(quantity)
  if(productId==12||productId==14){
 setReminder(true)
  }else{
    addToCart(productId, quantity)
  }

  };

  const addToCart = async (productId, quantity) => {
   
    setLoading(true);
    try {
      const res = await axios.post(`${API_URL}/add-to-cart`, {
        user_id: localStorage.getItem("user")
          ? localStorage.getItem("user")
          : "",
        product_ids: [productId],
        quantities: [productQuantity||quantity],
      });
      if (res.status === 200) {
        console.log(res);
        setLoading(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddSuccessMsg("");
        }, 2000);
        setAddSuccessMsg(res.data.message);
        const userId = res.data.user_id ;
        localStorage.setItem("user", res.data.user_id);
        dispatch(getCart(userId));
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoading(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddErrorMsg("");
        }, 2000);
        setAddErrorMsg("Out of stock");
      }
    }
  }

  

// useEffect(()=>{
// if(productId===12||productId===14){
// setReminder(true)
// }
// },[productId])

  return (
    <Box>
      <Box
        sx={{
          lineHeight: "0",
          display: { sm: "flex", xs: "block" },
          justifyContent: "space-between",
          borderBottom: "1px solid #dad5d5",
        //   borderTop: "1px solid #dad5d5",
          mb: "0.5rem",
          pb: "0.5rem",
        //   pt: "0.5rem",
          alignItems: "center",
          flexDirection: { sm: "row", xs: "column" },
          gap: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={7} sm={5} xs={12} sx={{
            display:"flex",
            alignItems:"center",
            justifyContent:{sm:"start",xs:"center"}
          }}>
            <Box className={classes.flip_card_outerr}>
              <Typography
                sx={{
                  fontSize: { sm: "22px", xs: "18px" },
                  fontFamily: "ABLE",
                  color: "rgb(109 79 160)",
                //   lineHeight: "15px",
                  textAlign: { sm: "start", xs: "center" },
                //   pb: { sm: "0", xs: "0.8rem" },
                  textDecoration: "none!important",
                  fontStyle: "italic",
                  fontWeight: "600"
                }}
                component={'a'}
                href={`/go-Detail/${data?.id}`}
              >
                {data?.name} {data?.subtitle && (<span style={{fontSize:"18px",fontStyle: "normal", color:"#000"}}>({data?.subtitle})</span>)}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Box
              sx={{
                display: "flex",
                // gap: "3rem",
                alignItems: {sm:"center"},
                justifyContent: { sm: "space-around", xs: "space-between" },
                // pb: { sm: "0", xs: "0.8rem" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "20px", xs: "18px" },
                  fontFamily: "ABLE",
                }}
              >
                $ {data.price}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    border: "1px solid rgb(109 79 160)",
                    fontSize: "20px",
                    fontFamily: "ABLE",
                    borderRadius: "6px 0 0 6px",
                    width: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {count}
                </Box>
                <Box
                  sx={{
                    background: "rgb(109 79 160)",
                    borderRadius: "0px 6px 6px 0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ExpandLess
                    onClick={increment}
                    sx={{
                      cursor: "pointer",
                      width: "1.3rem",
                      height: "1.3rem",
                      color: "#fff",
                    }}
                  />
                  <ExpandMore
                    onClick={decrement}
                    sx={{
                      cursor: "pointer",
                      width: "1.3rem",
                      height: "1.3rem",
                      color: "#fff",

                      "&[disabled]": {
                        pointerEvents: "not-allowed",
                        opacity: 0.6, // Adjust the opacity as needed
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} sm={3} xs={12}>
            <Box
              sx={{
                // pb: { sm: "0", xs: "0.8rem" },
                textAlign: "end",
              }}
            >
              <Button
                className={classes.btn}
                sx={{
                  width: { md: "120px", xs: "100%" },
                }}
                disabled={count === 0}
                onClick={() => addToCartFunc(data?.id, count)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      width: "21px !important",
                      height: "21px !important",
                      color: "#e4dbe7",
                    }}
                  />
                ) : (
                  "Add to Cart"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ReminderPopup reminder={reminder} setReminder={setReminder} product_Id={productId} addToCart={addToCart} productQuantity={count}/>
    </Box>
  );
};

export default GoInstruments;
