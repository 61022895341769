import { Badge, Box, Button, Collapse, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import cart from "../Assets/Images/cart.png";
// import user from "../Assets/Images/user.png";
import Subscribe from "../Pages/Subscribe";
import logo from "../Assets/Images/logo.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  btn: {
    background: "rgb(109 79 160) !important",
    color: "#fff !important",
    fontFamily: "ABLE",
    fontSize: "0.85rem !important",
    lineHeight: "initial !important",
    padding: "10px 8px !important",
    "&:hover": {
      background: "rgb(109 79 160)",
    },
    border: "2px solid #C5A7CF !important",
    borderRadius: "6px !important",
  },
  submenuList: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    alignItems: "center",
    // background: "rgb(109 79 160)",
    // color: "rgb(109 79 160)",
    color: "#000",
    padding: "0.4rem",
    borderRadius: "4px",
    fontFamily: "ABLE !important",
    "&:hover": {
      color: "rgb(109 79 160)",
    },
  },
});
const MobileNav = ({ handleDrawerToggle,cartValue }) => {
  const classes = useStyles();
  const [subscribeModal, setSubscribeModal] = React.useState(false);

  const [submenus, setSubmenus] = React.useState({
    submenu: false,
    submenu1: false,
    submenu2: false,
    submenu3: false,
    submenu4: false,
  });

  const handleClickMenu = (menu) => {
    setSubmenus((prevSubmenus) => ({
      ...prevSubmenus,
      [menu]: !prevSubmenus[menu],
    }));
  };
  const { submenu, submenu1, submenu2, submenu3, submenu4 } = submenus;
  return (
    <>
      <Typography component={"img"} src={logo} mt={"1.5rem"} />
      <IconButton
        aria-label="close"
        onClick={handleDrawerToggle}
        title="Close"
        sx={{
          position: "absolute",
          right: "5px",
          color: "rgb(109 79 160)",
          top: "5px",
          background: "#fff",
          "&:hover": {
            background: "#fff",
          },
          // color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fontSize: "1.1rem" }} />
      </IconButton>
      <Box
        // onClick={handleDrawerToggle}
        sx={{
          // textAlign: "center",
          display: "grid",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.1rem",
          mt: "2rem",
          "& a": {
            // color: "rgb(109 79 160)",
            color: "#000",
            textDecoration: "none",
          },
          "& a:hover": {
                      color: "rgb(109 79 160)",
                    },
        }}
      >
        <Typography className={classes.submenuList}>
          <Link to={"/"}>HOME</Link>
        </Typography>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu")}
            className={classes.submenuList}
          >
            Company{" "}
            {submenu ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu}>
            <Box
              sx={{
                background: "#fcfcfc6b",
                p: "0.5rem",
                borderRadius: "4px",
                "& a": {
                  color: "#000 !important",
                  fontFamily: "ABLE",
                },
              }}
            >
              <Typography>
                <Link to={"/contact"}>Contact</Link>
              </Typography>
              <Typography>
                <Link to={"/distributors"}>Distributors</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/history"}>History</Link>
              </Typography>
              <Typography>
                <Link to={"/mission"}>Mission</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu1")}
            className={classes.submenuList}
          >
            Products{" "}
            {submenu1 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu1}>
            <Box
              sx={{
                background: "#fcfcfc6b",
                p: "0.5rem",
                borderRadius: "4px",
                "& a": {
                  color: "#000 !important",
                  fontFamily: "ABLE",
                },
              }}
            >
              <Typography>
                {" "}
                <Link to={"/go-brochure"}>GO Brochure</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/shopping-cart"}>GO Buy</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/request-demonstration"}>GO Demo</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/go-overview"}>GO Overview</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu2")}
            className={classes.submenuList}
          >
            Educations{" "}
            {submenu2 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu2}>
            <Box
              sx={{
                borderRadius: "4px",
                background: "#fcfcfc6b",
                p: "0.5rem",
                "& a": {
                  color: "#000 !important",
                  fontFamily: "ABLE",
                },
              }}
            >
              <Typography>
                <Link to={"/book"}>Book</Link>
              </Typography>
              <Typography>
                <Link to={"/course"}>Course</Link>
              </Typography>
              <Typography>
                <Link to={"/interview"}>Interviews</Link>
              </Typography>
              <Typography>
                <Link to={"/lectures"}>Lectures</Link>
              </Typography>
              <Typography>
                <Link to={"/references"}>References</Link>
              </Typography>
              <Typography>
              <Link to={"/professional-education"}>
          Professional School
          </Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu3")}
            className={classes.submenuList}
          >
            Services{" "}
            {submenu3 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu3}>
            <Box
              sx={{
                borderRadius: "4px",
                background: "#fcfcfc6b",
                p: "0.5rem",
                "& a": {
                  color: "#000 !important",
                  fontFamily: "ABLE",
                },
              }}
            >
              <Typography>
                <Link to={"/consultation"}>Consultation</Link>
              </Typography>
              <Typography>
                <Link to={"/go-support"}>GO Support</Link>
              </Typography>
              <Typography>
                <Link to={"/go-video"}>GO Videos</Link>
              </Typography>
              <Typography>
                <Link to={"/training"}>Training</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu4")}
            className={classes.submenuList}
          >
            Software{" "}
            {submenu4 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu4}>
            <Box
              sx={{
                background: "#fcfcfc6b",
                p: "0.5rem",
                borderRadius: "4px",
                "& a": {
                  color: "#000 !important",
                  fontFamily: "ABLE",
                },
              }}
            >
              <Typography>
                <Link to={"/go-download"}>GO Downloads</Link>
              </Typography>
              <Typography>
                <Link to={"/go-manual"}>GO Manuals</Link>
              </Typography>
              <Typography>
                <Link to={"/go-quickstart"}>GO Quickstart</Link>
              </Typography>
              <Typography>
                <Link to={"/download-5.0"}>5.0 Instruments</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>

        <Typography
          className={classes.submenuList}
          // onClick={() => setSubscribeModal(true)}
        >
         <Link to={"/subscribe"}>Subscribe</Link> 
        </Typography>
        <Typography className={classes.submenuList}>
          <Link to={"/faq"}>FAQs</Link>
        </Typography>
        <Box
          mt={"0.5rem"}
          sx={{
            "& .MuiBadge-root": {
              display: "block !important",
            },
          }}
        >
          <Badge
           badgeContent={cartValue}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#E4D5F4",
                color: "#000000",
                fontSize: "15px",
                fontFamily: "ABLE",
                height: "23px",
                width: "23px",
                right: "10px",
                border: "1px solid rgb(109 79 160)",
                borderRadius: "100%",
              },
            }}
          >
            <Button
              className={classes.btn}
              component={Link}
              sx={{ width: "100%", p: "0.6rem" }}
              to="/cart"
            >
              Cart{" "}
              <Typography
                ml={"0.5rem"}
                component={"img"}
                width={"16px"}
                src={cart}
              />
            </Button>
          </Badge>
        </Box>
        <Box mt={"0.5rem"}>
          <Button
            className={classes.btn}
            sx={{ width: "100%", p: "0.6rem" }}
            component={Link}
            to="/shopping-cart"
          >
            Buy Now
          </Button>
        </Box>
       
        {/* ***subscribe modal**** */}
        <Subscribe
          subscribeModal={subscribeModal}
          setSubscribeModal={setSubscribeModal}
        />
      </Box>
    </>
  );
};

export default MobileNav;
