import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../redux/Constant";
import { useDispatch } from "react-redux";
import { getCart } from "../../store/cartSlice";
import ReminderModal from "./ReminderModal";
// import cart_bg from "../../Assets/Images/cart_bg.png";

const CartItem = ({ item, setMaxQnty, setShowSnackbar, setAddSuccessMsg }) => {
  const [productData, setProductData] = useState([]);
  const [count, setCount] = useState(0 || item?.quantity);
  const [isLoading, setIsLoading] = useState(false);


  const dispatch = useDispatch();
  let quantities =
    count > item?.quantity ? count - item?.quantity : -item?.quantity + count;
  const updateQuantity = async () => {
    try {
      const res = await axios.put(
        `${API_URL}/update/cart/by/${localStorage.getItem("user")}`,
        { cart_ids: [item?.id], quantities: [quantities] }
      );
      if (res.status === 200) {
        setShowSnackbar(true);
        setAddSuccessMsg(res.data.message);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
      }
      dispatch(getCart(localStorage.getItem("user")));
    } catch (err) {
      console.log("Error", err);
    }
  };

  const increment = () => {
    if (count < productData?.availability) {
      setCount(count + 1);
    } else {
      setMaxQnty(true);
      setTimeout(() => {
        setMaxQnty(false);
      }, 2000);
    }
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const getproductData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/get/product/by/${item?.product_id}`
      );
      if (res.status === 200) {
        setProductData(res.data.data?.[0]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const removeCart = async () => {
    setIsLoading(true);
    try {
      const res = await axios.delete(`${API_URL}/delete/cart/by/${item?.id}`);
      if (res.status === 200) {
        setIsLoading(false);
        setShowSnackbar(true);
        setAddSuccessMsg(res.data.message);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
      }
      dispatch(getCart(localStorage.getItem("user")));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (quantities !== 0) {
      updateQuantity();
    }
    // eslint-disable-next-line
  }, [quantities]);
  useEffect(() => {
    getproductData(); 
    // eslint-disable-next-line
  }, [item?.id]);

  function htmlToText(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}
  return (
    <>
      <Grid container spacing={3} mt={"0.5rem"}>
        <Grid item md={4} sm={12} xs={12}>
          <Box
            sx={{
              // backgroundImage: `url(${cart_bg})`,
              border: "1px solid #dad5d5",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "100% 100%",
              backgroundSize: "cover",
            //   background: "#d0a3ff6b",
              p: "0.4rem",
              borderRadius: "6px",
              textAlign: "center",
            }}
          >
            <Typography
              component={"img"}
              src={`${IMAGE_URL}/${productData?.product_images?.[0]}`}
              sx={{
                width: {sm: "100%", xs: "100%" },
                height: { md: "175px", sm: "100%", xs: "100%" },
              }}
            />
          </Box>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: {
                sm: "20px",
                xs: "18px",
                lineHeight: "30px",
                fontFamily: "ABLE",
                fontWeight: "600",
              },
            }}
          > 
            {htmlToText(productData?.name)}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                sm: "16px",
                xs: "14px",
                lineHeight: "30px",
                fontFamily: "ABLE",
                fontWeight: "400",
              },
            }}
          > 
            {productData?.subtitle ? htmlToText(productData?.subtitle) : ""}
          </Typography>
        </Grid>
        <Grid item md={4} sm={12} xs={12} textAlign={"center"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                lineHeight: "30px",
                fontFamily: "ABLE",
                fontWeight: "600",
              }}
            >
              Total Price
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                lineHeight: "30px",
                fontFamily: "ABLE",
                fontWeight: "600",
              }}
            >
              $ {item?.total_price}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                lineHeight: "30px",
                fontFamily: "ABLE",
                fontWeight: "600",
              }}
            >
              Unit Price
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                lineHeight: "30px",
                fontFamily: "ABLE",
                fontWeight: "600",
              }}
            >
              $ {item?.unit_price}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: "0.3rem",
              //   p: "0.3rem",
              display: "flex",
              justifyContent: "space-between",
              //   alignItems: "center",
              //   border: "1px solid rgb(109 79 160)",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                fontFamily: "ABLE",
                fontWeight: "600",
              }}
            >
              Quantity
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  border: "1px solid rgb(109 79 160)",
                  fontSize: "20px",
                  fontFamily: "ABLE",
                  borderRadius: "6px 0 0 6px",
                  width: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {count}
              </Box>
              <Box
                sx={{
                  background: "rgb(109 79 160)",
                  borderRadius: "0px 6px 6px 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ExpandLess
                  onClick={increment}
                  sx={{
                    cursor: "pointer",
                    width: "1.3rem",
                    height: "1.3rem",
                    color: "#fff",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                />
                <ExpandMore
                  onClick={decrement}
                  sx={{
                    cursor: "pointer",
                    width: "1.3rem",
                    height: "1.3rem",
                    color: "#fff",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box pt="1rem">
            <Button
              sx={{
                background: "rgb(109 79 160) !important",
                color: "#fff !important",
                fontFamily: "ABLE",
                p: "0.7rem",
                fontSize: "18px !important",
                lineHeight: "initial !important",
                border: "2px solid #C5A7CF !important",
                width: "100%",
                "&:hover": {
                  background: "rgb(109 79 160)",
                },
              }}
              onClick={removeCart}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    width: "23px !important",
                    height: "23px !important",
                    color: "#e4dbe7",
                  }}
                />
              ) : (
                "REMOVE"
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* <ReminderModal setReminder={setReminder} reminder={reminder} productData={productData}/> */}
    </>
  );
};

export default CartItem;
