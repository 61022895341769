import React, { useEffect } from 'react'
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from '../../Components/Copyright';
import HistoryTypography from '../History/HistoryTypography';
import NewTypography from '../../Components/NewTypography';
import demo from "../../Assets/Images/demo.png"
import graph from "../../Assets/Images/graph.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"
import SupportNewTypography from '../Support/SupportNewTypography';

const GoDemo = () => {
  useEffect(()=>{
    AOS.init();
  },[])
    const goDemoData=[
        {
            id:1,
            instruments:"enhanced efficacy of your work;",
            serialNo:"01"
        },
        {
            id:2,
            instruments:"exponential growth of your marketplace; and",
            serialNo:"02"
        },
        {
            id:3,
            instruments:"significant increase in your potential income.",
            serialNo:"03"
        },
    ]

  return (
    <Container>
    <Navbar/>
    <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
      <Title title={`Request Demonstration`} paddingb={'1.5rem'} />
      <Typography
          sx={{
            fontSize: { sm: "1.375rem", xs: "1.2rem" },
            fontFamily: "ABLE",
            lineHeight: { sm: "2.1rem"},
            textAlign:{ sm: "start", xs: "justify" },
            pb: "2rem",
            pt:{sm:"0.3rem"}
          }}
        >
         We arrange live demonstrations by appointment on our Zoom Webinar platform. You can operate the instrument located in our offices from you own location. In this way you can see for yourself how assessment and learning sessions can be accomplished over the Internet with clients and trainees.
        </Typography>
 <Box pt={"2rem"} borderTop={"1px solid rgb(109 79 160)"}>
 <HistoryTypography
              text1={"Renting instruments"}
              text2={"to clients for learning in the field and offering breathing services over the Internet means:"}
              fsm={"24px"}
              fxs={"21px"}
              lsm={"45px"}
              lxs={"30px"}
              fsm1={"22px"}
              fxs1={"18px"}
              lsm1={"32px"}
              lxs1={"30px"}
            />
         <Grid container spacing={2} pt={"1rem"}>
            <Grid item md={9} sm={12} xs={12}>
            {
                goDemoData.map((e,index)=>(
                    <Box sx={{display:"flex",gap:"0.5rem",alignItems:"center",pb:"1rem"}} key={index}>
         <Box sx={{width:"40px",height:"40px",borderRadius:"100%",background:"#E4D5F4",display:"flex",justifyContent:"center",alignItems:"center",fontSize:{sm:"24px",xs:"20px"}}}>
           {e.serialNo}
         </Box>
         <NewTypography
            text={e.instruments}
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
         </Box>
                ))
            }
           {/* <Box> <Typography component={"img"} src={graph}  sx={{width:{md:"70%", xs:"100%"}}}/></Box> */}
        <Box pt={"1rem"}>
        {/* <Typography
          component={"a"}
          href="mailto:instruments@betterphysiology.com"
          sx={{
            fontSize: { sm: "24px", xs: "19px" },
            fontFamily: "ABLE",
            lineHeight: { sm: "32px", xs: "30px" },
            color:"rgb(109 79 160)",
            textAlign:{md:"start",xs:"center"}
          }}
        >
         Send an email to pl@betterphysiogy.com to make an appointment for a live demonstration.
        </Typography> */}
        <SupportNewTypography
            text={
              "Send an email to"
            }
            email={"pl@betterphysiology.com"}
            text2={
              " to make an appointment for a live demonstration."
            }
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
            <Box sx={{my:"2rem",textAlign:"center"}}>
          {" "}
          {/* <Typography component={"img"} src={demo} sx={{width:{sm:"inherit",xs:"60%"}}}/> */}
        </Box>
           
            </Grid>
         </Grid>
         <Box
          sx={{
            height: { sm: "20vh" },
          }}
        />
         <Box pt={"3rem"}>
      <Copyright />
    </Box>
 </Box>
    </Box>
   
  </Container>
  )
}

export default GoDemo