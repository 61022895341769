import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
// import { Link } from "react-router-dom";
import book from "../../Assets/Images/book.png";
import CourseTypograpgy from "./CourseTypograpgy";
import CourseSupport from "./CourseSupport";
import CourseLabel from "./CourseLabel";

const Course = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    AOS.init();
  }, []);
  const courseList=[
    {
      text:"learning basic respiratory psychophysiology"
    },
    {
      text:"using CapnoTrainer technology"
    },
    {
      text:"applying methods for identifying breathing habits"
    },
    {
      text:"learning techniques for disengaging troublesome breathing habits"
    },
    {
      text:"learning techniques for acquiring new breathing habits"
    },
    {
      text:"using self-interventions for managing symptoms triggered by habits"
    },
    {
      text:"aligning breathing mechanics with respiratory chemistry"
    },
    {
      text:"restoring healthy CO2 concentrations for improving performance"
    },
  ]
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "rgb(109 79 160)", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title
          title={
            <>
              <span style={{ fontSize: "2.2rem", }}> An audio and video recorded course</span>
              <br />{" "}
              <span style={{ fontSize: "2.7rem",color:"#000" }}>CapnoLearning™</span>
            </>
          }
          
          // position={"absolute"}
 
        />
        <Grid container spacing={2}>
          <Grid item md={6.1} sm={12} xs={12}>
            <Typography
              sx={{
                pt: "0.2rem",
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "28px", xs: "28px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
              }}
            >
              Learn about the principles of Breathing Behavior Analysis and their practical implementation for optimizing respiration and acid-base physiology, including:
            </Typography>
            <Box my={"1rem"}>
         {  
          courseList.map((e,index)=>(
            <Typography
            key={index}
            component={"li"}
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "28px", xs: "28px" },
                textAlign: { sm: "start", xs: "justify" },
                color:"#000",
              }}
            >
              {e.text}
            </Typography>
          )
          )
         }
            </Box>
        <Box pt={"0.5rem"}>    <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#000000",
                fontWeight: "600",
              }}
            >
              Peter M. Litchfield, Ph.D.
            </Typography>
       
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "20px", xs: "20px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#000000",
                fontWeight: "600",
              }}
            >
              Sandra Reamer, MFA, MS, CBBA, CBBP, CSOM, CBT
            </Typography></Box>
       
        
          </Grid>
          <Grid item md={5.9} sm={12} xs={12} textAlign={"center"}>
         <Box>
         <Box pb={"2rem"}>
     
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "30px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#000",
                fontWeight: "500",
              }}
            >
             <span style={{fontWeight:600,color: 
            "rgb(109 79 160)"}}>CE OPTION:</span> 10 hours of professional continuing education credit from the Professional School of Behavioral Health Sciences; $50.00
            </Typography>
         </Box>
         <Box>
              <Typography
                sx={{
                  fontSize: { sm: "22px", xs: "20px" },
                  fontFamily: "ABLE",
                  lineHeight: { sm: "30px", xs: "30px" },
                  textAlign: { sm: "start", xs: "justify" },
                  color: "#040404",
                }}
              >
                Click here to{" "}
                <a
                  href="#learn-more"
                  onClick={() => setShow(!show)}
                  style={{
                    textDecoration: "underline",
                    color: "rgb(109 79 160)",
                    cursor: "pointer",
                    fontWeight:600
                  }}
                >
                  learn more
                </a>{" "}
                about the COURSE.
              </Typography>
              <Typography
                sx={{
                  fontSize: { sm: "22px", xs: "20px" },
                  fontFamily: "ABLE",
                  lineHeight: { sm: "30px", xs: "30px" },
                  textAlign: { sm: "start", xs: "justify" },
                  color: "#040404",
                }}
              >
               The eBook is included in the price of the course.
              </Typography>
            </Box>
            <Box pb={"2rem"}>
              <Typography
                sx={{
                  fontSize: { sm: "22px", xs: "20px" },
                  fontFamily: "ABLE",
                  lineHeight: { sm: "30px", xs: "30px" },
                  textAlign: { sm: "start", xs: "justify" },
                  color: "#040404",
                }}
              >
                Buy the COURSE and support the <em>Breathing Science Journal</em>
                <br />
                by making{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    color: "rgb(109 79 160)",
                    fontWeight: "600",
                  }}
                  href="https://www.thebsj.org/offers/DpW8m8MA/checkout"
                  target="_blank"
                >
                  a tax-deductible purchase
                </a>{" "}
                from the publisher ($199.00)
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "30px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "rgb(109 79 160)",
                fontWeight: "600",
              }}
            >
              Publisher
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "30px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
                fontWeight: "600",
              }}
            >
              Breathing Science, Inc.
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "22px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "30px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
                fontWeight: "600",
              }}
            >
             A 501(c)(3) nonprofit corporation
            </Typography>
      
         </Box>
          </Grid>
        </Grid>
        {show && (
          <Box id="learn-more">
            <Typography
              sx={{
                pt: "2rem",
                fontSize: { sm: "26px", xs: "20px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "40px", xs: "30px" },
                textAlign: { sm: "start", xs: "justify" },
                fontWeight:600
              }}
            >
              Breathing habits, optimal respiration, and acid-base physiology
            </Typography>

            {/* CourseTypograpgy */}

            <Box pt={"1.5rem"}>
              <CourseTypograpgy
                text={`Breathing Science, Inc., a 501(c)(3) nonprofit USA corporation and publisher of the Breathing Science Journal`}
                link={"www.theBSJ.org"}
                text2={`, is pleased to offer you an eye-opening`}
                text3={` 10-hour on-line course `}
                text4={`based on the book, `}
                text5={`CapnoLearning:
An Introductory Guide `}
fontStl={'italic'}
                text6={`authored by Peter M Litchfield and Sandra Reamer. Learn all about breathing habits, optimal
                respiration, and acid-base physiology from a practical perspective, personal and professional.`}
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>

            {/* =====================course */}
            {/* <Box pt={"1.5rem"}>
              <NewTypography
                text={`Breathing Science, Inc., a 501(c)(3) nonprofit USA corporation and publisher of the Breathing Science Journal
(www.theBSJ.org), is pleased to offer you an eye-opening 10-hour on-line course based on the book, CapnoLearning:
An Introductory Guide authored by Peter M Litchfield and Sandra Reamer. Learn all about breathing habits, optimal
respiration, and acid-base physiology from a practical perspective, personal and professional.`}
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box> */}

            <Box pt={"1.5rem"}>
              <CourseTypograpgy
                text={`The authors read their book to you, 51 very short chapters, chapter by chapter, and then speak to you about specific
                practical applications, e.g., pregnancy and performance, based on the book in a series of ten short videos. The course
                includes description of 14 practicums which are templates for designing solutions to your own and/or your client’s
                specific breathing challenges. Listening and viewing times will be tracked so that when you have completed (that is,
                listened to) each chapter as well as having watched the ten videos, you will immediately receive a `}
                text3={`Certificate of Attendance `}
                text4={`from us (Breathing Science, Inc.).`}
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>

            <Box pt={"1.5rem"}>
              <CourseTypograpgy
                text={`You will also receive by email an option to earn 10 hours of `}
                text3={`Professional Continuing Education (CE) Credit `}
                // text3={``}
                text4={`and receive a `}
                text5={`Certificate of Completion `}
                text6={`and an  `}
                text7={`Academic Transcript `}
                text8={`documenting your credit from the `}
                text9={`Professional School of
Behavioral Health Sciences, `}
                text10={`a USA state licensed post-secondary professional school. Earning this credit requires
completion of a multiple-choice examination administered by the Professional School. If you pass the examination, you
will be requested to complete an on-line registration form and to make payment of a $50.00 tuition fee. Upon
submission of the registration, you will immediately receive both the `}
                text11={`Certificate of Completion `}
                text12={`and an `}
                text13={`official transcript`}
                text14={`from the Professional School. If you fail the examination you may continue to take it until you pass.`}
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>

            <Box pt={"1.5rem"}>
            <CourseSupport
            text={
              `CapnoLearning is best accomplished by using CapnoTrainer GO technology where the book practicums are built into the
software (and for which there is a patent pending), but can also be accomplished with generic capnometers or to some
extent even without accompanying technology. The practicum chapters in the book and the practical applications
described in the videos refer specifically to CapnoTrainer GO technology manufactured by Better Physiology Ltd. Visit`
            }
            link={`www.betterphysiology.com`}
            text2={`for Information about this technology and its purchase, or email your questions to`}
            email={"instruments@betterphysiology.com"}
            text3={`Visit`}
            link2={`www.CapnoLearning.org`}
            text4={`for rental of this same technology and/or for
appointments with Breathing Behavior Analysts who can provide you with CapnoLearning professional consultation.`}
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
            </Box>

            <Box pt={"1.5rem"}>
              <NewTypography
                text={`You may be wondering, “can I benefit from the course without using technology?” The answer, of course, is a
resounding YES. The ultimate objective of breathing behavior analysis work is NOT about learning to use technology for
manipulating breathing with physical techniques, i.e., an outside-in paradigm, but rather it is about learning new
breathing habits that automatically optimize respiration, that is, aligning breathing with respiration based on the
principles of self-regulation, an inside-out paradigm. The CapnoTrainer GO technology is a powerful tool for making this
transition from an outside-in to an inside-out paradigm, that is, for identifying existing habits and learning new ones.`}
                fsm={"22px"}
                fxs={"19px"}
                lsm={"32px"}
                lxs={"30px"}
                textalign={"justify"}
                color={"#040404"}
              />
            </Box>

          </Box>
        )}




        <Box sx={{
          pt:show?"5rem":"10rem"
        }}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default Course;
