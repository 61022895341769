import { Typography } from "@mui/material";
import React from "react";

const EmailTypography = ({ text, fsm, fxs, lsm, lxs, email,textalign}) => {
  return (
    <Typography
      sx={{
        fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        textAlign: { sm: "start", xs: textalign },
      }}
    >
      {text}{" "}
      {email && (
        <Typography
          component={"a"}
          href={`mailto:${email}`}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            color:"rgb(109 79 160)",
            fontWeight:600
            // textAlign: { sm: "start", xs: "justify" },
          }}
        >
          {email}
        </Typography>
      )}
    </Typography>
  );
};

export default EmailTypography;
