import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ContractContext } from "../../../Context/ContractContext";

const PlaceOrder = ({ shippingFee, count, unitPrice }) => {
  const { setShow, show } = useContext(ContractContext);

  window.addEventListener("scroll", function () {
    var scrollPosition = window.scrollY;
    if (scrollPosition >= 100) {
      setShow(false);
    } else {
      setShow(true);
    }
  });
  const [totalPrice, setTotalPrice] = useState(count * unitPrice + shippingFee);
  useEffect(() => {
    setTotalPrice(count * unitPrice + shippingFee);
  }, [count, shippingFee, unitPrice]);
  return (
    <Box
      sx={{
        border: "1px solid rgb(109 79 160)",
        p: show ? "1rem 1rem 3.1rem" : "1rem",
      }}
    >
      <Typography
        sx={{
          fontSize: { sm: "18px", xs: "18px" },
          fontFamily: "ABLE",
          lineHeight: "35px",
          fontWeight: "600",
          borderBottom: "1px solid rgb(109 79 160)",
          pb: "0.5rem",
        }}
      >
        PRICE DETAILS
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: { sm: "18px", xs: "18px" },
            fontFamily: "ABLE",
            lineHeight: "35px",
            fontWeight: "500",
          }}
        >
          Subtotal
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "18px", xs: "18px" },
            fontFamily: "ABLE",
            lineHeight: "35px",
            fontWeight: "500",
          }}
        >
          $ {count * unitPrice}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: { sm: "18px", xs: "18px" },
            fontFamily: "ABLE",
            lineHeight: "35px",
            fontWeight: "500",
          }}
        >
          Shipping
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "18px", xs: "18px" },
            fontFamily: "ABLE",
            lineHeight: "35px",
            fontWeight: "500",
          }}
        >
          $ {shippingFee}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid rgb(109 79 160)",
          pb: "0.5rem",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: { sm: "18px", xs: "18px" },
            fontFamily: "ABLE",
            lineHeight: "35px",
            fontWeight: "600",
          }}
        >
          Total Amount
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "18px", xs: "18px" },
            fontFamily: "ABLE",
            lineHeight: "35px",
            fontWeight: "600",
          }}
        >
          $ {totalPrice}
        </Typography>
      </Box>
      {show && (
        <Box pt="1rem">
          <Button
            sx={{
              background: "rgb(109 79 160) !important",
              color: "#fff !important",
              fontFamily: "ABLE",
              p: "0.7rem",
              fontSize: "18px !important",
              lineHeight: "initial !important",
              width: "100%",
              "&:hover": {
                background: "rgb(109 79 160)",
              },
            }}
            component={"a"}
            // onClick={handleShow}
            href="#placeOrder"
          >
            PLACE ORDER
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PlaceOrder;
