import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../redux/Constant";

const ItemDetails = ({ item }) => {
  const [productData, setProductData] = useState([]);
  const getproductData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/get/product/by/${item.product_id}`
      );
      if (res.status === 200) {
        setProductData(res.data.data?.[0]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getproductData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          fontSize: { sm: "18px", xs: "16px" },
          fontFamily: "ABLE",
          lineHeight: "35px",
          fontWeight: "600",
          color: "#000",
        }}
      >
        {productData?.name} (<em>Qty: </em>{item?.quantity})
      </Typography>
      <Typography
        sx={{
          fontSize: { sm: "18px", xs: "16px" },
          fontFamily: "ABLE",
          lineHeight: "35px",
          fontWeight: "600",
          color: "#000",
        }}
      >
        $ {parseFloat(item?.total_price).toFixed(2)}
      </Typography>
    </Box>
  );
};

export default ItemDetails;
