import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import download from "../../Assets/Images/download.png"
import NewTypography from "../../Components/NewTypography";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"

const Earlier = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  const goManualData=[
    {
      id:1,
      title:"5.0 SOFTWARE (REGULAR VERSION)",
      link:"https://better-physiology-videos.s3.amazonaws.com/Capno5_.exe",
    },
    {
      id:2,
      title:"5.0 SOFTWARE (GROUP VERSION)",
      link:"https://better-physiology-videos.s3.amazonaws.com/CapnoGroup.exe"
    },
    {
      id:3,
      title:"6.0 Software for 5.0 users",
      subtitle:"(no longer updated for 5.0 users)",
      link:"https://capno-software.s3.amazonaws.com/software_data/CapnoTrainer+Setup+2.2.2.exe"
    },

  ]
  return (
    <Container>
    <Navbar/>
      <Box sx={{ color: "rgb(109 79 160)", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
        <Title title={`Download 5.0`} paddingb={'0rem'} />

       <Box pb={"1.5rem"} pt={"1rem"}>
       <NewTypography
            text={
              "CapnoTrainer 5.0 and earlier software is no longer being supported. You may download the last version here."
            }
            fsm={"22px"}
            fxs={"17px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
       <NewTypography
            text={
              "The last version only works on Windows PC."
            }
            fsm={"22px"}
            fxs={"17px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
       </Box>
      {
        goManualData.map((e,index)=>(
          <Box key={index} pb="1rem">
       <Typography
          component={"a"}
          href={e.link}
          sx={{
            fontSize: { sm: "22px", xs: "17px" },
            fontFamily: "ABLE",
            lineHeight: { sm: "32px", xs: "30px" },
            color:"rgb(109 79 160)",
            fontWeight:600
          }}
        >
         DOWNLOAD
        </Typography>{" "}
        <Typography
          component={"span"}
          sx={{
            fontSize: { sm: "22px", xs: "17px" },
            fontFamily: "ABLE",
            lineHeight: { sm: "32px", xs: "30px" },
            color:"#040404"
          }}
        >
         {e.title}
        </Typography>
        {
          e.subtitle &&
          <Typography
          component={"p"}
          sx={{
            fontSize: { sm: "18px", xs: "13px" },
            fontFamily: "ABLE",
            lineHeight: { sm: "32px", xs: "30px" },
            color:"#040404"
          }}
        >
         {e.subtitle}
        </Typography>
        }
       </Box>
        ))
      }
      <Box sx={{my:"2rem"}}>
          {" "}
          <Typography component={"img"} src={download} width={"100%"}/>
        </Box>
      </Box>
      <Box pt={"2rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Earlier;
