import React, { useEffect } from 'react'
import { Box, Container } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from '../../Components/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"

const GoOverview = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <Container>
    <Navbar/>
    <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
      <Title title={`GO Overview`} />
      <embed src="https://better-physiology-videos.s3.amazonaws.com/Professional-GO-Overview-1.pdf#toolbar=0" type="application/pdf" width="100%" height="700" style={{borderRadius:"10px",padding:"2rem 0 1rem"}}></embed>
    </Box>
    <Box pt={"1rem"}>
      <Copyright />
    </Box>
  </Container>
  )
}

export default GoOverview