import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../Components/Navbar";
import Copyright from "../Components/Copyright";
import Title from "../Components/Title";
import axios from "axios";
import { API_URL } from "../redux/Constant";
import Loader from "../Components/Loader";

const Annoucement = () => {
  const [annoucementList, setAnnoucementList] = useState([]);

  useEffect(() => {
    AOS.init();
    annoucementFunc();
  }, []);
  const annoucementFunc = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/all/announcement`);
      if (res.status === 200) {
        setAnnoucementList(res.data.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <Container>
      <Navbar />
      <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up">
        <Title title={`Annoucement`} paddingb={"2rem"} />

        {annoucementList?.length>0? annoucementList?.map((list, index) => (
          <Box
            key={index}
            sx={{
              border: "1px solid #7c13867d",
              marginTop: "1rem",
              borderRadius: "4px",
              p: { sm: "1.5rem", xs: "1rem" },
            }}
          >
          <Box sx={{
            display:"flex",
            flexDirection:{sm:"row",xs:"column"},
            justifyContent:{sm:"space-between"},
            gap:"0.6rem",
            pb:"1rem"
          }}>
          <Typography
              textAlign={"left"}
              variant="h5"
              fontFamily={"ABLE"}
              color={"#000"}
              fontWeight={600}
              sx={{
                fontSize: {md:"24px",xs:"20px"},
              }}
            >
              {list?.headline}
            </Typography>
          <Typography
              textAlign={"left"}
              variant="h5"
              fontFamily={"ABLE"}
              color={"#000"}
              fontWeight={600}
              sx={{
                fontSize: "16px",
              }}
             
            >
            Date: {new Date(list?.created).toLocaleDateString()}
            </Typography>

          </Box>
            <Typography
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: list?.content,
              }}
              sx={{
                "& p": {
                  marginTop: 0,
                },
              }}
            />
          </Box>
        ))
        :
        <Box
              sx={{
                height: "40vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>}

        <Box pt="6rem">
          <Copyright />
        </Box>
        {/* <div class="ctct-inline-form" data-form-id="c92f3cf7-5f23-44f7-908c-bf70d551a4f8"></div> */}
      </Box>
    </Container>
  );
};

export default Annoucement;
