import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../Components/Title";
import Copyright from "../Components/Copyright";
import consult_img from "../Assets/Images/consult_img.jpg";
import NewTypography from "../Components/NewTypography";
import EmailTypography from "../Components/EmailTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../Components/Navbar";

const ProfessionalEducation = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`Professional Education`} paddingb={"1.5rem"} />
        <Box>
         <Typography 
            sx={{
              fontSize: { sm: "22px", xs: "19px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "30px" },
              textAlign: { sm: "start", xs: "justify" },
            }}>
The <b>Professional School Of Behavioral Health Sciences</b> offers a <b>Professional
Certificate</b> in the Science of Breathing Behavior Analysis, <b>Certification</b> as
Certified Breathing Behavior Analyst, and a <b>Professional Diploma</b> in
Behavioral Breathing Science to qualified applicants. 
         </Typography>
         <Typography 
            sx={{
              pt:"1rem",
              fontSize: { sm: "22px", xs: "19px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "30px" },
              textAlign: { sm: "start", xs: "justify" },
              "& a":{
                color:"rgb(109 79 160)",
                fontWeight:"600"
              }
            }}>
            If you are interested in formal training, visit their website at <a href="https://www.bp.edu/" target="_blank">www.bp.edu</a> or
write to them for a Zoom appointment at <a href="mailto:education@bp.edu">education@bp.edu</a>. Go to {" "}
<a href="https://e-campus-registration.bp.edu/registration/146" target="_blank">www.e-campus.bp.edu</a> for a brochure, scheduling, and registration.
         </Typography>
          <Box pt={"1.5rem"}>
            <NewTypography
              text={`A problem…`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
              fontWeight={600}
            />
            <NewTypography
              text={`Statistics suggest that tens millions of people world-wide suffer with the
profound and misunderstood symptoms and deficits of learned
dysfunctional breathing habits. Unfortunately, these habits are rarely
identified by practitioners, their effects mistakenly attributed to other
causes, and their resolutions prescriptive in nature where focus is on
symptoms rather than on causes.`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
          </Box>
          <Box pt={"1.5rem"}>
            <NewTypography
              text={`A solution…`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
              fontWeight={600}
            />
            <NewTypography
              text={`The Professional School (1) trains healthcare practitioners, human service
professionals, health educators, and performance enhancement consultants
to integrate breathing learning services into their practices and businesses;
(2) trains breathing specialists, e.g., breathworkers, to integrate the
principles of behavior analysis into their practices; and (3) trains highperformance professionals such as athletes, performers, artists, and pilots,
to bring breathing science applications into their work. To this end the
School offers two breathing behavior analysis programs.`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
          </Box>
          <Box pt={"1.5rem"}>
            <NewTypography
              text={`Professional Certificate: Science of Breathing Behavior Analysis`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
              fontWeight={600}
            />
            <NewTypography
              text={`Earning this Certificate requires completing three webinars during a
trimester, including: Respiratory Psychophysiology, Breathing Habit
Assessment, and Breathing Habit Modification. These courses constitute
three units of credit and 45 professional CE hours.`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
          </Box>
          <Box pt={"1.5rem"}>
            <NewTypography
              text={`Professional Certification: Certified Breathing Behavior Analyst`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
              fontWeight={600}
            />
            <NewTypography
              text={`Becoming Certified as a Breathing behavior Analyst requires completing the
Proseminar, Case Analysis, Review, and Practicum, during the trimester
following the Certificate training. During the Proseminar candidates present
cases of their own and provide feedback for colleagues. The Proseminar
constitutes another three units of credit and 45 professional CE hours.`}
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
          </Box>
        </Box>
        {/* <Box
          pt={"2.5rem"}
          sx={{ m: "0 auto", display: { md: "block", xs: "none" } }}
        >
          <Typography
            component={"img"}
            src={consult_img}
            style={{
              position: "absolute",
              width: "380px",
              right: "0px",
              top: "20px",
            }}
            mt={"60px"}
            width={"100%"}
            borderRadius={"20px"}
          />
        </Box> */}
      </Box>
      {/* <Box
        sx={{
          display: { md: "none", xs: "block" },
          width: { sm: "80%", xs: "100%" },
          margin: "0 auto",
        }}
      >
        <Typography
          component={"img"}
          src={consult_img}
          width={"100%"}
          mt={"2rem"}
          borderRadius={"20px"}
        />
      </Box> */}

      <Box
        sx={{
          display: "none",
          height: { lg: "10vh", xs: "0" },
          "@media(min-width:1300px)": {
            display: "block",
          },
        }}
      />
      <Box pt={"3rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ProfessionalEducation;
