import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const ProductComparision = (value) => {
    const [show,setShow] = useState(value.value==1 ? true :false)
  return (
    <Box
      sx={{
        border: "1px solid rgb(109 79 160)",
        p: "1rem",
        borderRadius: "10px",
        mt: "2rem",
      }}
    >
      <Typography
        sx={{
          fontSize: { sm: "23px", xs: "20px" },
          fontFamily: "ABLE",
          lineHeight: "30px",
          fontWeight: "600",
          borderBottom: "1px solid rgb(109 79 160)",
          pb: "0.5rem",
          textAlign: "center",
        }}
        // className="title"
      >
        Which GO should you buy?
        <Typography
          sx={{
            fontSize: { sm: "20px", xs: "18px" },
            fontFamily: "ABLE",
            lineHeight: "35px",
            fontWeight: "600",
            textAlign: "center",
          }}
        className="title"

        >
          Personal, Basic, or Professional
        </Typography>
      </Typography>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "700",
          py: "0.7rem",
        }}
      >
        Use the GUIDE below to determine which version of the CapnoTrainer GO
        best suits you.
      </Typography>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",
          //   pb: "0.7rem",
        }}
      >
        Please read the brochure for detailed descriptions of the differences.
        <br />
        You may upgrade from one version to the next at any time.
      </Typography>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",
          py: "0.8rem",
        }}
      >
        All customers should purchase the GO Starter Kit and additional packs of
        Sampling Lines if working with clients.
         {/* {!show  && <span style={{textDecoration:"underline",fontWeight:"600",cursor:"pointer"}} onClick={()=>setShow(!show)}>Learn More</span>} */}
      </Typography>
    {show &&  <Box>
      <Typography
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          // lineHeight: "35px",
          fontWeight: "600",
          color:"rgb(109 79 160)",
          pt:"0.5rem"
        }}
      >
        CapnoTrainer GO Personal:
      </Typography>

      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",
          
        }}
      >
      <em>  “I will be using the GO to improve my own health and performance, no
        more.”</em>
      </Typography>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",

        }}
      >
        <span style={{ fontWeight: "bold" }}>Note:</span> The GO Personal
        operates on cell phones and tablets ONLY (without Option C).
      </Typography>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",
          pb: "0.5rem",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Note:</span> You cannot collect data for clients or friends (without Option M). 
      </Typography>
      <Typography
        sx={{
          fontSize: { sm: "18px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "600",
        }}
      >
        CapnoTrainer GO Personal with OPTION C:
      </Typography>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",
          py: "0.5rem",
        }}
      >
       <em> “I also want the{" "}
        <span style={{ fontWeight: "bold" }}>GO Basic Computer software</span>{" "}
        (PC or Mac) for expanding my learning options with built-in learning
        practicums.”</em>
      </Typography>
      <Typography
        sx={{
          fontSize: { sm: "18px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "600",
        }}
      >
        CapnoTrainer GO Personal with OPTION M:
      </Typography>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",
          py: "0.5rem",
        }}
      >
        <em>“I also want to collect data and create reports not just for myself but for others as well, e.g., family 
members, friends, and clients.”</em>
      </Typography>
      <Typography
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          // lineHeight: "35px",
          fontWeight: "600",
          color:"rgb(109 79 160)",
          pt:"0.5rem"
        }}
      >
        CapnoTrainer GO Basic:
      </Typography>
    
      <Box
        sx={{
          "& p": {
            fontSize: { sm: "20px", xs: "18px" },
            fontFamily: "ABLE",
            fontWeight: "500",
            pb: "0.5rem",
          },
        }}
      >
        <Typography component={"p"}>
          “<em>I am a SOLO practitioner and I will be using the GO with my clients.</em>”
        </Typography>
        <Typography component={"p"}>
        “<em>I am not interested in the advanced software and database features of the Professional GO.</em>”  
        </Typography>
        <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "500",
          pb: "0.7rem",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Note: </span>The GO Basic is the same as the GO Personal with Options C and M
      </Typography>
      </Box>
      <Typography
        component={"p"}
        sx={{
          fontSize: { sm: "20px", xs: "18px" },
          fontFamily: "ABLE",
          fontWeight: "600",
          color:"rgb(109 79 160)",
          pt:"0.5rem"
        }}
      >
        CapnoTrainer GO Professional:
      </Typography>
      <Box
        sx={{
          "& p": {
            fontSize: { sm: "20px", xs: "18px" },
            fontFamily: "ABLE",
            fontWeight: "500",
          },
        }}
      >
        <Typography component={"p"}>
          Is any one of the following true?
        </Typography>
        <Typography component={"p"} pb={"0.7rem"}>
          If so, you will need {" "}
         <em> <span style={{ fontWeight: "bold" }}>the GO Professional</span></em>.
        </Typography>
        <Typography component={"p"}>
          <em>“We are an organization, e.g., a clinic.”</em>
        </Typography>
        
        <Typography component={"p"}>
          <em>“We will be using more than one GO in our practice.”</em>
        </Typography>
        <Typography component={"p"}>
         <em> “More than one person will be using the GO for work with clients.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“Our clients will monitor themselves and collect data in the field.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“We plan to rent instruments to clients.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“We plan to do group work (i.e., work with multiple clients
          simultaneously).”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“We want to customize data reports and write report notes.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“Clients will need their own logins to see their data.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“We need additional assessment tools, such as interview forms.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“We are using the GO for research purposes.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“We want to take advantage of GO’s many advanced features, e.g., HRV algorithms.”</em>
        </Typography>
        <Typography component={"p"}>
          <em>“We want “live” tech support.”</em>
        </Typography>
      </Box>
      </Box>}
    </Box>
  );
};

export default ProductComparision;
