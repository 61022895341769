import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"


const Publications = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  const referenceData = [
    {
      id: 1,
      article:
        "Chaitow, L., Bradley, D., Gilbert, C., & Ley, R. (2002) Multidisciplinary approaches to breathing pattern disorders. London: Churchill Livingstone.",
    },
    {
      id: 2,
      article:
        "Fried, R. (1999) Breathe Well, Be Well. Toronto: John Wiley & Sons Canada, Limited; p 45.",
    },
    {
      id: 3,
      article:
        "Fried, R. (1987) Hyperventilation Syndrome: Research and Clinical Treatment. Baltimore: John Hopkins Univ. Press.",
    },
    {
      id: 4,
      article:
        "Fried, R., & Grimaldi, J. (1993) The Psychology and Physiology of Breathing in Behavioral Medicine, Clinical Psychology, and Psychiatry.",
    },
    {
      id: 5,
      article: "New York Plenum Press.",
    },
    {
      id: 6,
      article:
        "Laffey, J. G., & Kavanagh, B. P. Hypocapnia. New England Journal of Medicine (2002); 347(1): 43-53.",
    },
    {
      id: 7,
      article:
        "Levitzky, M. G. (2007) Pulmonary Physiology (7th edition). New York: McGraw Hill.",
    },
    {
      id: 8,
      article:
        "Litchfield, Peter M. CapnoLearning: respiratory fitness and acid-base regulation. Psychophysiology Today (2010).",
    },
    {
      id: 9,
      article:
        "Litchfield, P. M. & Reamer, S. (2022) Embodied breathing habits: aligning breathing mechanics with respiratory chemistry.",
    },
    {
      id: 10,
      article:
        "Journal of Holistic Healthcare and Integrative Medicine. Vol. 19 (22), pp 37-42.",
    },
    {
      id: 11,
      article:
        "Litchfield, P. M. & Reamer, S. (2023) CapnoLearning: An Introductory Guide. Breathing Science, Inc. Cheyenne, WY USA.",
    },
    {
      id: 12,
      article:
        "Litchfield, P. M. & Tsuda, A. (2006) Good breathing, bad breathing. L’Esprit D’aujourdhui 8 (1), 47-57.",
    },
    {
      id: 13,
      article:
        "Thomson, W. S. T., Adams, J. F., & Cowan, R. A. (1997) Clinical Acid-Base Balance. New York: Oxford Univ. Press.",
    },
    {
      id: 14,
      article:
        "Timmons, B. H. & Ley, R. (1994) Behavioral and Psychological Approaches to Breathing Disorders. New York: Plenum Press",
    },
  ];
  return (
    <Container>
    <Navbar/>
      <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
        <Box mb={"0.5rem"}>
          <Title title={`Publications`} paddingb={'1.5rem'} />
        </Box>
        {referenceData.map((e, index) => (
          <Box pb={ index == 8 || index == 3 ? "0px":"1rem"} key={index}>
          {console.log(e, "value of e", )}
            <NewTypography
              text={e.article}
              fsm={index == 9 || index == 4 ? "20px": "22px"}
              fxs={index == 9 || index == 4 ? "17px": "19px"}
              lsm={index == 9 || index == 4 ? "33px": "35px"}
              lxs={index == 9 || index == 4 ? "28px": "30px"}
              textalign={"justify"}
              color={"#040404"}
              index={index}
              fstyle={index == 9 || index == 4 ? 'italic' :''}
            />
          </Box>
        ))}
        <Box pt={"2rem"}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default Publications;
