import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import Copyright from "../../Components/Copyright";
import Title from "../../Components/Title";
import axios from "axios";
import { API_URL } from "../../redux/Constant";
import Loader from "../../Components/Loader";

const Faq = () => {
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [faqCategory, setFaqCategory] = useState([]);
  const [faqQuestions, setFaqQuestions] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  useEffect(() => {
    AOS.init();
    getfaqCategory()
    getfaqQues()
  }, []);
  const getfaqCategory = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/all/faqCategory`);
      if (res.status === 200) {
        setFaqCategory(res.data.faqscategory);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getfaqQues = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/all/faq`);
      if (res.status === 200) {
        setFaqQuestions(res.data.faqs)
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  

  return (
    <Container>
    <Navbar />
    <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up">
      <Title title={`FAQs`} paddingb={'2rem'} />

      <Box
        sx={{
          width: { sm: "75%" },
          margin: "0 auto",
          marginTop:'-3rem'
        }}
      >
        {faqCategory?.length>0? faqCategory?.map((category, index) => (
          <Box sx={{ marginTop: "2rem" }} key={index}>
            <Typography
              sx={{
                fontSize: {
                  sm: "26px",
                  xs: "23px",
                },
                lineHeight: "25px",
                fontFamily: "ABLE",
                fontWeight: "500",
                mb: "1rem",
                color:'purple'
              }}
            >
              {/* {category.name}  */}
            </Typography>
            {faqQuestions?.map((faq, v) => (
              <Accordion
                key={`panel${index + 1}-${v + 1}`}
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "inherit",
                  border: "1px solid #7c13867d",
                  marginTop: "1rem",
                  borderRadius: "4px",
                }}
                expanded={expandedPanel === `panel${index + 1}-${v + 1}`}
                onChange={handleChange(`panel${index + 1}-${v + 1}`)}
              >
                <AccordionSummary
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fill: "rgb(109 79 160)",
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-${v + 1}bh-content`}
                  id={`panel${index + 1}-${v + 1}bh-header`}
                >
                  <Typography
                    textAlign={"left"}
                    variant="h6"
                    fontFamily={"ABLE"}
                    color={"#000"}
                    fontWeight={600}
                  >
                   {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    textAlign: "left",
                    padding: "0px 16px 0px",
                    fontWeight:'600'
                  }}
                >
                <Typography
                fontSize={"18px"}
          component={"span"}
          dangerouslySetInnerHTML={{
                          __html:faq.answer,
                        }}
          sx={{
            "& p":{
              marginTop:0
          // wordBreak:"break-word",
          // fontSize: "20px",
          // textAlign: "justify",
          // fontFamily: "ABLE !important",
        }    
          }}
        />    
                {/* <Typography component={'p'}> {faq.answer}</Typography> */}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )):
        <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>}
      </Box>

      <Box pt="4rem">
        <Copyright />
      </Box>
      {/* <div class="ctct-inline-form" data-form-id="c92f3cf7-5f23-44f7-908c-bf70d551a4f8"></div> */}

    </Box>
  </Container>
  );
};

export default Faq;
