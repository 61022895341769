import { Typography } from "@mui/material";
import React from "react";

const SupportNewTypography = ({ text, text2, email, fsm, fxs, lsm, lxs,fstyle, index,  link,textalign,color,fontWeight,subtitle}) => {
  console.log(fstyle, "font style")
  return (
    <>
    <Typography
      sx={{
        fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        // fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        textAlign: { sm: "start", xs: textalign },
        fontWeight:fontWeight,
        color:color && color,
        fontStyle:index == 9 || index == 4 ? `${fstyle}`:"none"
      }}
      className="title"
    >
      {text}{" "} 
      {email && (
        <Typography
        component={"a"}
        href={`mailto:${email}`}
        sx={{
            fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        color:"rgb(109 79 160)",
        fontWeight:"600"
        }}
    >
        {email}
    </Typography>
      )} {text2}
    </Typography>
    <span color={"#000"} fontSize={"18px"}><i>{subtitle}</i></span>
    </>
  );
};

export default SupportNewTypography;
