import { Typography } from "@mui/material";
import React from "react";

const NewTypography = ({ text, fsm, fxs, lsm, lxs,fstyle,marginTop ,fontStyle, index,  link,textalign,color,fontWeight,subtitle}) => {
 
  return (
    <>
    <Typography
      sx={{
        fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        
        marginTop: marginTop || 0,
        // fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        textAlign: { sm: "start", xs: textalign },
        fontWeight:fontWeight,
        color:color && color,
        fontStyle: fstyle || "none"
      }}
      className="title"
      
    >
      {text}{" "} 
      {link && (
        <Typography
          component={"a"}
          href={link}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            color: "rgb(109 79 160)",
            fontWeight:600
            // textAlign: { sm: "start", xs: "justify" },
          }}
          target="_blank"
        >
          {link}
        </Typography>
      )}
    </Typography>
    <span color={"#000"} fontSize={"18px"}><i>{subtitle}</i></span>
    </>
  );
};

export default NewTypography;
