import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const GoPersonalC = () => {
  return (
    <Box
      sx={{
        height:{md:"60vh"},
        "& p": {
          fontSize: "20px",
          textAlign: "justify",
          fontFamily: "ABLE !important",
        },
      }}
    >
      <Typography pb={"1.5rem"}>
        The CapnoTrainer GO Personal with{" "}
        <b>Option C (for PC and Apple computers)</b> provides users with Go
        Basic software which offers numerous benefits beyond the scope of the
        cell phone and tablet App., including automated versions of the
        practicums (14) appearing in the book,{" "}
        <b>CapnoLearning: An Introductory Guide</b> (Breathing habits, optimal
        respiration, and acid-base physiology).
      </Typography>

      <Typography pb={"1.5rem"}>
        Click on CapnoTrainer,{" "}
        <Link style={{ textDecoration: "underline",color:"rgb(109 79 160)" }} to={"/go-Detail/12"}>
          <b>GO Personal</b>
        </Link>{" "}
        for more details.
      </Typography>
      <Typography pb={"1.5rem"}>
        GO Personal, Option C users may upgrade to GO Basic at any time by
        adding Option M ($450.00).
      </Typography>
    </Box>
  );
};

export default GoPersonalC;
