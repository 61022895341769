import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px 0", color: "rgb(109 79 160)" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#fff",
            top: "-10px",
            background: "rgb(109 79 160)",
            // border:"1px solid rgb(109 79 160)",
            borderRadius:"100%",
            "&:hover": {
              background: "rgb(109 79 160)",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ErrorModal({
    errModal,
  setErrModal,
  errorText
}) {

  const closeHandle = () => {
    setErrModal(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => closeHandle()}
        aria-labelledby="customized-dialog-title"
        open={errModal}
        sx={{
          "& .MuiPaper-root": {
            overflowY: "inherit",
          },
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "1222px",
            // background: "#E4D5F4",
            border: "2px solid rgb(109 79 160)",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => closeHandle()}
        >
        <Typography sx={{color:"red",fontSize:"1.2rem",borderBottom:"1px solid rgb(109 79 160)",pb:"0.3rem",mb:"0.3rem",display:"flex",alignItems:"center",gap:"0.1rem",fontFamily:"ABLE"}}><ErrorIcon sx={{mb:"2px"}}/>Error</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
         <Box sx={{
            textAlign:"center",
            py:"1.5rem",
            fontSize:"1.2rem",
            color:"red",
            fontFamily:"ABLE"
         }}>
            {errorText}
           </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
