import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Copyright from "../../Components/Copyright";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import grid_2 from "../../Assets/Images/grid_2.png";
import blog_7 from "../../Assets/Images/BP imgs/blog_6_new.png";
import blog_9 from "../../Assets/Images/BP imgs/blog_7_new.jpg";
import blog_8 from "../../Assets/Images/blog_8.png";
import blog_4_new from "../../Assets/Images/blog_4_new.png";

const BlogDetailPage_3 = () => {
  useEffect(() => {
    AOS.init();
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "ABLE",
            // textAlign: "center",
            // pb: "1rem",
            fontWeight: "600",
          }}
        >
          {/* <em style={{ textDecoration: "underline" }}> */}
            Understanding the Environmental Impact of Air Pollution on
            {/* <br /> */}
            Respiratory Health
            <span style={{ fontSize: "15px", verticalAlign: "top" }}>®</span> GO
          {/* </em> */}
        </Typography>
        <Typography
            sx={{
              fontSize: "16px",
              // textAlign: "center",
              pb: "1rem",
              fontFamily: "ABLE",
              fontWeight: "600",
            }}
          >
            Mon Apr 29 2024
          </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
          }}
        >
          Air pollution is a pressing global concern with far-reaching
          implications for public health and the environment. Among its various
          adverse effects, air pollution significantly impacts respiratory
          health, leading to an array of respiratory diseases and exacerbating
          existing conditions. Understanding the intricate relationship between
          air pollution and respiratory health is crucial for implementing
          effective mitigation strategies and safeguarding public well-being.
        </Typography>

        <Typography
          component={"img"}
          src={blog_7}
          sx={{ float: "right",p:{md:"0rem 1rem 0rem 1rem",xs:"0 0 1rem"}, width: { md: "50%", xs: "100%" } }}
          height={"100%"}
          pb={"1rem"}
        />
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "ABLE",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          <em style={{ textDecoration: "underline" }}>
            The Environmental Impact of Air Pollution on Respiratory Health
          </em>
        </Typography>

        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          Air pollution consists of a complex mixture of pollutants, including
          particulate matter (PM), nitrogen dioxide (NO2), sulfur dioxide (SO2),
          ozone (O3), and volatile organic compounds (VOCs), among others. These
          pollutants are emitted from various sources such as vehicular
          emissions, industrial activities, biomass burning, and natural
          processes.
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify",
          }}
        >
          Exposure to air pollution has been linked to a range of respiratory
          conditions, including asthma, chronic obstructive pulmonary disease
          (COPD), bronchitis, and respiratory infections. Particulate matter, in
          particular, poses a significant threat to respiratory health, as fine
          particles can penetrate deep into the lungs, causing inflammation and
          exacerbating existing respiratory conditions.
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          Moreover, certain air pollutants, such as nitrogen dioxide and ozone,
          can irritate the respiratory tract, leading to respiratory symptoms
          such as coughing, wheezing, and shortness of breath. Prolonged
          exposure to air pollution may also increase the risk of developing
          respiratory diseases later in life, highlighting the importance of
          addressing this issue proactively.
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "ABLE",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          <em style={{ textDecoration: "underline" }}>
            The Role of CapnoTrainer GO in Respiratory Health
          </em>
        </Typography>
        <Typography
          component={"img"}
          src={blog_4_new}
          sx={{ float: "left",p:{md:"1rem 1rem 0rem 0rem",xs:"0 0 1rem"}, width: { md: "auto", xs: "100%" } }}
          height={"100%"}
          pb={"1rem"}
        />
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
          }}
        >
          In the face of escalating air pollution levels, tools like the
          CapnoTrainer GO offer valuable support in promoting respiratory health
          and mitigating the effects of air pollution. The CapnoTrainer GO is a
          state-of-the-art hardware and software system designed to provide
          personalized breathing education based on principles of behavioral
          analysis.
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
          }}
        >
          With its three versions - Personal, Basic, and Professional - the
          CapnoTrainer GO caters to a diverse range of users, including
          healthcare practitioners, fitness trainers, mental health
          professionals, educators, and individuals with a keen interest in
          breathing science. Its portability, lightweight design, and
          compatibility with various devices make it accessible and convenient
          for use in various settings.
        </Typography>
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
          }}
        >
          With its three versions - Personal, Basic, and Professional - the
          CapnoTrainer GO caters to a diverse range of users, including
          healthcare practitioners, fitness trainers, mental health
          professionals, educators, and individuals with a keen interest in
          breathing science. Its portability, lightweight design, and
          compatibility with various devices make it accessible and convenient
          for use in various settings.
        </Typography>
        <Typography
          component={"img"}
          src={blog_9}
          sx={{ float: "right",p:{md:"1rem 1rem 0rem 1rem",xs:"0 0 1rem"}, width: { md: "50%", xs: "100%" } }}
          height={"100%"}
          pb={"1rem"}
        />
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          By offering real-time feedback and training tools, the CapnoTrainer GO
          empowers users to improve their breathing patterns, optimize
          respiratory function, and enhance overall well-being. Whether it's
          through guided breathing exercises, biofeedback training, or
          respiratory assessments, the CapnoTrainer GO equips individuals with
          the knowledge and tools they need to take control of their respiratory
          health.
        </Typography>

     
        <Typography
          sx={{
            fontSize: "22px",
            fontFamily: "ABLE",
            pb: "1rem",
            textAlign:"justify"
          }}
        >
          In conclusion, the environmental impact of air pollution on
          respiratory health is a critical public health issue that demands
          attention and action. As we strive to mitigate the effects of air
          pollution and protect respiratory health, tools like the CapnoTrainer
          GO emerge as valuable assets in our arsenal. By leveraging innovative
          technologies and education tools, we can empower individuals to
          safeguard their respiratory health, reduce their susceptibility to
          respiratory diseases, and thrive in an increasingly polluted
          environment. Let us harness the power of knowledge, innovation, and
          collaboration to create a healthier, cleaner future for all.
        </Typography>
        {/* <Typography
          component={"img"}
          src={blog_9}
          sx={{width:{md:"auto",xs:"100%"}}}
          height={"100%"}
          pb={"1rem"}
        /> */}
        <Box pt={"5rem"}>
          <Copyright center={"center"} />
        </Box>
      </Box>
    </Container>
  );
};

export default BlogDetailPage_3;
