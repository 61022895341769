
import React, { createContext, useState } from "react";

export const ContractContext = createContext();
const ContractContextProvider = ({ children }) => {
    const [show,setShow] = useState(true)




  return (
    <ContractContext.Provider
      value={{
        show,setShow
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};
export default ContractContextProvider;
