import { Typography } from "@mui/material";
import React from "react";

const HistoryTypography = ({ text1, fsm, fxs, lsm, lxs, text2,fsm1, fxs1, lsm1, lxs1, }) => {
  return (
    <Typography
      sx={{
        fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        textAlign: { sm: "start", xs: "justify" },
        color:'rgb(109 79 160)',
        fontWeight:600
      }}
    >
      {text1}   <Typography component={"span"}
      sx={{
        fontSize: { sm: fsm1, xs: fxs1 },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm1, xs: lxs1 },
        textAlign: { sm: "start", xs: "justify" },
        color:"#040404"
      }}
    >
      {text2}
     
    </Typography>
     
    </Typography>
  );
};

export default HistoryTypography;
