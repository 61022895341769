import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../redux/Constant";
import StatusCode from "../utils/StatusCode";

const initialState ={
    data:[],
    status:StatusCode.IDLE
}

const cartSlice = createSlice({
    name:'cart',
    initialState,
    // reducers:{
    //   fetchProducts(state,action){
    //     state.data=action.payload;
    //   }
    // },
    extraReducers:(builder)=>{
        builder
        .addCase(getCart.pending,(state,action)=>{
            state.status=StatusCode.LOADING
        })
        .addCase(getCart.fulfilled,(state,action)=>{
            state.data=action.payload;
            state.status=StatusCode.IDLE
        })
        .addCase(getCart.rejected,(state,action)=>{
            state.status=StatusCode.ERROR
        })
    }
})

// export const {fetchProducts} = cartSlice.actions; 

export default cartSlice.reducer;

export const getCart = createAsyncThunk('cart/get',async (userId)=>{
    const data = await fetch(`${API_URL}/get-cart/by/${localStorage.getItem('user')}`)
    const result =await data.json();
    return result;
})
