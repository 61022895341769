import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import HistoryTypography from "./HistoryTypography";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"

const History = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  const HistoryData = [
    {
      id: 1,
      title: "Better Physiology, Ltd.",
      desc: "was incorporated in May of 2000. It is a privately held USA Wyoming corporation owned by 52 stockholders from around the world, all of whom subscribe to the Company mission.",
    },
    {
      id: 2,
      title: "The Company",
      desc: "was founded based on statistics suggesting that millions of people worldwide suffer with the profound and misunderstood symptoms and deficits of learned dysfunctional breathing habits. Unfortunately, these habits are rarely identified by practitioners, their effects mistakenly attributed to other causes, and their resolutions prescriptive in nature where focus is on symptoms rather than on causes.",
    },
    {
      id: 3,
      title: "The CapnoTrainer®",
      desc: "was developed to provide practitioners and their clients with training and learning tools for identifying, exploring, unlearning, and learning breathing habits based on the principles of behavioral analysis through the presentation of live and recorded breathing physiology.",
    },
    {
      id: 4,
      title: "Distributors",
      desc: "are located in Asia, Australia, Europe, North America, and South America, many of whom also also provide training and expertise in CapnoLearning.",
    },
    {
      id: 5,
      title: "Customer practitioners",
      desc: "are from more than 60 countries including a wide range of health, human service, and performance training professions.",
    },
    {
      id: 6,
      title: "Customer organizations",
      desc: "include diverse groups such as athletic clubs, clinics, consulting firms, corporations, fitness centers, government agencies, hospitals, military, police departments, private practices, schools, universities, US military, and yoga centers.",
    },
    {
      id: 7,
      title: "Customer personal users",
      desc: "include people of diverse backgrounds and interests, particularly those with a special interest in their own breathing habits and the effects that these habits may be having on personal health and performance.",
    },
  ];
  return (
    <Container>
     <Navbar />
      <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
        <Title title={`History`} paddingb={'1.5rem'} />
        <Typography
          sx={{
            fontSize: "30px",
            fontFamily: "ABLE",
            lineHeight: "30px",
            textAlign: { sm: "start", xs: "center" },
            pb: "1.7rem",
            borderBottom: "1px solid rgb(109 79 160)",
          }}
          className="title"
          fontWeight={600}
        >
          CapnoTrainer GO Instruments
        </Typography>
        {HistoryData.map((e, index) => (
          <Box mt={"2rem"} key={index}>
            <HistoryTypography
              text1={e.title}
              text2={e.desc}
              fsm={"26px"}
              fxs={"21px"}
              lsm={"32px"}
              lxs={"30px"}
              fsm1={"22px"}
              fxs1={"19px"}
              lsm1={"32px"}
              lxs1={"30px"}
            />
          </Box>
        ))}
        <Box pt={"5rem"}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default History;
