import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import ItemDetails from "./ItemDetails";

const useStyles = makeStyles({
  arrow: {
    "&:before": {
      border: "1px solid rgb(109 79 160)",
    },
    color: "rgb(109 79 160) !important",
  },
  tooltip: {
    backgroundColor: "rgb(109 79 160) !important",
    border: "1px solid #ffffff7d",
    color: "#fff",
    padding: "5px 15px !important",
    fontSize: "14px !important",
    fontWeight: "bold !important",
    fontFamily: "ABLE !important",
    textAlign: "center",
  },
});

const PriceDetail = ({
  result,
  totalPrice,
  display,
  shipping,
  deliveryDate,
  hide,
}) => {
  const classes = useStyles();
  return (
    <>
      {hide && (
        <Typography
          sx={{
            fontSize: { sm: "20px", xs: "20px" },
            fontFamily: "ABLE !important",
            lineHeight: "35px",
            fontWeight: "600",
            color: "rgb(109 79 160)",
          }}
        >
          PRICE DETAILS ({result?.data?.length}{" "}
          {result?.data?.length > 1 ? "items" : "item"})
        </Typography>
      )}
      <Box
        sx={{ border: "1px solid rgb(109 79 160)", p: hide ? "0.5rem 1rem" : "1rem" }}
      >
        {!hide && (
          <Typography
            sx={{
              fontSize: { sm: "18px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: "35px",
              fontWeight: "600",
              borderBottom: "1px solid rgb(109 79 160)",
              pb: "0.5rem",
              color: "rgb(109 79 160)",
            }}
          >
            PRICE DETAILS ({result?.data?.length}{" "}
            {result?.data?.length > 1 ? "items" : "item"})
          </Typography>
        )}
        {hide && (
          <Box
            sx={{
              pb: "0.5rem",
            }}
          >
            {result?.data?.map((item, index) => (
              <Box>
                <ItemDetails item={item} key={index} />
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: !hide && "0.5rem",
            borderTop: hide && "1px solid rgb(109 79 160)",
            pt: hide && "0.3rem",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                sm: hide ? "18px" : "18px",
                xs: hide ? "16px" : "16px",
              },
              fontFamily: "ABLE",
              lineHeight: "35px",
              fontWeight:hide?"600":"500",
              color: "#000",
            }}
          >
            Total
          </Typography>
          <Typography
            sx={{
              fontSize: {
                sm: hide ? "18px" : "18px",
                xs: hide ? "16px" : "16px",
              },
              fontFamily: "ABLE",
              lineHeight: "35px",
              fontWeight:hide?"600":"500",
              color: "#000",
            }}
          >
            $ {parseFloat(totalPrice).toFixed(2)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid rgb(109 79 160)",
            pb: "0.5rem",
            color: "#000",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                sm: hide ? "18px" : "18px",
                xs: hide ? "16px" : "16px",
              },
              fontFamily: "ABLE",
              lineHeight: "35px",
              fontWeight:hide?"600":"500",
            }}
          >
            Shipping
          </Typography>
          <Typography
            sx={{
              fontSize: {
                sm: hide ? "18px" : "18px",
                xs: hide ? "16px" : "16px",
              },
              fontFamily: "ABLE",
              lineHeight: "35px",
              fontWeight:hide?"600":"500",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {shipping == null ? "NA" : `$ ${parseFloat(shipping).toFixed(2)}`}
            {/* <Tooltip placement="top" title={`Your shipping charge is calculated as per items in your cart and shipping address.`} arrow   classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
       
                     
                        </Tooltip> */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: "0.5rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: "18px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: "35px",
              fontWeight:hide?"800":"600",
              color: "#000",
            }}
          >
            Total Amount
          </Typography>
          <Typography
            sx={{
              fontSize: { sm: "18px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: "35px",
              fontWeight:hide?"800":"600",
              color: "#000",
            }}
          >
            ${" "}
            {shipping
              ? parseFloat(
                  parseFloat(totalPrice) + parseFloat(shipping)
                ).toFixed(2)
              : parseFloat(totalPrice).toFixed(2)}
          </Typography>
        </Box>
        {deliveryDate && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: "0.5rem",
              color: "#000",
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "18px" },
                fontFamily: "ABLE",
                lineHeight: "35px",
                fontWeight: "600",
              }}
            >
              Expected Delivery
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "18px" },
                fontFamily: "ABLE",
                lineHeight: "35px",
                fontWeight: "600",
              }}
            >
              {new Date(deliveryDate).toLocaleDateString()}
            </Typography>
          </Box>
        )}
        <Box pt="0.65rem" display={display}>
          <Button
            sx={{
              background: "rgb(109 79 160) !important",
              color: "#fff !important",
              fontFamily: "ABLE",
              p: "0.7rem",
              fontSize: "18px !important",
              lineHeight: "initial !important",
              border: "2px solid #C5A7CF !important",
              width: "100%",
              "&:hover": {
                background: "rgb(109 79 160)",
              },
            }}
            component={Link}
            to="/place-order"
          >
            PLACE ORDER
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(PriceDetail);
