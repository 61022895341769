import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../redux/Constant";
import StatusCode from "../utils/StatusCode";

const initialState ={
    data:[],
    status:StatusCode.IDLE
}

const ProductByIdSlice = createSlice({
    name:'productById',
    initialState,
    // reducers:{
    //   fetchProducts(state,action){
    //     state.data=action.payload;
    //   }
    // },
    extraReducers:(builder)=>{
        builder
        .addCase(getProductsById.pending,(state,action)=>{
            state.status=StatusCode.LOADING
        })
        .addCase(getProductsById.fulfilled,(state,action)=>{
            state.data=action.payload;
            state.status=StatusCode.IDLE
        })
        .addCase(getProductsById.rejected,(state,action)=>{
            state.status=StatusCode.ERROR
        })
    }
})

// export const {fetchProducts} = ProductByIdSlice.actions; 

export default ProductByIdSlice.reducer;

export const getProductsById = createAsyncThunk('productById/get',async (id)=>{
    const data = await fetch(`${API_URL}/get/product/by/${id}`)
    const result =await data.json();
    return result;
})
