import React, { useEffect } from 'react'
import { Box, Container } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"

const Webinar = () => {
    useEffect(()=>{
        AOS.init();
      },[])
  return (
    <Container>
    <Navbar/>
    <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
      <Title title={`Webinars`} />
      <Box pt={"4rem"}>
        <Copyright />
      </Box>
    </Box>
  </Container>
  )
}

export default Webinar