import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import Select from "react-select";
import { Country, State } from "country-state-city";
import axios from "axios";
import { useFormik } from "formik";
import { getInitialValues, validationSchema } from "./FormConfig";
import { useStyles } from "./BookOrderStyle";
import Paypal from "./Paypal";
import { useSelector } from "react-redux";
import PriceDetail from "../../Components/PriceDetail";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BillingInfo from "./BillingInfo";
import ChangeShipping from "./ChangeShipping";
import ErrorModal from "./ErrorModal";

const BookOrder = ({
  count,
  unitPrice,
  // selectedCountry,
  // setSelectedCountry,
  // selectedCountryShipping,
  // setSelectedCountryShipping,
  // shippingFee,
  // setNewAdd,
  // newAdd,
}) => {
  const [totalAmount, setTotalAmount] = useState(null);
  const [itemTotalAmount, setItemTotalAmount] = useState(null);

  const [orderId, setOrderId] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [shippingNew, setShippingNew] = useState("");
  const [newAdd, setNewAdd] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);

  const [selectedCountryShipping, setSelectedCountryShipping] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [shippingResponse, setShippingResponse] = useState([]);

  const classes = useStyles();
  const [checkout, setCheckOut] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateShipping, setSelectedStateShipping] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(false);
  const [errModal, setErrModal] = useState(false);
  const { data: result } = useSelector((state) => state.cart);

  useEffect(() => {
    if (result?.data) {
      const totalPriceAdd = result.data.reduce(
        (total, item) => total + (item.total_price || 0),
        0
      );
      setTotalPrice(totalPriceAdd);
    }
  }, [result]);
  // let cartId = [];
  // for (let i = 0; i < result?.data?.length; i++) {
  //   cartId.push(result.data[i].id);
  // }
  const initialValues = getInitialValues(
    selectedState,
    selectedCountry,
    newAdd,
    selectedStateShipping,
    selectedCountryShipping
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
    },
  });

  let shipping_address_info = {
    fname: formik.values.fname_s,
    lname: formik.values.lname_s,
    company: formik.values.company_s,
    country: selectedCountryShipping?.isoCode,
    state: selectedStateShipping?.isoCode,
    address_1: formik.values.address_1_s,
    address_2: formik.values.address_2_s,
    address_3: formik.values.address_3_s,
    address_4: formik.values.address_4_s,
    city: formik.values.city_s,
    postal: formik.values.postal_s,
    phone: formik.values.phone_s,
    email: formik.values.email_s,
  };

  const billing_address_info = {
    fname: formik.values.fname,
    lname: formik.values.lname,
    company: formik.values.company,
    country: selectedCountry?.isoCode,
    state: selectedState?.isoCode,
    address_1: formik.values.address_1,
    address_2: formik.values.address_2,
    address_3: formik.values.address_3,
    address_4: formik.values.address_4,
    city: formik.values.city,
    postal: formik.values.postal,
    phone: formik.values.phone,
    email: formik.values.email,
  };
  // const proceedToPayment = async () => {
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_API}/create-order`,
  //       {
  //         orderId,
  //         shipping_address_info:
  //           newAdd === false ? billing_address_info : shipping_address_info,
  //         billing_address_info,
  //         unit_price: unitPrice,
  //       }
  //     );
  //     if (res.status === 200) {
  //       console.log(res);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const makeOrder = async () => {
    setLoading(true)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/shopping-cart/make-order`,
        {
          user_id: localStorage.getItem("user"),
          affiliate: localStorage.getItem("referer"),
          shipping_address_info:
            newAdd === false ? billing_address_info : shipping_address_info,
          billing_address_info,
        }
      );
      if (res.data.status) {
        console.log(res);
        setLoading(false)
        setShipping(res.data.total_shippingRate);
        setOrderId(res.data.order_id);
        setOrderItems(res.data.order_items);
        setShippingResponse(res.data.shippingResponse);
        setItemTotalAmount(res.data.total_product_price);
        setTotalAmount(res.data.total_price_with_shipping);
        setDeliveryDate(res.data.DeliveryDate);
        setShippingNew(res.data.shippingResponse[0].full_name)
        setErrorText(null);
        setErrModal(false)
        setNext(true);
      } else {
        setLoading(false)
        setErrorText(res.data.message);
        setErrModal(true)
      }
    } catch (err) {
      setLoading(false)
      setErrorText("Execution Error");
      setErrModal(true)
    }
  };

  const handleEdit = () => {
    setNext(false);
    setDeliveryDate(null);
    setShipping(null);
    setErrorText(null);
    setErrModal(false)
  };
  // console.log(Country.getAllCountries());
  useEffect(() => {
    AOS.init();
  }, []);
  const handleAddress = () => {
    setNewAdd(!newAdd);
  };
  const countries = Country.getAllCountries();
  countries.sort((a, b) => {
    if (a.name === 'United States') return -1;
    if (b.name === 'United States') return 1;
    return a.name.localeCompare(b.name);
  });
  console.log(shippingNew,totalAmount,itemTotalAmount);

  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "rgb(109 79 160)", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`Place Order`} paddingb={'0rem'} />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} mt={"0.5rem"}>
            <Grid item md={8} sm={12} xs={12}>
              {!next ? (
                <Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid rgb(109 79 160)",
                      pb: "0.5rem",
                      fontSize: "20px",
                      fontWeight:600
                    }}
                  >
                    Billing Details
                  </Box>
                  <Box className={classes.input_box}>
                    <Grid container spacing={2} mt={"0.5rem"}>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          placeholder="First Name"
                          id="fname"
                          name="fname"
                          className={classes.bin1}
                          value={formik.values.fname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fname && formik.errors.fname && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.fname}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          placeholder="Last Name"
                          id="lname"
                          name="lname"
                          className={classes.bin1}
                          value={formik.values.lname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lname && formik.errors.lname && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.lname}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="Company name (optional)"
                          id="company"
                          name="company"
                          value={formik.values.company}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.company && formik.errors.company && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.company}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Box className="select">
                          <Select
                            id="country"
                            name="country"
                            options={countries}
                            getOptionLabel={(options) => {
                              return (
                                <Box>
                                  {options.flag} {options.name}
                                </Box>
                              );
                            }}
                            getOptionValue={(options) => {
                              return options["isoCode"];
                            }}
                            value={selectedCountry}
                            onChange={(item) => {
                              setSelectedCountry(item);
                              formik.setFieldValue("country", item.isoCode);
                            }}
                            onBlur={formik.handleBlur("country")}
                            placeholder="Select country..."
                          />
                          {formik.touched.country && formik.errors.country && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.country}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="Address Line 1"
                          id="address_1"
                          name="address_1"
                          value={formik.values.address_1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.address_1 &&
                          formik.errors.address_1 && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.address_1}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="Address Line 2 (optional)"
                          id="address_2"
                          name="address_2"
                          value={formik.values.address_2}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.address_2 &&
                          formik.errors.address_2 && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.address_2}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="Address Line 3 (optional)"
                          id="address_3"
                          name="address_3"
                          value={formik.values.address_3}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.address_3 &&
                          formik.errors.address_3 && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.address_3}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="Address Line 4 (optional)"
                          id="address_4"
                          name="address_4"
                          value={formik.values.address_4}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.address_4 &&
                          formik.errors.address_4 && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.address_4}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="City/District/Town"
                          id="city"
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.city}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Box className="select">
                          <Select
                            id="state"
                            name="state"
                            options={State?.getStatesOfCountry(
                              selectedCountry?.isoCode
                            )}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["isoCode"];
                            }}
                            value={selectedState}
                            onChange={(item) => {
                              setSelectedState(item); 
                              formik.setFieldValue("state", item.isoCode);
                            }}
                            onBlur={formik.handleBlur("state")}
                            placeholder="State/Province/District"
                          />
                          {formik.touched.state && formik.errors.state && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.state}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="Postal Code"
                          id="postal"
                          name="postal"
                          value={formik.values.postal}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.postal && formik.errors.postal && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.postal}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="text"
                          className={classes.bin1}
                          placeholder="Phone"
                          id="phone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.phone}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="email"
                          className={classes.bin1}
                          placeholder="Email Address"
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.email}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <BillingInfo
                  title={" Billing to"}
                  textObj={billing_address_info}
                />
              )}
              {next ? (
                <Box>
                  <Box
                    sx={{
                      mt: "1.5rem",
                    }}
                  >
                    <BillingInfo
                      title={" Shipping to"}
                      textObj={
                        newAdd ? shipping_address_info : billing_address_info
                      }
                    />
                  </Box>
                  <Box mt={"1rem"}>
                    <Button
                      type="button"
                      className={classes.proceed_btn}
                      sx={{ width: "130px" }}
                      onClick={() => handleEdit()}
                    >
                      <BorderColorIcon
                        sx={{
                          color: "#fff",
                          fontSize: "1.2rem",
                          mr: "0.3rem",
                        }}
                      />{" "}
                      Edit
                    </Button>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid rgb(109 79 160)",
                        pb: "0.5rem",
                        pt: "1.5rem",
                        fontSize: "20px",
                        fontWeight:600
                      }}
                    >
                      Shipping Options
                    </Box>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={shippingNew}
                        onChange={(e) => setShippingNew(e.target.value)}
                      >
                        {shippingResponse?.map((e, index) => (
                          <ChangeShipping
                            e={e}
                            key={index}
                            selectedCountry={selectedCountry}
                            orderId={orderId}
                            setShipping={setShipping}
                            setItemTotalAmount={setItemTotalAmount}
                            setTotalAmount={setTotalAmount}
                            setDeliveryDate={setDeliveryDate}
                            setShippingNew={setShippingNew}
                            shippingNew={shippingNew}
                            setCheckOut={setCheckOut}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      mt: "24px",
                      borderBottom: "1px solid rgb(109 79 160)",
                      pb: "0.5rem",
                      fontSize: "20px",
                      fontWeight:600
                    }}
                  >
                    <Checkbox
                      id="checkbox"
                      name="checkbox"
                      value={newAdd}
                      checked={newAdd}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleAddress(e);
                      }}
                      disableRipple
                      sx={{
                        color: "rgb(109 79 160)",
                        p: "0",
                        verticalAlign: "text-bottom",
                        "&.Mui-checked": {
                          color: "rgb(109 79 160)",
                        },
                        "& svg": {
                          fontSize: "1.3rem",
                        },
                      }}
                    />{" "}
                    Ship to a different address?
                  </Box>
                  {newAdd && (
                    <Box className={classes.input_box}>
                      <Grid container spacing={2} mt={"0.5rem"}>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            placeholder="First Name"
                            id="fname_s"
                            name="fname_s"
                            className={classes.bin1}
                            value={formik.values.fname_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.fname_s && formik.errors.fname_s && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.fname_s}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            placeholder="Last Name"
                            id="lname_s"
                            name="lname_s"
                            className={classes.bin1}
                            value={formik.values.lname_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.lname_s && formik.errors.lname_s && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.lname_s}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="Company name (optional)"
                            id="company_s"
                            name="company_s"
                            value={formik.values.company_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.company_s &&
                            formik.errors.company_s && (
                              <Typography
                                variant="body1"
                                className={classes.error}
                              >
                                {formik.errors.company_s}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Box className="select">
                            <Select
                              id="country_s"
                              name="country_s"
                              options={countries}
                              getOptionLabel={(options) => {
                                return (
                                  <Box>
                                    {options.flag} {options.name}
                                  </Box>
                                );
                              }}
                              getOptionValue={(options) => {
                                return options["isoCode"];
                              }}
                              value={selectedCountryShipping}
                              onChange={(item) => {
                                setSelectedCountryShipping(item);
                                formik.setFieldValue("country_s", item.isoCode);
                              }}
                              onBlur={formik.handleBlur("country_s")}
                              placeholder="Select country..."
                            />
                            {formik.touched.country_s &&
                              formik.errors.country_s && (
                                <Typography
                                  variant="body1"
                                  className={classes.error}
                                >
                                  {formik.errors.country_s}
                                </Typography>
                              )}
                          </Box>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="Address Line 1"
                            id="address_1_s"
                            name="address_1_s"
                            value={formik.values.address_1_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.address_1_s &&
                            formik.errors.address_1_s && (
                              <Typography
                                variant="body1"
                                className={classes.error}
                              >
                                {formik.errors.address_1_s}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="Address Line 2 (optional)"
                            id="address_2_s"
                            name="address_2_s"
                            value={formik.values.address_2_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.address_2_s &&
                            formik.errors.address_2_s && (
                              <Typography
                                variant="body1"
                                className={classes.error}
                              >
                                {formik.errors.address_2_s}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="Address Line 3 (optional)"
                            id="address_3_s"
                            name="address_3_s"
                            value={formik.values.address_3_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.address_3_s &&
                            formik.errors.address_3_s && (
                              <Typography
                                variant="body1"
                                className={classes.error}
                              >
                                {formik.errors.address_3_s}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="Address Line 4 (optional)"
                            id="address_4_s"
                            name="address_4_s"
                            value={formik.values.address_4_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.address_4_s &&
                            formik.errors.address_4_s && (
                              <Typography
                                variant="body1"
                                className={classes.error}
                              >
                                {formik.errors.address_4_s}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="City/District/Town"
                            id="city_s"
                            name="city_s"
                            value={formik.values.city_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.city_s && formik.errors.city_s && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.city_s}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Box className="select">
                            <Select
                              id="state_s"
                              name="state_s"
                              options={State?.getStatesOfCountry(
                                selectedCountryShipping?.isoCode
                              )}
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["isoCode"];
                              }}
                              value={selectedStateShipping}
                              onChange={(item) => {
                                setSelectedStateShipping(item);
                                formik.setFieldValue("state_s", item.isoCode);
                              }}
                              onBlur={formik.handleBlur("state_s")}
                              placeholder="State/Province/District"
                            />
                            {formik.touched.state_s &&
                              formik.errors.state_s && (
                                <Typography
                                  variant="body1"
                                  className={classes.error}
                                >
                                  {formik.errors.state_s}
                                </Typography>
                              )}
                          </Box>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="Postal Code"
                            id="postal_s"
                            name="postal_s"
                            value={formik.values.postal_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.postal_s &&
                            formik.errors.postal_s && (
                              <Typography
                                variant="body1"
                                className={classes.error}
                              >
                                {formik.errors.postal_s}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="text"
                            className={classes.bin1}
                            placeholder="Phone"
                            id="phone_s"
                            name="phone_s"
                            value={formik.values.phone_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.phone_s && formik.errors.phone_s && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.phone_s}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} mb={"1rem"}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            type="email"
                            className={classes.bin1}
                            placeholder="Email Address"
                            id="email_s"
                            name="email_s"
                            value={formik.values.email_s}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email_s && formik.errors.email_s && (
                            <Typography
                              variant="body1"
                              className={classes.error}
                            >
                              {formik.errors.email_s}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <PriceDetail
                result={result}
                deliveryDate={deliveryDate}
                totalPrice={totalPrice}
                display={"none"}
                hide="true"
                shipping={shipping}
              />
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              {/* {errorText && (
                <Typography style={{ color: "red" }}>{errorText}</Typography>
              )}  */}
              {errorText && (
                <ErrorModal errModal={errModal} setErrModal={setErrModal} errorText={errorText}/>
              )} 
              {next ? (
                <Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid rgb(109 79 160)",
                      pb: "0.5rem",
                      fontSize: "20px",
                      fontWeight:600
                    }}
                  >
                    Payment Options
                  </Box>

                  {(checkout && totalAmount > 0) ? (
                    <Paypal
                      totalAmount={totalAmount}
                      itemTotalAmount={itemTotalAmount}
                      orderId={orderId}
                      billing_address_info={billing_address_info}
                      shipping_address_info={
                        (newAdd === false
                          ? billing_address_info
                          : shipping_address_info,
                        billing_address_info)
                      }
                      orderItems={orderItems}
                      newAdd={newAdd}
                      shipping={shipping}
                      shippingNew={shippingNew}
                    />
                  ) : (
                    <Box mt={"1rem"}>
                      <Button
                        type="button"
                        className={classes.proceed_btn}
                        // disabled={!(formik.isValid && formik.dirty)}
                        onClick={() => setCheckOut(true)}
                      >
                        Proceed to Payment
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box mt={"0.5rem"}>
                  <Button
                    type="submit"
                    className={classes.proceed_btn}
                    sx={{ width: "150px" }}
                    // disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => (formik.isValid && formik.dirty) && makeOrder()}
                  >
                 {loading ?   <CircularProgress
              sx={{
                width: "21px !important",
                height: "21px !important",
                color: "#e4dbe7",
              }}
            />
                  :  "Next"}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box pt={"5rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default BookOrder;
