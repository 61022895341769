
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
    input_box: {
      "& label": {
        marginBottom: "0.3rem",
        display: "block",
        color: "rgb(109 79 160)",
        fontSize: "18px",
        fontFamily: "ABLE !important",
      },
    },
    bin1: {
      borderRadius: "8px",
      boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
      border: "0",
      width: "100%",
      background: "transparent",
      color: "#fff",
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&:focus-Visible": {
        outline: "none !important",
        border: "none !important",
      },
      "&:hover": {
        border: "none",
      },
      "& .MuiInputBase-root": {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
  
      "&:focus": {
        boxShadow: "none !important",
        border: "1px solid #fff !important",
        borderRadius: "10px !important ",
      },
      "& input": {
        padding: "10px",
        width: "100%",
        color:"#000",
        fontFamily:"ABLE",
        fontSize:"18px",
        "&:focus": {
          outline: "none",
        },
        "&::placeholder": {
          color: "#0000008f !important",
        },
      },
      "& textarea": {
        // background: "#F7F7F9",
        padding: "10px !important",
        color: "rgb(109 79 160)",
        "&:focus": {
          outline: "none",
        },
        "&::placeholder": {
          color: "#0000008f",
        },
      },
    },
    error: {
      marginTop: "0.5rem !important",
      fontSize: "14px !important",
      color: "red !important",
      textAlign: "start",
    },
    proceed_btn:{
        background: "rgb(109 79 160) !important",
        color: "#fff !important",
        fontFamily: "ABLE !important",
        padding: "0.5rem 1rem !important",
        fontSize: "16px !important",
        lineHeight: "initial !important",
        border: "2px solid #C5A7CF !important",
        // width: "100%",
        "&:hover": {
          background: "rgb(109 79 160)",
        },
        "&.Mui-disabled": {
          cursor: "not-allowed !important",
          pointerEvents: "auto !important",
          color: "rgb(255 255 255 / 38%) !important",
          "&:hover": {
            opacity: "1",
          },
        },
    },
    text:{
      color: "rgb(109 79 160)",
      fontSize: "18px",
      fontFamily:"ABLE"
      // padding:"0 15px"
    }
  });