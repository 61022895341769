import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import capno_trainer_img from "../Assets/Images/book.png";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "0",}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: 5,
            color: "#fff",
            top: 5,
            background:"rgb(109 79 160)",
            "&:hover":{
                background:"rgb(109 79 160)",
            }
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ImageModal({
    poppup,
    setPoppup,
}) {
  return (
    <div>
      <BootstrapDialog
        onClose={() => setPoppup(false)}
        aria-labelledby="customized-dialog-title"
        open={poppup}
        sx={{
          "& .MuiDialogTitle-root": {
            // padding:"26px",
            // borderBottom: "1px solid rgb(109 79 160)",
          },
          "& .MuiDialog-paper": {
            width: "1000px",
            // background: "linear-gradient(180deg, #FCE4D1, #FFDDBA, #FEF3E5)",
            border: "5px solid rgb(109 79 160)",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(12px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setPoppup(false)}
        >
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Typography
                      component={"img"}
                      src={capno_trainer_img}
                      sx={{
                        width: { sm: "100%", xs: "100%" },
                        // height: { md: "180px", sm: "100%", xs: "100%" },
                      }}
                    />
         
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
