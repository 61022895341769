import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Paypal({
  _price,
  orderId,
  newAdd,
  unitPrice,
  count,
  billing_address_info,
  shipping_address_info,
  shipping

}) {
  const [paypalContainer, setPaypalContainer] = useState(null);
  const navigate = useNavigate();
  const shippingFee = shipping;
  
  useEffect(() => {
    if (!paypalContainer) {
      console.error("PayPal container not found");
      return;
    }
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: _price,
                  breakdown: {
                    item_total: {
                      /* Required when including the `items` array */
                      currency_code: "USD",
                      value: (unitPrice * count).toFixed(2),
                    },
                    shipping: {
                      currency_code: "USD",
                      value: shippingFee, // Shipping charges
                    },
                  },
                },
                items: [
                  {
                    name: "CapnoLearning™ An Introductory Guide" /* Shows within upper-right dropdown during payment approval */,
                    description:
                      "Purchased by " +
                      billing_address_info?.fname +
                      billing_address_info?.lname +
                      "." /* Item details will also be in the completed paypal.com transaction view */,
                    unit_amount: {
                      currency_code: "USD",
                      value: unitPrice,
                    },
                    quantity: count,
                  },
                ],
                shipping_address: {
                  line1:
                    newAdd === false
                      ? billing_address_info?.address_1
                      : shipping_address_info?.address_1,
                  line2:
                    newAdd === false
                      ? billing_address_info?.address_2
                      : shipping_address_info?.address_2,
                  city:
                    newAdd === false
                      ? billing_address_info?.city
                      : shipping_address_info?.city,
                  country_code:
                    newAdd === false
                      ? billing_address_info?.country
                      : shipping_address_info?.country,
                  Postal_CODE:
                    newAdd === false
                      ? billing_address_info?.postal
                      : shipping_address_info?.postal,
                  state:
                    newAdd === false
                      ? billing_address_info?.state
                      : shipping_address_info?.state,
                  phone:
                    newAdd === false
                      ? billing_address_info?.phone
                      : shipping_address_info?.phone,
                  recipient_name:
                    newAdd === false
                      ? billing_address_info?.fname
                      : shipping_address_info?.fname,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(function (details) {
            console.log(details);
            confirmOrder(orderId, details);
          });
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypalContainer);
    // eslint-disable-next-line
  }, [paypalContainer]);

    const confirmOrder = async (id, details) => {
      console.log(details);
      const response = await fetch(
        `${process.env.REACT_APP_API}/confirm-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: id,
            transaction_body: JSON.stringify(details),
          }),
        }
      );
      const data = await response.json();
      if (data.status) {
        navigate("/success");
      } else {
        alert("Something went wrong..");
      }
    };
  return (
    <Box mt={"1rem"}>
      <div ref={setPaypalContainer}></div>
    </Box>
  );
}

export default Paypal;
