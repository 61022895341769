import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import NewTypography from "../../Components/NewTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
// import cart_bg from "../../Assets/Images/cart_bg.png";
import capno_trainer_img from "../../Assets/Images/capno-trainer_img.png";
import Copyright from "../../Components/Copyright";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Loader from "../../Components/Loader";
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../redux/Constant";
import { getProductsById } from "../../store/ProductByIdSlice";
import { getCart } from "../../store/cartSlice";
import SuccesAlert from "../../Components/SuccesAlert";
import CartBox from "./CartBox";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ProductComparision from "./ProductComparision";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles({
  btn: {
    background: "rgb(109 79 160) !important",
    color: "#fff !important",
    fontFamily: "ABLE",
    padding: "10px 8px !important",
    lineHeight: "initial !important",
    "&:hover": {
      background: "rgb(109 79 160)",
    },
    border: "2px solid #C5A7CF !important",
    borderRadius: "6px !important",
  },
});

const ProductDetail1 = () => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addSuccessMsg, setAddSuccessMsg] = useState("");
  const [addErrorMsg, setAddErrorMsg] = useState("");
  const [relatedProduct, setRelatedProduct] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    dispatch(getProductsById(id));
  }, [dispatch, id]);
  const { data: result } = useSelector((state) => state.productById);
  const [productData] = result.data || [];
  const [images, setImages] = useState([]);

  // const images = [
  //   {
  //     original:`https://capno-data.s3.amazonaws.com/${productData?.product_images?.[0]}`,
  //     thumbnail:`https://capno-data.s3.amazonaws.com/${productData?.product_images?.[0]}`,
  //     originalClass: "custom-image",
  //     thumbnailClass: "custom-thumbnail",
  //   },
  //   // {
  //   //   original:`https://capno-data.s3.amazonaws.com/${productData?.product_images?.[1]}`,
  //   //   thumbnail:`https://capno-data.s3.amazonaws.com/${productData?.product_images?.[1]}`,
  //   //   originalClass: "custom-image",
  //   //   thumbnailClass: "custom-thumbnail",
  //   // },
  //   // {
  //   //   original:`https://capno-data.s3.amazonaws.com/${productData?.product_images?.[2]}`,
  //   //   thumbnail:`https://capno-data.s3.amazonaws.com/${productData?.product_images?.[2]}`,
  //   //   originalClass: "custom-image",
  //   //   thumbnailClass: "custom-thumbnail",
  //   // },
  // ];
  const handleBack = () => {
    window.history.back();
  };


  // const addToCartFunc = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await axios.post(`${API_URL}/add-to-cart`, {
  //       user_id: localStorage.getItem("user")
  //         ? localStorage.getItem("user")
  //         : "",
  //       product_ids: [id],
  //       quantities: [count],
  //     });
  //     if (res.status === 200) {
  //       setLoading(false);
  //       setShowSnackbar(true);
  //       setTimeout(() => {
  //         setShowSnackbar(false);
  //         setAddSuccessMsg("");
  //       }, 2000);
  //       setAddSuccessMsg(res.data.message);
  //       const userId = localStorage.getItem("user")
  //         ? localStorage.getItem("user")
  //         : localStorage.setItem("user", res.data.user_id);
  //       dispatch(getCart(userId));
  //     }
  //   } catch (err) {
  //     console.log("Error", err);
  //     if (err.response.status === 404) {
  //       setLoading(false);
  //       setShowSnackbar(true);
  //       setTimeout(() => {
  //         setShowSnackbar(false);
  //         setAddErrorMsg("");
  //       }, 2000);
  //       setAddErrorMsg("Out of stock");
  //     }
  //   }
  // };

  useEffect(() => {
    if (productData?.category) {
      const _image = productData?.product_images.map((v, i) => {
        return {
          original: `${IMAGE_URL}/${v}?${Date.now()}`,
          thumbnail: `${IMAGE_URL}/${v}?${Date.now()}`,
          originalClass: "custom-image",
          thumbnailClass: "custom-thumbnail",
        };
      });
      setImages(_image);
      setRelatedProduct(productData?.accessory_data);
      // relatedProductData();
    }
    // eslint-disable-next-line
  }, [productData,id]);
  return (
    <>
      <SuccesAlert
        showSnackbar={showSnackbar}
        addSuccessMsg={addSuccessMsg}
        addErrorMsg={addErrorMsg}
      />
      <Container>
        <Navbar />
        <Box
          sx={{ color: "#000", mt: "2rem" }}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {/* <Title title={`Shopping Cart`} /> */}

          {productData ? (
            <Box>
            <Box
                sx={{
                  pb: "1.5rem",
                  pt: { sm: "0.3rem" },
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  gap: { lg: "0rem", md: "1rem", xs: "1rem", sm: "1rem" },
                }}
              >
                <Box>
                  <NewTypography
                    text={productData?.name}
                    fsm={"40px"}
                    fxs={"30px"}
                    lsm={"50px"}
                    lxs={"35px"}
                    textalign={"center"}
                  />
                  {productData?.subtitle && (
                    <NewTypography
                      text={productData?.subtitle}
                      fsm={"20px"}
                      fxs={"20px"}
                      lsm={"25px"}
                      lxs={"25px"}
                      textalign={"center"}
                    />
                  )}
                </Box>
                <Box>
                  <Button
                    className={classes.btn}
                    LinkComponent={Link}
                    to="/shopping-cart"
                    sx={{
                      fontSize: {
                        md: "0.92rem !important",
                        xs: "0.85rem !important",
                      },
                      width:{md:"130px",xs:"120px"} ,
                      display:'flex',
                      gap:'10px',
                      margin:{md:'auto', sm:'auto', xs:'auto'}
                    }}
                    
                  >
                    <ArrowBackIcon sx={{
                      fontSize:"1.2rem"
                    }}/> {" "} Go Back 
                  </Button>
                </Box>
              </Box>
              <Grid container spacing={3}>
                <Grid item md={6} sm={6} xs={12}>
                  <Box>
                    <ImageGallery
                      items={images}
                      showNav={false}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      disableSwipe={true}
                      disableThumbnailSwipe={true}
                      slideDuration={0}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Box
                    sx={{
                      border: "1px solid rgb(109 79 160)",
                      p: "1rem",
                      borderRadius: "10px",
                      // mt: "2rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: "18px", xs: "18px" },
                        fontFamily: "ABLE",
                        lineHeight: "35px",
                        fontWeight: "600",
                        borderBottom: "1px solid rgb(109 79 160)",
                        pb: "0.5rem",
                      }}
                      className="title"
                    >
                      ITEM DESCRIPTION
                    </Typography>
                    <Box py="0.7rem">
              <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: productData?.description,
                        }}
                      />
                
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {productData?.category === "7" && <ProductComparision />}
            </Box>
          ) : (
            <Box
              sx={{
                height: "60vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          )}

          <Box pt={"4rem"}>
            <Copyright />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ProductDetail1;
