
import './App.css';
import AllRoutes from './AllRoutes';
import { Box } from '@mui/material';

function App() {
  return (
    <Box sx={{
      "& .MuiContainer-root":{
        "@media (min-width:1260px)":{
          maxWidth:"1300px"
        },
      }
      
    }}>
    <AllRoutes/>
    </Box>
  );
}

export default App;
