import { Typography } from '@mui/material'
import React from 'react'

const SupportTypography = ({link,text1,text2,fsm,fxs,lsm,lxs}) => {
  return (
    <Typography
    sx={{
        fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        textAlign: { sm: "start", xs: "justify" },
    }}
>
    {text1} 
    {" "}
    <Typography
        component={"a"}
        href={`mailto:${link}`}
        sx={{
            fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        color:"rgb(109 79 160)",
        fontWeight:600
        }}
    >
        {link}
    </Typography>
    {" "}
   {text2}
</Typography>
  )
}

export default SupportTypography