import { Box, Typography } from "@mui/material";
import React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ChooseLanguages from "./Languages/ChooseLanguages";

const Copyright = ({center}) => {
  return (
    <Box
      sx={{
        display: { sm: "flex", xs: "block" },
        justifyContent: center?center:"space-between",
        my: "1rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.1rem",
          fontFamily: "ABLE",
          lineHeight: "22px",
          mb: "1rem",
          color: "#040404",
          // textAlign:{md:"inherit",sm:"center",xs:"center"}
        }}
      >
        Copyright © 2023-2024. All Rights Reserved.
      </Typography>
      <Box sx={{
        display:"flex",
        justifyContent:"center",
        gap:"0.3rem",
        alignItems:"center",
        "& svg":{
          color:"rgb(109 79 160)",
        },
        "& a":{
        lineHeight:"0",
        transition:"0.3s all",
        "&:hover":{
          transform:"scale(1.2)"
        }
        },
      }}>
      <a href={"https://www.instagram.com/betterphysiology/?igsh=MWtpOXR6bHQ0MnB2ag%3D%3D&utm_source=qr"} target="_blank" rel="noreferrer">
      <InstagramIcon/>
      </a>
      <a href={"https://www.linkedin.com/company/better-physiology-ltd/"} target="_blank" rel="noreferrer">
      <LinkedInIcon sx={{fontSize:"1.6rem"}}/>
      </a>
      {/* <XIcon/>
      <FacebookIcon sx={{fontSize:"1.7rem"}}/>
      <YouTubeIcon sx={{fontSize:"2.2rem"}}/> */}
      </Box>
      {/* <ChooseLanguages /> */}
    </Box>
  );
};

export default Copyright;
