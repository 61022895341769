import { Typography } from "@mui/material";
import React from "react";

const CourseTypograpgy = ({
  text,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  text9,
  text10,
  text11, 
  text12,
  text13,
  text14,
  fontStl,
  fsm,
  fxs,
  lsm,
  lxs,
  fstyle,
  marginTop,
  fontStyl,
  index,
  link,
  textalign,
  color,
  fontWeight,
  subtitle,
}) => {
  console.log(fstyle, "font style");
  return (
    <>
      <Typography
        sx={{
          fontSize: { sm: fsm, xs: fxs },
          fontFamily: "ABLE",

          marginTop: marginTop || 0,
          // fontFamily: "ABLE",
          lineHeight: { sm: lsm, xs: lxs },
          textAlign: { sm: "start", xs: textalign },
          fontWeight: fontWeight,
          color: color && color,
          fontStyle: fstyle || "none",
        }}
        className="title"
      >
        {text}{" "}
        {link && (
          <Typography
            component={"a"}
            href={`https://${link}`}
            sx={{
              fontSize: { sm: fsm, xs: fxs },
              fontFamily: "ABLE",
              // lineHeight: { sm: lsm, xs: lxs },
              color: "rgb(109 79 160)",
              fontWeight:600
              // textAlign: { sm: "start", xs: "justify" },
            }}
            target="_blank"
          >
            {`${link}`}
          </Typography>
        )}
        {text2}
        <Typography
          component={"span"}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",

            marginTop: marginTop || 0,
            // fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            textAlign: { sm: "start", xs: textalign },
            fontWeight: 600,
            color: color && color,
            fontStyle: fontStl || "none",
          }}
        >
          {text3}
        </Typography>

        {text4}

        <Typography
          component={"span"}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",

            marginTop: marginTop || 0,
            // fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            textAlign: { sm: "start", xs: textalign },
            fontWeight: 600,
            color: color && color,
            fontStyle: fontStl || "none",
          }}
        >
          {text5}{" "}
        </Typography>
        {" "}{text6}

        <Typography
          component={"span"}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",

            marginTop: marginTop || 0,
            // fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            textAlign: { sm: "start", xs: textalign },
            fontWeight: 600,
            color: color && color,
            fontStyle: fstyle || "none",
          }}
        >
          {text7}{" "}
        </Typography>
        {text8}{" "}

        <Typography
          component={"span"}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",

            marginTop: marginTop || 0,
            // fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            textAlign: { sm: "start", xs: textalign },
            fontWeight: 600,
            color: color && color,
            fontStyle: fstyle || "none",
          }}
        >
          {text9}{" "}
        </Typography>
        {text10}{" "}

        <Typography
          component={"span"}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",

            marginTop: marginTop || 0,
            // fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            textAlign: { sm: "start", xs: textalign },
            fontWeight: 600,
            color: color && color,
            fontStyle: fstyle || "none",
          }}
        >
          {text11}{" "}
        </Typography>
        {text12}{" "}
        <Typography
          component={"span"}
          sx={{
            fontSize: { sm: fsm, xs: fxs },
            fontFamily: "ABLE",

            marginTop: marginTop || 0,
            // fontFamily: "ABLE",
            lineHeight: { sm: lsm, xs: lxs },
            textAlign: { sm: "start", xs: textalign },
            fontWeight: 600,
            color: color && color,
            fontStyle: fstyle || "none",
          }}
        >
          {text13}{" "}
        </Typography>
        {text14} {" "}

      </Typography>
      <span color={"#000"} fontSize={"18px"}>
        <i>{subtitle}</i>
      </span>
    </>
  );
};

export default CourseTypograpgy;
