export const Add_to_cart= "Add_to_cart"
export const Remove_to_cart= "Remove_to_cart"
export const Empty_cart= "Empty_cart"
export const PRODUCT_LIST= "PRODUCT_LIST"
export const SET_PRODUCT_LIST= "SET_PRODUCT_LIST"
export const PRODUCT_LIST_BY_ID= "PRODUCT_LIST_BY_ID"
export const SET_PRODUCT_LIST_BY_ID= "SET_PRODUCT_LIST_BY_ID"
export const Set_Add_to_cart= "Set_Add_to_cart"
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const API_URL= process.env.REACT_APP_API;
export const IMAGE_URL= "https://capno-product-image.s3.amazonaws.com";