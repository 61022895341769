import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import { videoData } from "./VideoData";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import VideoPop from "./VideoPop";
import { Subtitles } from "@mui/icons-material";

const GoVideo = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [videoLink, setVideoLink] = useState("");
  const [subscribeModal, setSubscribeModal] = useState(false);
  const handleVideo = (link) => {
    setVideoLink(link);
    setSubscribeModal(true);
  };
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "rgb(109 79 160)", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`GO Videos`} paddingb={'0rem'} />
        <small style={{fontStyle:"italic" , color: "#000",fontSize:"16px"}}>Click on a name below to watch a video.</small>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            {videoData.map(
              (data, index) =>
                index == 2 && (
                  <Box sx={{ pt: { sm: "1.5rem" }, pb: "0.5rem" }} key={index}>
                    {console.log(index, "value of index", data, "data value")}
                    <NewTypography
                      text={data.title}
                      fsm={"26px"}
                      fxs={"22px"}
                      lsm={"35px"}
                      lxs={"25px"}
                      fontWeight={600}
                    />
                    <Typography component={"ul"} pt={"0.7rem"}>
                      {data.lists.map((e) => (
                        <Typography component={"li"} pb={"0.5rem"}>
                          <Typography
                            sx={{
                              fontSize: { sm: "22px", xs: "18px" },
                              fontFamily: "ABLE",
                              lineHeight: { sm: "30px", xs: "23px" },
                              textDecoration: "none",
                              color: "#000",
                            }}
                            component={"a"}
                            fontStyle={"italic"}
                            href="#"
                            target="_parent"
                            onClick={() => handleVideo(e.link)}
                          >
                            {e.text}
                          </Typography> 
                        </Typography>
                      ))}
                    </Typography>
                  </Box>
                )
            )}
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            {videoData.map(
              (data, index) =>
                index < 2 && (
                  <Box sx={{ pt: { sm: "1.5rem" }, pb: "0.5rem" }} key={index}>
                    <NewTypography
                      text={data.title}
                      fsm={"26px"}
                      fxs={"23px"}
                      lsm={"35px"}
                      lxs={"25px"}
                      fontWeight={600}
                    />
                    <Typography component={"ul"} pt={"0.7rem"}>
                      {data.lists.map((e) => (
                        <Typography component={"li"} pb={"0.5rem"}>
                          <Typography
                            sx={{
                              fontSize: { sm: "22px", xs: "18px" },
                              fontFamily: "ABLE",
                              lineHeight: { sm: "30px", xs: "23px" },
                              color: "#000",
                              textDecoration: "none"
                            }}
                            fontStyle={"italic"}
                            component={"a"}
                            href="#"
                            target="_parent"
                            onClick={() => handleVideo(e.link)}
                          >
                            {e.text}
                          </Typography>
                        </Typography>
                      ))}
                    </Typography>
                  </Box>
                )
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
          sx={{
            height: { sm: "15vh" },
          }}
        />
      <Box pt={"3rem"}>
        <Copyright />
      </Box>
      <VideoPop
        subscribeModal={subscribeModal}
        setSubscribeModal={setSubscribeModal}
        videoLink={videoLink}
      />
    </Container>
  );
};

export default GoVideo;
