import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px", color: "rgb(109 79 160)" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#000",
            top: "-10px",
            background: "#fff",
            "&:hover": {
              background: "#fff",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function InterviewVideo({
  interviewModal,
  setInterviewModal,
  videoLink,
}) {
  const [modalHeight, setModalHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    const handleResize = () => {
      setModalHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const closeHandle = () => {
    setInterviewModal(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => closeHandle()}
        aria-labelledby="customized-dialog-title"
        open={interviewModal}
        sx={{
          "& .MuiPaper-root": {
            overflowY: "inherit",
          },
          "& .MuiDialog-paper": {
            width: "1222px",
            maxWidth: "1222px",
            background: "#E4D5F4",
            // border: "5px solid #fff",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => closeHandle()}
        >
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              background: "#F7F7F9",
              //   padding: "1.5rem",
              borderRadius: "12PX",
              "& label": {
                mb: "0.3rem",
                display: "block",
                color: "#000",
                fontSize: "18px",
                fontFamily: "ABLE !important",
              },
              "& input": {
                background: "#F7F7F9",
                p: "10px",
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
                border: "0",
                color: "#000",
                mb: "0.5rem",
                "&:focus": {
                  outline: "none",
                },
                "&::placeholder": {
                  color: "#E4D5F4",
                },
              },
            }}
          >
            <Box
              sx={{
                background: "#F7F7F9",
                borderRadius: "12px",
                // p: { sm: "1rem", xs: "1rem" },
                // my: "1.5rem",
                "& iframe": {
                  "@media (max-width:600px)": {
                    height: "200px",
                  },
                },
              }}
            >
              <iframe
                width="100%"
                height={modalHeight - 150}
                style={{ borderRadius: "10px" }}
                //   src="https://www.youtube.com/embed/LzpC6ul-3J8?si=BKjQQpuP7bHQ_NW1"
                src={videoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
