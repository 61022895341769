import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import SupportTypography from "./SupportTypography";
import EmailTypography from "../../Components/EmailTypography";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"
import SupportNewTypography from "./SupportNewTypography";

const Support = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <Container>
    <Navbar/>
      <Box sx={{ color: "#000", mt: "2rem" }}  data-aos="fade-up" data-aos-duration="500">
        <Title title={`GO Support`} paddingb={'1.5rem'} />

        <NewTypography
          text={`It is highly recommended that all CapnoTrainer GO users read the software Manuals and watch the tutorial videos for answers to their questions. These support materials are available on this website as well as within the BASIC and PROFESSIONAL software programs themselves.`}
          fsm={"22px"}
          fxs={"17px"}
          lsm={"32px"}
          lxs={"30px"}
          textalign={"justify"}
          color={"#040404"}
        />
        <Box pt={"1.5rem"}>
          <NewTypography
            text={"Hardware OR installation issues?"}
            // color={"#FF0000"}
            color={"rgb(109 79 160)"}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            fontWeight={600}
            textalign={"justify"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <SupportNewTypography
            text={
              "If you have a hardware or installation issue, email"
            }
            email={"tech@betterphysiology.com"}
            text2={
              " to make an appointment for immediate service!"
            }
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={"Professional GO Users and their associated Trainers:"}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            textalign={"justify"}
            fontWeight={600}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <SupportTypography
            text1={
              "Set an appointment on Zoom with the Better Physiology Tech System at "
            }
            text2={
              "for trouble-shooting your software and Cloud database account issues. Your professional subscription includes these live services at no cost to you or your associate trainers."
            }
            link={"tech@betterphysiology.com"}
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
          color={"#040404"}
          />
      
        </Box>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={"Personal and Basic GO users:"}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            textalign={"justify"}
            fontWeight={600}
          />
        </Box>
        <Box pt={"0.5rem"}>
        <SupportTypography
            text1={
              "Read the BASIC MANUAL and watch specifically relevant video tutorials available on this website as well as within the software itself. Live tech support on Zoom is available for a fee of $25.00 per 15 minutes of service  by appointment with the Better Physiology Tech System. Go to "
            }
            text2={
              "to set an appointment."
            }
            link={"tech@betterphysiology.com"}
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
          color={"#040404"}
          />
        <SupportTypography
            text1={
              "Tech Support does NOT provide software instructional services. You can, however, pay for live one-on-one tutorial service as described later."
            }
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
          color={"#040404"}
          />
        </Box>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={"Tutorial Services:"}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            textalign={"justify"}
            fontWeight={600}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <NewTypography
            text={
              "Many people learn to use software more efficiently through receiving oneon-one live help from a technology expert rather than by reading manuals and watching videos. Personal attention while learning the details of sophisticated software means learning to take advantage of its many perhaps otherwise overlooked features."
            }
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          {/* <NewTypography
            text={
              "Better Physiology offers one-on-one tutorial services for a fee of $25.00 per 15 minutes of service. Contact tech@betterphysiology.com to make an appointment. This service is above and beyond the trouble-shooting services provided at no charge for Professional GO users who receive these services as part of their subscription."
            }
            fsm={"24px"}
          fxs={"19px"}
          lsm={"32px"}
          lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          /> */}
           <SupportTypography
            text1={
              "Better Physiology offers one-on-one tutorial services for a fee of $25.00 per 15 minutes of service. Contact "
            }
            text2={
              "to make an appointment. This service is above and beyond the trouble-shooting services provided at no charge for Professional GO users who receive these services as part of their subscription."
            }
            link={"tech@betterphysiology.com"}
            fsm={"22px"}
          fxs={"18px"}
          lsm={"32px"}
          lxs={"30px"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <SupportTypography
            text1={
              "Better Physiology DOES NOT educate customers on how to provide breathing behavior analysis or CapnoLearning services. Consult the book, CapnoLearning: An Introductory Guide, or contact Sandra Reamer, MFA, MS at"
            }
            text2={
              "and her network of breathing behavior analysts for help. For more details, go to SERVICES again and click on CONSULTATION."
            }
            link={"sr@bp.edu"}
            fsm={"22px"}
          fxs={"17px"}
          lsm={"32px"}
          lxs={"30px"}
          />
        </Box>
      </Box>
      <Box pt={"3rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Support;
