import { Typography } from '@mui/material'
import React from 'react'

const CustomTypography = ({text}) => {
  return (
    <Typography
    sx={{
      // fontSize: "1.125rem",
      fontSize: { sm: "1.375rem", xs: "1.2rem" },
      fontFamily: "ABLE",
      lineHeight: { sm: "2.15rem",xs:"1.8rem"},
      // lineHeight: "1.434rem",
      py: "0.8rem",
      pt:'0rem',
      textAlign:{ sm: "start", xs: "justify" },
    }}
  >
    {text}
  </Typography>
  )
}

export default CustomTypography