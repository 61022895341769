import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"

const Lectures = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  const lectureData = [
    {
      id: 1,
      title: "VIDEO 1: Breathing: Unexplained Symptoms and Deficits",
      subtitle:
        "Your breathing may be affecting you in profound ways, in ways you never imagined,much more so than you know, and without your realizing it.",
      video: (
        <iframe
          width="100%"
  height="500px"
  style={{ borderRadius: "10px" }}
  src="https://better-physiology-videos.s3.amazonaws.com/Shambhala-Breathing-Video-1.mp4"
  title="Video player"
  frameborder="0"
  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen
        ></iframe>
      ),
    },
    {
      id: 2,
      title:
        "VIDEO 2: Breathing: Dysfunctional Habits & Vicious Circle Learning",
      subtitle:
        "Bad breathing habits are vicious circle and they may stay with you for a life time,triggering unexplained symptoms and deficits year after year.",
      video: (
        <iframe
          width="100%"
          height="500px"
          style={{ borderRadius: "10px" }}
          src="https://better-physiology-videos.s3.amazonaws.com/Shambhala-Breathing-Video-2.mp4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ),
    },
    {
      id: 3,
      title:
        "VIDEO 3: Breathing: Intuitive Learning vs. Prescriptive Intervention",
      subtitle:
        "You can learn to break your bad breathing habits, replace them with good ones,and benefit enormously from aligning your breathing with respiratory reflexes.",
      video: (
        <iframe
          width="100%"
          height="500px"
          style={{ borderRadius: "10px" }}
          src="https://better-physiology-videos.s3.amazonaws.com/Shambala-Breathing-Video-3.mp4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ),
    },
  ];
  return (
    <Container>
    <Navbar/>
      <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up" data-aos-duration="500">
        <Title title={`Lectures`} paddingb={'1.5rem'} />

        <Box>
          <NewTypography
            text={
              "Three videos produced in partnership with Shambhala Mountain Center in Colorado were prepared for lay audiences who practice yoga and other self-regulation breathing practices. These videos introduce the subject of “overbreathing,” hypocapnia (CO2 deficit), and dysfunctional breathing habits.They are relevant to the interests of both practitioners and their clients."
            }
            fsm={"22px"}
            fxs={"19px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={
              "Practitioners may download the videos for purposes of working with their clients, but may not use them for commercial purposes.The videos are copyrighted by the Professional School of Behavioral Health Sciences."
            }
            fsm={"22px"}
            fxs={"19px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>

        {lectureData.map((e, index) => (
          <Box pt={"1.5rem"} key={index}>
           <Box pb="0.3rem">
           <NewTypography
              text={e.title}
              fsm={"24px"}
              fxs={"22px"}
              lsm={"40px"}
              lxs={"30px"}
              fontWeight={600}
            />
           </Box>
            <NewTypography
              text={e.subtitle}
              fsm={"22px"}
            fxs={"19px"}
            lsm={"32px"}
            lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
            <Box
              sx={{
                background: "#F7F7F9",
                borderRadius: "12px",
                p: { sm: "2.5rem", xs: "1rem" },
                my: "1.5rem",
                "& iframe": {
                  "@media (max-width:600px)": {
                    height: "300px",
                  },
                },
              }}
            >
              {e.video}
            </Box>
          </Box>
        ))}

        <Box pt={"3rem"}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default Lectures;
