import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import { Typography } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../redux/Constant";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/ProductSlice";
import { getCart } from "../../store/cartSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "14px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0,p:"0" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          disableRipple
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "-5px",
            color: "rgb(109 79 160)",
            top: "-5px",
            // background: "rgb(109 79 160)",
            // "&:hover": {
            //   background: "rgb(109 79 160)",
            // },
          }}
          title="Close"
        >
          <CancelIcon sx={{ fontSize: "1.6rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ReminderModal = ({ reminder, setReminder }) => {
  const [loadingKit, setLoadingKit] = useState(false);

  const dispatch = useDispatch();

  const addToCartFunc = async (productId, quantity) => {
    setLoadingKit(true);
    try {
      const res = await axios.post(`${API_URL}/add-to-cart`, {
        user_id: localStorage.getItem("user")
          ? localStorage.getItem("user")
          : "",
        product_ids: [productId],
        quantities: [quantity],
      });
      if (res.status === 200) {
        setLoadingKit(false);
        const userId = res.data.user_id;
        dispatch(getCart(userId));
        setReminder(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoadingKit(false);
      }
    }
  };

  const handleClose = () => {
    setReminder(false);
  };
  // console.log(result);


  return (
    <div>
      <BootstrapDialog
        disableScrollLock
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={reminder}
        sx={{
          "& .MuiPaper-root": {
            overflowY: "inherit",
            width: "300px !important",
            maxWidth: "100% !important",
            background: "#fff",
            color: "#000",
            border: " 1px solid #ffffff59",
          },
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            mt: "4.5rem",
          },
          "& .MuiDialogContent-root": {
            borderBottom: "none",
            borderTop: " 1px solid #ffffff59",
            "::-webkit-scrollbar": { width: "6px" },
            // '::-webkit-scrollbar-track': { background: '#ffffff66' },
            // '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          //   "& .MuiDialog-container": {
          //     backdropFilter: "blur(8px)",
          //   },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ p: "8px" }}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Typography
            sx={{
              p: "5px",
              fontStyle: "italic",
              fontWeight: "600",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            <PanToolAltIcon
              sx={{
                transform: "rotate(90deg)",
                verticalAlign: "bottom",
                mr: "0.5rem",
                fontSize: "2rem",
                color: "rgb(109 79 160)",
              }}
            />
            You did not order a Starter Kit. Did you forget? Clicking{" "}
            <a
              style={{
                textDecoration: !loadingKit && "underline",
                color: "rgb(109 79 160)",
                fontSize: "16px",
                fontWeight: "600",
                fontStyle: "italic",
                cursor: "pointer",
              }}
              onClick={() => addToCartFunc(50, 1)}
              // href="#supply"
              title="Order a Starter Kit"
            >
              {loadingKit ? "Processing..." : "YES"}
            </a>{" "}
            will add it to the Cart ($115.00). If you do not wish to add the
            Starter Kit, click{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "rgb(109 79 160)",
                fontSize: "16px",
                fontWeight: "600",
                fontStyle: "italic",
                cursor: "pointer",
              }}
              onClick={handleClose}
              title="Do not order a Starter Kit"
            >
              NO
            </span>
            .
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ReminderModal;
