import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Box, Container, useMediaQuery } from "@mui/material";
import Loader from "./Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BookPdf = () => {
  const [loader, setLoader] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.35);
  const isMobile = useMediaQuery("(max-width:900px)");
  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    const pdfSection = document.getElementById("pdf-section");
    if (pdfSection) {
      pdfSection.addEventListener("contextmenu", disableRightClick);

      return () => {
        pdfSection.removeEventListener("contextmenu", disableRightClick);
      };
    }
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // setLoader(false);
  }
  useEffect(() => {
    setScale(isMobile ? 1.0 : 1.35);
  }, [isMobile]);
  console.log(scale);
  return (
    <Container>
      {!loader ? (
        <Box
          id="pdf-section"
          sx={{
            mt: "1rem",
            pt: "5px",
            width: { sm: "100%", xs: "100%" },
            border: "1px solid",
            background: "grey",
            overflowY: "auto",
            userSelect: "none",
          }}
        >
          <Document
            file="https://capno-forms.s3.amazonaws.com/CapnoLearning+-+An+Introductory+Guide+-+2023+-+Litchfield+%26+Reamer.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
          
            {Array.from({ length: numPages }, (_, index) => (
              <Box sx={{ mt: "10px" }}>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={scale}
                />
              </Box>
            ))}
          </Document>
        </Box>
      ) : (
        <Box
          sx={{
            height: "700px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      )}
    </Container>
  );
};

export default BookPdf;
