import { Typography } from "@mui/material";
import React from "react";

const CourseSupport = ({ text, text2,text3, text4, email, fsm, fxs, lsm, lxs,fstyle, index,  link,link2,textalign,color,fontWeight,subtitle}) => {
  console.log(fstyle, "font style")
  return (
    <>
    <Typography
      sx={{
        fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        // fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        textAlign: { sm: "start", xs: textalign },
        fontWeight:fontWeight,
        color:color && color,
        fontStyle:index == 9 || index == 4 ? `${fstyle}`:"none"
      }}
      className="title"
    >
      {text}{" "} 
      {link && (
          <Typography
            component={"a"}
            href={`https://${link}`}
            sx={{
              fontSize: { sm: fsm, xs: fxs },
              fontFamily: "ABLE",
              lineHeight: { sm: lsm, xs: lxs },
              color: "rgb(109 79 160)",
              fontWeight:600
              // textAlign: { sm: "start", xs: "justify" },
            }}
            target="_blank"
          >
            {`${link}`}
          </Typography>
        )}{" "}{text2}{" "}
      {email && (
        <Typography
        component={"a"}
        href={`mailto:${email}`}
        sx={{
            fontSize: { sm: fsm, xs: fxs },
        fontFamily: "ABLE",
        lineHeight: { sm: lsm, xs: lxs },
        color:"rgb(109 79 160)",
        fontWeight:600
        }}
    >
        {email}
    </Typography>
      )}.{" "} {text3} {" "}

      {link2 && (
          <Typography
            component={"a"}
            href={`https://${link2}`}
            sx={{
              fontSize: { sm: fsm, xs: fxs },
              fontFamily: "ABLE",
              lineHeight: { sm: lsm, xs: lxs },
              color: "rgb(109 79 160)",
              fontWeight:600
              // textAlign: { sm: "start", xs: "justify" },
            }}
            target="_blank"
          >
            {" "}{`${link2}`}
          </Typography>
        )}{" "} {text4}
    </Typography>
    <span color={"#000"} fontSize={"18px"}><i>{subtitle}</i></span>
    </>
  );
};

export default CourseSupport;
