import React, { useEffect } from "react";


const RedirectToSchool = () => {
    window.location.href="https://e-campus-registration.bp.edu/special/breathing-science-for-birth-professionals"

  
}

export default RedirectToSchool;
